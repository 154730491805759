import React from "react";
import { Outlet } from "react-router-dom";
// import Col from "../styled-components/col";
// import Contain from "../styled-components/contain";

import GetText from "../translation/getText"
function Booking() {
 
  return (
    <div >
    <GetText>
       {/* <TabForm/> */}
       {/* <canvas id="OTW_mainTimepiece_m126710blro-0001_267" width="328" height="397" style={{left: "0px", top: "0px", position: "absolute", transform: "scale(1, 1)", width: "328px", height: "397px"}}></canvas> */}
     <Outlet/>
     </GetText>
    </div>
  );
}


export default Booking;
