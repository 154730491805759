import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import { getStudentsByIDS, sendExam, sendReport, sendReportAttendOnly, sendReportHwOnly } from '../../utils/API';
import Alert from 'react-bootstrap/Alert';
import { HomePage } from '../../translations/trans';
import { useSelector } from 'react-redux';
import { success } from '../../utils/Toast';
import { BoxLoading, WaveLoading, SolarSystemLoading } from 'react-loadingg';
import { TbReportMedical } from "react-icons/tb";
const ChooseReport = ({ classId, studentId }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);  // حالة التحميل
    const [date, setDate] = useState(null);
    const [formTo, setToDate] = useState(null);
    const [message, setMessage] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSendReport = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendReport({
                classId: classId,
                studentId: studentId,
                from: date,
                to: formTo
            });
            console.log(res);
            if (res?.data?.report !== "") {
                success(`تم ارسال التقرير`);
            }
        } catch (e) {
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };

    // Send HomeWork Only
    const handleSendHWOnly = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendReportHwOnly({
                classId: classId,
                studentId: studentId,
                from: date,
                to: formTo
            });
            console.log(res);
            if (res?.data?.report !== "") {
                success(`تم ارسال التقرير`);
            }
        } catch (e) {
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };
    // Send Attend Only
    const handleSendAttendOnly = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendReportAttendOnly({
                classId: classId,
                studentId: studentId,
                from: date,
                to: formTo
            });
            console.log(res);
            if (res?.data?.report !== "") {
                success(`تم ارسال التقرير`);
            }
        } catch (e) {
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };



    const handleSendExam = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendExam({
                classId: classId,
                studentId: studentId,
                from: date,
                to: formTo
            });
            console.log(res);
        } catch (e) {
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };

    const handleReportsAll = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendExam({
                classId: classId,
                studentId: studentId,
                from: date,
                to: formTo
            });
            console.log(res);
        } catch (e) {
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };




    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
        setDate(formattedDate);
    };

    const handleDateToChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
        setToDate(formattedDate);
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 5000); // Hide the alert after 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount or if message changes
        }
    }, [message]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            {/* <button className="btn" >
                {HomePage.chooseTypeReport[lang]}
            </button> */}
            <TbReportMedical onClick={handleShow} style={{ color: "#4169E2" }} size={"25"} />
            {/* Modal Report */}
            <div>
                <Modal show={show} onHide={handleClose} centered dir={dir}>
                    <Modal.Body>
                        {message && (
                            <Alert variant={"danger"}>
                                {message}
                            </Alert>
                        )}
                        <div style={{ padding: "10px", backgroundColor: "#4169E2", textAlign: "center" }}>
                            <h4 style={{ color: "white" }}>{HomePage.addreport[lang]}</h4>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-3'>
                            <div>
                                <label>{HomePage.startDate[lang]}</label>
                                <input type="date" name="fromDate" id="fromDate"
                                    value={date ? date.split('T')[0] : ''}
                                    onChange={handleDateChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }} />
                            </div>
                            <div>
                                <label>{HomePage.endDate[lang]}</label>
                                <input type="date" name="toDate" id="toDate"
                                    value={formTo ? formTo.split('T')[0] : ''}
                                    onChange={handleDateToChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-around align-items-center mt-3'>
                            <p>{HomePage.email[lang]}</p>
                            <p>{HomePage.sendMessage[lang]}</p>
                        </div>
                        <div className=''>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div style={{ padding: "10px", marginTop: "10px", background: "#4169E2", borderRadius: "5px", color: "white", width:"50%" }}>
                                    {HomePage.attendAndhomeWork[lang]}
                                </div>
                                <div>
                                    <FaReply
                                        size="16px"
                                        style={{ cursor: 'pointer', color: '#001C45' }}
                                        onClick={handleSendReport}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div style={{ padding: "10px", marginTop: "10px", background: "#4169E2", borderRadius: "5px", color: "white", width:"50%" }}>
                                    {HomePage.hwonly[lang]}
                                </div>
                                <div>
                                    <FaReply
                                        size="16px"
                                        style={{ cursor: 'pointer', color: '#001C45' }}
                                        onClick={handleSendHWOnly}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div style={{ padding: "10px", marginTop: "10px", background: "#4169E2", borderRadius: "5px", color: "white"}}>
                                    {HomePage.attendOnly[lang]}
                                </div>
                                <div>
                                    <FaReply
                                        size="16px"
                                        style={{ cursor: 'pointer', color: '#001C45' }}
                                        onClick={handleSendAttendOnly}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div style={{ padding: "10px", marginTop: "10px", background: "#4169E2", borderRadius: "5px", color: "white", width:"50%" }}>
                                    {HomePage.test[lang]}
                                </div>
                                <div>
                                    <FaReply
                                        size="16px"
                                        style={{ cursor: 'pointer', color: '#001C45' }}
                                        onClick={handleSendExam}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-around align-items-center'>
                                <div style={{ padding: "10px", marginTop: "10px", background: "#4169E2", borderRadius: "5px", color: "white", width:"50%" }}>
                                    مجمع
                                </div>
                                <div>
                                    <FaReply
                                        size="16px"
                                        style={{ cursor: 'pointer', color: '#001C45' }}
                                        onClick={handleReportsAll}
                                    />
                                </div>
                            </div>
                        </div>
                        {loading && <div className='shadow' style={{ backgroundColor: "white", }}>
                            <SolarSystemLoading	></SolarSystemLoading	>
                        </div>}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default ChooseReport;
