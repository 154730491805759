// import React, { useEffect, useState } from 'react';
// import { Modal, Button, Table } from 'react-bootstrap';
// import { FaReply } from 'react-icons/fa';
// import Alert from 'react-bootstrap/Alert';
// // import { HomePage } from '../../translations/trans';
// import { useSelector } from 'react-redux';
// // import { success } from '../../utils/Toast';
// import { SolarSystemLoading } from 'react-loadingg';
// import { sendReportToClass } from '../../../utils/API';
// import { success } from '../../../utils/Toast';
// import { HomePage } from '../../../translations/trans';

// const SendReportToClass = ({ classId, }) => {
//     const [show, setShow] = useState(false);
//     const [loading, setLoading] = useState(false);  // حالة التحميل
//     const [date, setDate] = useState(null);
//     const [formTo, setToDate] = useState(null);
//     const [message, setMessage] = useState(null);
//     const [type, setType] = useState("");

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const loggedUser = useSelector((state) => state.loggedUser)

//     const handleSendReportToAllClass = async () => {
//         setLoading(true);  // ابدأ التحميل
//         try {
//             const res = await sendReportToClass({
//                 classId: classId,
//                 from: date,
//                 to: formTo,
//                 about: type
//             }, loggedUser?.user?._id);
//             console.log(res);
//             if (res?.data?.case) {
//                 success(res?.data?.message)
//                 handleClose();
//             }
//         } catch (e) {
//             console.log(e)
//             if (e.response) {
//                 setMessage(e.response.data.message);
//             }
//         } finally {
//             setLoading(false);  // أوقف التحميل
//         }
//     };

//     const handleDateChange = (e) => {
//         const selectedDate = new Date(e.target.value);
//         const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
//         setDate(formattedDate);
//     };

//     const handleDateToChange = (e) => {
//         const selectedDate = new Date(e.target.value);
//         const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
//         setToDate(formattedDate);
//     };

//     useEffect(() => {
//         if (message) {
//             const timer = setTimeout(() => {
//                 setMessage(null);
//             }, 5000); // Hide the alert after 5 seconds

//             return () => clearTimeout(timer); // Cleanup the timer on unmount or if message changes
//         }
//     }, [message]);

//     const { lang, dir } = useSelector(({ language }) => {
//         return { lang: language.lang, dir: language.dir };
//     });

//     // ,"about": "test" //  homework | attendance | activity -> ( homework & attendance) | mock | test

//     return (
//         <div>
//             <div onClick={handleShow}>
//                 <FaReply
//                     size="16px"

//                     style={{ cursor: 'pointer', color: '#001C45' }}
//                 />
//                 {HomePage.sendReports[lang]}
//             </div>

//             {/* Modal Report */}
//             <div>
//                 <Modal show={show} onHide={handleClose} centered dir={dir}>
//                     <Modal.Body>
//                         {message && (
//                             <Alert variant={"danger"}>
//                                 {message}
//                             </Alert>
//                         )}
//                         <div className=''>
//                             <div>
//                                 <label>{HomePage.from[lang]}:</label>
//                                 <input type="date" name="fromDate" id="fromDate"
//                                     value={date ? date.split('T')[0] : ''}
//                                     onChange={handleDateChange}
//                                     style={{ border: "none", color: "#120C54", padding: "10px" }}
//                                     className="form-control"
//                                 />
//                             </div>
//                             <div>
//                                 <label>{HomePage.to[lang]}:</label>
//                                 <input type="date" name="toDate" id="toDate"
//                                     value={formTo ? formTo.split('T')[0] : ''}
//                                     onChange={handleDateToChange}
//                                     style={{ border: "none", color: "#120C54", padding: "10px" }}
//                                     className="form-control"
//                                 />
//                             </div>
//                             <div>
//                                 <select className='form-control' value={type} onChange={(e) => setType(e.target.value)}>
//                                     <option hidden>choose Type Report</option>
//                                     <option value={"test"}>Test</option>
//                                     <option value={"homework"}>H.W</option>
//                                     <option value={"attendance"}>Attendance</option>
//                                     <option value={"activity"}>Activity (  homework & attendance)</option>
//                                     <option value={"mock"}>Mock</option>
//                                 </select>
//                             </div>

//                         </div>

//                         <div className="text-center mt-4">
//                             <button onClick={handleSendReportToAllClass} className='btn' style={{ background: "#001C45", padding: "5px", borderRadius: "5px" }}>{HomePage.sendMsg[lang]}</button>
//                         </div>
//                         {loading && <div className='shadow' style={{ backgroundColor: "white", }}>
//                             <SolarSystemLoading	></SolarSystemLoading	>
//                         </div>}
//                     </Modal.Body>
//                 </Modal>
//             </div>
//         </div>
//     );
// }

// export default SendReportToClass;

import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import Alert from 'react-bootstrap/Alert';
// import { HomePage } from '../../translations/trans';
import { useSelector } from 'react-redux';
// import { success } from '../../utils/Toast';
import { SolarSystemLoading } from 'react-loadingg';
import { sendReportToClass } from '../../../utils/API';
import { success } from '../../../utils/Toast';
import { HomePage } from '../../../translations/trans';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const SendReportToClass = ({ classId, }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);  // حالة التحميل
    const [date, setDate] = useState(null);
    const [formTo, setToDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [type, setType] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state) => state.loggedUser)

    const handleSendReportToAllClass = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendReportToClass({
                classId: classId,
                from: date,
                to: formTo,
                about: type
            }, loggedUser?.user?._id);
            console.log(res);
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };

    // const handleDateChange = (e) => {
    //     const selectedDate = new Date(e.target.value);
    //     const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
    //     setDate(formattedDate);
    // };

    // const handleDateToChange = (e) => {
    //     const selectedDate = new Date(e.target.value);
    //     const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
    //     setToDate(formattedDate);
    // };

    const formatDate = (date) => {
        return date ? date.toISOString().split('.')[0] + '+03:00' : null;
    };


    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 5000); // Hide the alert after 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount or if message changes
        }
    }, [message]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    // ,"about": "test" //  homework | attendance | activity -> ( homework & attendance) | mock | test

    return (
        <div>
            <div onClick={handleShow}>
                <FaReply
                    size="16px"

                    style={{ cursor: 'pointer', color: '#001C45' }}
                />
                {HomePage.sendReports[lang]}
            </div>

            {/* Modal Report */}
            <div>
                <Modal show={show} onHide={handleClose} centered dir={dir}>
                    <Modal.Body>
                        {message && (
                            <Alert variant={"danger"}>
                                {message}
                            </Alert>
                        )}
                        <div className=''>
                            <div>
                                <label>{HomePage.from[lang]}:</label>
                                <br/>
                                {/* <input type="date" name="fromDate" id="fromDate"
                                    value={date ? date.split('T')[0] : ''}
                                    onChange={handleDateChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }}
                                    className="form-control"
                                /> */}
                                <DatePicker
                                    selected={date ? new Date(date) : null}
                                    onChange={(date) => setDate(formatDate(date))}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control w-100"
                                    placeholderText="Select Date"
                                    
                                />
                            </div>
                            <div className="mt-5">
                                <label>{HomePage.to[lang]}:</label>
                                <br/>
                                {/* <input type="date" name="toDate" id="toDate"
                                    value={formTo ? formTo.split('T')[0] : ''}
                                    onChange={handleDateToChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }}
                                    className="form-control"
                                /> */}
                                <DatePicker
                                    selected={formTo ? new Date(formTo) : null}
                                    onChange={(date) => setToDate(formatDate(date))}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control w-100"
                                    placeholderText="Select Date"
                                />
                            </div>
                            <div className="mt-3">
                                <select className='form-control' value={type} onChange={(e) => setType(e.target.value)}>
                                    <option hidden>choose Type Report</option>
                                    <option value={"test"}>Test</option>
                                    <option value={"homework"}>H.W</option>
                                    <option value={"attendance"}>Attendance</option>
                                    <option value={"activity"}>Activity (  homework & attendance)</option>
                                    <option value={"mock"}>Mock</option>
                                </select>
                            </div>

                        </div>

                        <div className="text-center mt-4">
                            <button onClick={handleSendReportToAllClass} className='btn' style={{ background: "#001C45", padding: "5px", borderRadius: "5px" }}>{HomePage.sendMsg[lang]}</button>
                        </div>
                        {loading && <div className='shadow' style={{ backgroundColor: "white", }}>
                            <SolarSystemLoading	></SolarSystemLoading	>
                        </div>}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default SendReportToClass;
// 2024-01-01T00:00:00+03:00
// 2024-10-15T21:00:00+03:00