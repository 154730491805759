import { getAuthedUser, intializeUser, logOut, register ,getAccounts,sendMessage, sendMessageToGroup} from "../utils/API";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const REGISTER_ADMIN = "REGISTER_ADMIN";
export const REGISTER_TEACHER = "REGISTER_TEACHER";
export const REGISTER_STUDENT = "REGISTER_STUDENT";
// ----- user is an object of data
const admin = (user) => {
  return {
    type: REGISTER_ADMIN,
    user,
  };
};
const teacher = (user) => {
  return {
    type: REGISTER_TEACHER,
    user,
  };
};
const student = (user) => {
  return {
    type: REGISTER_STUDENT,
    user,
  };
};

export const Register_Admin = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data === "created") {
        dispatch(admin(user));
        alert(`account created with email: ${user.username}`);
      }
     else if (res.data === "exist") {
        alert(`account with email: ${user.email} already exist`);
      }
      else{console.log(res.data);}
    });
  };
};
export const Register_Teacher = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data === "created") {
        dispatch(teacher(user));
        alert(`account created with email: ${user.username}`);
      }
     else if (res.data === "exist") {
        alert(`account with email: ${user.email} already exist`);
      }
      else{console.log(res.data);}

    });
  };
};
export const Register_Student = (user) => {
  return (dispatch) => {
    return register(user).then((res) => {
      if (res.data.case) {
        dispatch(student(res.data.user));
        alert(`account created with email: ${user.email}`);
      }
   
      else{
        alert(res.data.message);
        console.log(res.data);
      }
      return res;
    });
  };
};
export const authedUser = (user) => {
  return {
    type: AUTH_LOGIN,
    user,
  };
};

export const logIn = (authentcation) => {
  return (dispatch) => {
    return getAuthedUser(authentcation).then((data) => {
    if(data.auth){
      dispatch(authedUser(data));
    }
    else{
      // alert('password or username is not correct or username does not exist')
    }
    return data

    });
  };
};
//  inetialize useres

export const intializeLogIn = () => {
  return (dispatch) => {
    return intializeUser().then((user) => {
      dispatch(authedUser(user));
    });
  };
};
export const Log_Out = ()=>{
  return (dispatch) => {
    return logOut().then((res) => {
      dispatch(authedUser(null));
    });
  };
}
//get ADmS
export const INETIALIZE_ADS='INETIALIZE_ADS'
const inetializeAds = (ads)=>{
  return {
      type: INETIALIZE_ADS,
      ads
  }
}
export const Inetialize_Ads =()=>{
  return(dispatch)=>{
      return getAccounts().then((res)=>dispatch(inetializeAds(res.data))
      )
  }
}
// messages
export const SEND_MESSAGE='SEND_MESSAGE';
const newMessage = (user)=>{
  return{
    type:SEND_MESSAGE,
    user
  }
}
export const Send_Message=(data)=>{
  return (dispatch)=>{
    return sendMessage(data).then(res=>{
      if(res.data.case){
        dispatch(newMessage(res.data.user));
      }
      return res
    })
  }
}

export const Send_Message_To_Group = (data)=>{
  return dispatch =>{
    return sendMessageToGroup(data).then((res=>{
      if(res.data.case){
        dispatch(newMessage(res.data.user))
      }
      return res
    }))
  }
}