import React from 'react'

function Progress({percentage}) {
  return (
    <div className='progress'>
        <span> {percentage}%</span>
    <div
      className='progress-bar'
      role='progressbar'
      style={{ width: `${percentage}%` }}
    >
      
    </div>
  </div>
  )
}

export default Progress