import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteSession } from '../../utils/sessions';
import { ErrorMsg, success } from '../../utils/Toast';
import { removeStudentsFromClass } from '../../utils/class';
import { HomePage } from '../../translations/trans';
import { useSelector } from 'react-redux';
const DeleteStudent = ({ show, handleClose, Class, studentId, onDeleteSuccess }) => {

    const handleDelete = async () => {
        try {
            const res = await removeStudentsFromClass({
                id: Class?._id,
                students: [`${studentId}`]
            });
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();
                onDeleteSuccess(res?.data?.class);
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(`هناك مشكله ما`)
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <h5>{HomePage.didSuredelStu[lang]}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {HomePage.delete[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteStudent
