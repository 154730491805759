import React, { useEffect, useState } from "react";
// import Col from "../styled-components/col";
// import Contain from "../styled-components/contain";
import "./booking.css";
import { useDispatch, useSelector } from "react-redux";
import { Inetialize_Products } from "../../actions/product";
import { Choose_Package } from "../../actions/booking";
import title from '../../assets/book-title.png';
import "./bookingBoard.css";
import BlueHeader from '../publicPages/blueHeader.js';
import ChooseDate from './chooseDate'
function Category() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Inetialize_Products());
  }, [dispatch]);
  const { multiProducts, user,teacher,booking } = useSelector(mapStateToProps);
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState("SAR");

  useEffect(() => {
    product && document.getElementById("book3").reset();
  }, [product]);

  // const currencies = [
  //   { name: "دينار كويتى", value: "KWD" },
  //   { name: "ريال سعودى", value: "SAR" },
  //   { name: "دولار امريكى", value: "USD" },
  // ];

  // submit
  
  return (
    <>
      <BlueHeader title='Reservation details'/>
      <div className="book-board">
        <div id='book7'>
      <i><img src={title} alt='AGA-booking'/></i>
        <h2 id='book5'>Reservation details</h2>
        {/* <select
                className="currency"
                onChange={(event) => setCurrency(event.target.value)}
              >
                <option value="SAR" disabled>
                  العملة
                </option>
                {currencies.map((c, i) => (
                  <option key={i} value={c.value}>
                    {c.name}
                  </option>
                ))}
              </select> */}
        </div>
        <div className="category-board">
          <div className="cates">
            <div className="board-cat">
              <h2 id="book1">. The number of classes per week</h2>
              {multiProducts.map((m, i) => (
                <div key={i} className="booking-categories">
                  {/* <img src={m.image} width="70px" alt={m.name} /> */}

                  <input
                    className="booking-radio"
                    type="radio"
                    name={"category"}
                    id={m.name}
                    onChange={() => {
                      setProduct(m);
                      setPrice(null);
                    }}
                    // checked={true}
                    // defaultValue={true}
                  />
                  <label id={m.name + i} className="" htmlFor={m.name}>
                    {m.name}
                  </label>
                </div>
              ))}
            </div>

            <form className="board-sub " id="book3">

              <h2 id="book22">. number of months</h2>

              {product &&
                product.subCategory.map((m, i) => (
                  <div className="booking-categories" key={i}>
                    <input
                      className="booking-radio"
                      id={'product'+i}
                      type="radio"
                      name="subCategory"
                      value={m.price}
                      onClick={(event) => {
                        setPrice(m.price);
                        const packag = {
                          ...m,
                          packag: product.name,
                          currency: currency,
                          disscountAmount: 0,
                          disscountCode: null,
                        };
                        dispatch(Choose_Package(packag));
                      }}
                      required
                    />
                    <label id={'prodlab' + i} className="" htmlFor={i}>
                      {m.name}
                    </label>
                  </div>
                ))}
            </form>
          </div>

          <div className="price-board">
            <label id="bookp">Total amount</label>
            <br />
            <input onChange={()=>{}} value={price ? price + currency : 0} />
          </div>
        
        </div>
        <div className="book-date-board">
          <img src={teacher.personalImage} alt='AGA-teacher'/>
        {booking.package&&<ChooseDate/>}
        </div>
      </div>
    </>
  );
}
function mapStateToProps({ products, loggedUser,users,booking }) {
  let multiProducts = products.filter((p) => p.type === "multi");
  const user = loggedUser;
  if (user.auth === true) {
    if (user.user.freeSession) {
      multiProducts = multiProducts.filter(
        (m) => (m.subCategory.length > 1) & (m.subCategory[0].price !== "0")
      );
    }
  }
  const [teacher] = users.teachers.filter((t)=>
  t._id===booking.teacher)
  return {
    multiProducts,
    user,
    teacher,
    booking
  };
}
export default Category;
