import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../translations/trans';
import { urlBase } from '../../utils/API';
const ViewHw = ({ session, show,
    handleClose }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [homework, setHomeWork] = useState([]);

    const loggedUser = useSelector((state) => state.loggedUser)

    useEffect(() => {
        if (show) {
            const homeworkdata = session?.homeWork?.filter((hw) => hw?.student?._id === loggedUser?.user?._id)
            console.log("hhhommme Work", homeworkdata)
            setHomeWork(homeworkdata)
        }
    }, [show])

    return (
        <div>
            <div>
                {/* <button className='mx-3' onClick={handleShow}
                    
                    style={{ color: "white", background: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>
                    {HomePage.viewHW[lang]}

                </button> */}

                <Modal show={show} onHide={handleClose} centered className='py-5' dir={dir}>
                    <Modal.Body>
                        {
                            homework.length > 0 ? (
                                homework.map((hw) => (
                                    <div className="mt-3" key={hw._id}>
                                        <iframe
                                            src={`${urlBase}/${hw.file}`}
                                            title={`${hw}`}
                                            width="100%"
                                            height="500px"
                                            style={{ border: 'none' }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="text-center mt-3">
                                    <p>{lang === 'AR' ? 'لا يوجد بيانات للعرض' : 'No data available to display'}</p>
                                </div>
                            )
                        }
                    </Modal.Body>

                </Modal>
            </div>
        </div>
    )
}

export default ViewHw
