import React,{useEffect,useState} from "react"
import {urlBase} from "../../utils/API"

function Image({image}){
  
    const IM = image?image.image:null
    const [im ,setIm] =useState('')
    
    useEffect(()=>{
        const path = IM?require(`${urlBase}/uploads/${IM}`):''
        setIm(path)
    },[image])

    return (
      <>
      <img src={ im
     } width="130px" height="150px" /> 
      </>
    )
  }
  export default Image;