import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
import { urlBase } from '../../utils/API';
const ViewHW = ({ session, studentId }) => {
    const [showTable, setShowTable] = useState(false);
    const [students, setStudents] = useState([]);
    const [studentHomework, setStudentHomework] = useState([]);


    const handleCloseTable = () => setShowTable(false);
    const handleShowTable = () => {
        setShowTable(true)
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    // console.log(session)
    const checkHomeWork = () => {
        if (session?.homeWork) {
            const [filteredHomework] = session.homeWork.filter(homework => homework?.student?._id === studentId);
            //  console.log('ffffffffffffff',filteredHomework)
            setStudentHomework(filteredHomework);
        }
    };

    useEffect(() => {
        checkHomeWork();
    }, [session, studentId]);

    // console.log(studentHomework)
    return (

        <div>
            <button className='btn' onClick={handleShowTable} style={{ backgroundColor: "#4169E2" }}>
                {HomePage.viewHW[lang]}
            </button>
            <Modal show={showTable} onHide={handleCloseTable} centered size='xl' dir={dir}>
                <Modal.Body >
                    <div className="pdf-container">
                        {/* <iframe style={{ backgroundColor: '#fff' }} src={`${urlBase}/${studentHomework?.file}`} width="100%" height="1200px" title={`${studentHomework?.file}`} /> */}
                        <object className="pdf-object" data={`${urlBase}/${studentHomework?.file}`} type="application/pdf">
                        <embed src={`${urlBase}/${studentHomework?.file}`} type="application/pdf" />
                      </object>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViewHW
