import { ADD_NEW_MOCK, DELETE_MOCK, GET_ALL_MOCKS, UPDATE_MOCK } from "../actions/mockAction"


export const mockReducer = (state = [], action) => {
    switch (action.type) {
        case GET_ALL_MOCKS:
            console.log(action)
            return state = action.mocks
        // case ADD_NEW_MOCK:
        //     return state = [...state, action.mock]
        // case UPDATE_MOCK:
        //     console.log(action)
        //     return state = state.map(s => {
        //         if (s._id === action.mock._id) {
        //             return action.mock
        //         }
        //         return s
        //     })
        // case DELETE_MOCK:
        //     console.log(action)
        //     return state = state.filter(s => s._id !== action.id)
        default: return state
    }
}

