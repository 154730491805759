import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { sendMessageWhatsApp } from '../../utils/class';
import { ErrorMsg, success } from '../../utils/Toast';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
import { urlBase } from '../../utils/API';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
const SendWhatsappMsg = ({ show, handleClose, Class, studentId }) => {

    const [message, setMessage] = useState("");
    const [type, setType] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const loggedUser = useSelector((state) => state.loggedUser);


    const uploadingFileLocally = (data) => {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
        })
    }
    const handleAddMessageWhats = async () => {
        setLoading(true)
        try {
            if ((type !== null || type !== "") && message !== "") {
                let fileUrl = null;
                if (file) {
                    const uploadResponse = await uploadingFileLocally(file);
                    fileUrl = uploadResponse.data.data.name;
                }
                const res = await sendMessageWhatsApp({
                    classId: Class?._id,
                    message: message,
                    type: type,
                    file: fileUrl,
                    students: [`${studentId}`]
                }, loggedUser?.user?._id);
                if (res?.data?.case) {
                    success(res?.data?.message)
                    handleClose();
                }
            } else {
                ErrorMsg(`قم باختيار نوع الرساله ونصها`)
            }
        } catch (e) {
            console.log(e)
            if (e?.response) {
                ErrorMsg("الرساله فارغه")
            }
        }finally{
            setLoading(false)
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body dir={dir}>
                    <h5>{HomePage.enterMessage[lang]}</h5>


                    <input
                        type="file"
                        className='form-control'
                        accept="image/*,.pdf,.doc,.docx"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => setFile(e.target.files[0])}
                    />

                    <div>
                        <select onChange={(e) => setType(e.target.value)} value={type} className="w-100 form-control mb-3 mt-3">
                            <option hidden>{HomePage.chooseType[lang]}</option>
                            <option value={1}>{HomePage.sendToStudent[lang]}</option>
                            <option value={2}>{HomePage.sendToParent[lang]}</option>
                            <option value={3}>{HomePage.sendToParentAndStudent[lang]}</option>
                        </select>
                    </div>

                    <div>
                        <textarea row={"8"} style={{ resize: "none" }} className='w-100 form-control' value={message} onChange={(e) => setMessage(e.target.value)}>

                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        إلغاء
                    </Button>
                    <Button variant="primary" onClick={handleAddMessageWhats} disabled={loading}>

                        {loading ? <Spinner animation="border" variant="light" /> : 'اضافه'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SendWhatsappMsg
