import axios from 'axios';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { urlBase } from '../../utils/API';
import { handleAddAnswer } from '../../utils/mock';
import { ErrorMsg ,success} from '../../utils/Toast';

const AddAnswerMock = ({ answer }) => {
    const [file, setFile] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    console.log(answer)



    const loggedUser = useSelector((state) => state.loggedUser);

    const uploadingFileLocally = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
        console.log(response);
        return response.data.data;
    };

    const handleSendMock = async () => {
        try {
            setLoading(true);
            const fileData = file ? await uploadingFileLocally(file) : null;

            const res = await handleAddAnswer({ answer: fileData?.name }, answer?._id, loggedUser?.user?._id)
            console.log(res);
            if(res?.data?.case){
                success(res?.data?.message)
            }
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <button className="btn" style={{ background: "#6B8F04", color: "white" }} onClick={handleShow}>
                Add Answer Mock
            </button>

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Add Answer Mock</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input type="file" onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" disabled={loading} onClick={handleSendMock}>
                        {loading ? 'Loading…' : 'Add Answer Mock'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddAnswerMock
