import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { TbReplaceFilled } from "react-icons/tb";
import { getLessonsOfSpecificMaterial, updateLessonContent } from '../../../utils/lessons';
import { ErrorMsg, success } from '../../../utils/Toast';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { urlBase } from '../../../utils/API';
const UpdateLessonContent = ({
    lessonContent,
    lessonId,
    materialCode,
    show,
    handleClose
}) => {
    // const [show, setShow] = useState(false)
    // const handleShow = () => setShow(true)
    // const handleClose = () => setShow(false)
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false)

    console.log("Content", lessonContent)
    console.log("Iddddd", lessonId)
    const loggedUser = useSelector((state) => state.loggedUser);

    const uploadingFileLocally = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
        console.log(response);
        return response.data.data;
    };


    const handleUpdateContent = async () => {
        setLoading(true)
        try {
            const file = image ? await uploadingFileLocally(image) : null
            if (file) {
                const res = await updateLessonContent({
                    contentName: lessonContent?.name,
                    data: file
                }, lessonId)
                console.log(res)
                if (res?.data?.case) {
                    success("Updated")
                    handleClose();
                }

            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false);
            const lessonsData = await getLessonsOfSpecificMaterial({ code: materialCode });
        }
    }
    return (
        <div>
            
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <input type="file" className="form-control" name="file-lesson" id="file-lesson"
                        onChange={(event) => {
                            setImage(event.target.files[0]);
                            const url = window.URL.createObjectURL(event.target.files[0]);

                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn" style={{ backgroundColor: "#283476",color:"white" }} onClick={handleUpdateContent} disabled={loading}>
                        {loading ? "loading..." : "Update"}
                    </button>
                    <button className="btn" style={{ backgroundColor: "#283476" }} onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateLessonContent
