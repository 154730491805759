import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Current_Product,Update_Product } from "../../actions/product";

function EditProduct() {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const { product } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // console.log(product);
  const max = product.subCategory.length;
  //   const [max, setMax] = useState(1);
  const [text, setText] = useState(null);
  const [choosed, setChoosed] = useState([]);
  //-------------------------------
// on Choose days
  const onChooseDays = (value, productIndex) => {
    let subcategory = product.subCategory[productIndex];
    const newProduct = product;
    if (subcategory.days) {
      if (subcategory.session == subcategory.days.length) {
        subcategory.days.shift();

        subcategory.days.push(days.indexOf(days[value]));

        newProduct.subCategory[productIndex] = subcategory;
      } else {
        subcategory.days.push(days.indexOf(days[value]));
      }
    } else if (!subcategory.days) {
      subcategory.days = [days.indexOf(days[value])];
    }
    dispatch(Current_Product(newProduct));
    //  return newProduct
  };
  //-------------------------------------
  // onchange subCategory
  const onChange = (key, value, productIndex) => {
    const sub = product.subCategory[productIndex];
    const newProduct = product;
    if(key==='session'&value<sub.days.length){
      newProduct.subCategory[productIndex].days.splice(0,sub.days.length-value)     
    }
    newProduct.subCategory[productIndex][key] = value;
    dispatch(Current_Product(newProduct));
  };
  //--------------------------------------
  // onChange Category
  const changeCategory = (key,value)=>{
       let newProduct = product;
       newProduct[key] = value;
       dispatch(Current_Product(newProduct))
  }
  //--------------------------------------
  // handel changes of product

  const handelChanges = (e)=>{

    e.preventDefault();
    dispatch(Update_Product(product._id,product))

  }
  return (
    <form
      className="create-form "
      // encType="multipart/form-data"
      onSubmit={handelChanges}
    >
      <label htmlFor="co">Course name:</label>
      <input
        className="tex"
        id="co"
        type="text"
        name="name"
        placeholder="course"
        onChange={(event)=>changeCategory(event.target.name,event.target.value)}
        defaultValue={product.name}
        required
      />
      <label htmlFor="dis">Discription:</label>

      <input
        className="tex "
        id="dis"
        type="text"
        name="discription"
        placeholder="discription"
        defaultValue={product.discription}
        onChange={(event)=>changeCategory(event.target.name,event.target.value)}
        required
      />
      <label htmlFor="nos">Number of subCategories:</label>
      <input
        type="number"
        id="nos"
        className="tex "
        min="0"
        placeholder="number of subCategories"
        value={product.subCategory.length}
        readOnly
        // onChange={(event) => onChange(event.target.value)}
      />
      {product.subCategory.map((p, i) => (
        <div>
          <h2 className="sub-category-title">SubCategory {p.name} </h2>
          <label htmlFor="scpr">Price 'SR':</label>
          <input
            className="tex"
            id="scpr"
            type="number"
            name="price"
            defaultValue={p.price}
            placeholder="price"
            onChange={(event) =>
              onChange(event.target.name, event.target.value, i)
            }
            required
          />
          <label htmlFor="scn">Name:</label>

          <input
            className="tex"
            id="scn"
            type="text"
            name="name"
            defaultValue={p.name}
            placeholder={`subcategory${p.name}`}
            onChange={(event) =>
              onChange(event.target.name, event.target.value, i)
            }
            required
          />
          <label htmlFor="tnos">Total number of sessions per course:</label>

          <input
            className="tex"
            id="tnos"
            type="number"
            name="period"
            min="1"
            defaultValue={p.period}
            placeholder={"Total Number Of Sessions ...."}
            onChange={(event) =>
              onChange(event.target.name, event.target.value, i)
            }
            required
          />
          <label htmlFor="spw">Sessions Per Week:</label>

          <input
            className="tex"
            id="spw"
            type="number"
            name="session"
            min="0"
            defaultValue={p.session}
            placeholder={`Sessions Per Week`}
            onChange={(event) =>
              onChange(event.target.name, event.target.value, i)
            }
            required
          />
          <div className="drop-dw-title">
            <span>
              {p.days &&
                p.days.map((e, i) => <span key={i}>{days[parseInt(e)]} </span>)}
            </span>
          </div>
          <label> Choosed Days => .</label>
          <select
            className="drop-dw-select"
            onChange={(event) => onChooseDays(event.target.value, i)}
          >
            {days.map((o, i) => (
              <Option className="drop-dw-option" key={i} index={i} value={o} />
            ))}
          </select>
        </div>
      ))}
      <input type='submit' value='save'/>
    </form>
  );
}
function mapStateToProps({ general }) {
  return { product: general.currentP };
}
function Option({ index, value }) {
  return (
    <>
      <option className="drop-down-option" value={index} id={index}>
        {value}
      </option>
    </>
  );
}
export default EditProduct;
