import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { urlBase } from '../../utils/API';
import { submitAnswerStudent } from '../../utils/mock';
import { ErrorMsg, success } from '../../utils/Toast';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
const ViewAnswer = ({ show, handleClose, answer, index }) => {
    // const [show, setShow] = useState(false)
    // const handleShow = () => setShow(true)
    // const handleClose = () => setShow(false)
    const { lang, dir, user } = useSelector(({ language, loggedUser }) => {
        return { lang: language.lang, dir: language.dir, user: loggedUser?.user };
    });

    const [loading, setLoading] = useState(false)

    const submitMockAnswer = async (userId, mockId) => {
        try {
            console.log(userId, mockId)
            setLoading(true)
            const res = await submitAnswerStudent(userId, mockId);
            if (res?.data?.case) {
                success(res?.data?.message);
            }
            else {
                ErrorMsg(res?.data?.message)
            }
            setLoading(false)
        }
        catch (err) {
            console.warn(err?.message);
            ErrorMsg(err?.response?.data?.message);
            setLoading(false)
        } finally {
            handleClose();
        }

    }

    console.log(answer)

    return (
        <div>
            {/* <div
                style={{
                    backgroundColor:
                        title?.state === "pending"
                            ? "#6c757d"
                            : title?.state === "cancelled"
                                ? "#dc3545"
                                : title?.state === "submitted"
                                    ? "#28a745"
                                    : "#007bff",
                    color: "white",
                    padding: "2px",
                    borderRadius: "5x"
                }}
                
            >
                {`Result ${index + 1}`}
                &nbsp;
                <p>Mark: {title?.marks !== null ? title?.marks : 'لا يوجد درجه بعد'}</p>
            </div> */}

            <Modal show={show} onHide={handleClose} centered size='xl' className='text-center'>
                <Modal.Body>
                    <div>
                        <h3>{HomePage.status[lang]} : {answer?.state}</h3>
                        {
                            answer?.state === "approved" || answer?.state === "cancelled" || answer?.state==="submitted" ? (
                                <div>
                                    <span style={{ fontSize: "25px" }}>{HomePage.marks[lang]}: {answer?.marks}</span>
                                </div>
                            ) : (
                                <button className="btn mb-5"
                                    onClick={() => { submitMockAnswer(user?._id, answer?._id) }}
                                    disabled={loading}
                                    style={{ backgroundColor: "#6B8F04", color: "white" }}
                                >
                                    {loading ? HomePage.loading[lang] : HomePage.submit[lang]}
                                </button>
                            )
                        }
                    </div>
                    <iframe src={`${urlBase}/${answer?.file}`} title="Embedded PDF" width="100%" height="500px" />

                    <div>
                        Answer Of Mock : 
                        <iframe src={`${urlBase}/${answer?.answer}`} title="Embedded PDF" width="100%" height="500px" />
                        {/* <img src={`${urlBase}/${answer?.answer}`} alt='' width="100%" height="500px" /> */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViewAnswer
