import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function MenuSelection({ setStatus, status, name1 , name2 }) {
  return (
    <ButtonGroup style={{ width: '100%' ,height:'50px'}}>
      <Button
        variant={status ? 'success' : 'secondary'}
        onClick={() => setStatus(true)}
        style={{ width: '40%',borderRadius:'15px' }}
      >
        {name1}
      </Button>
      <Button
        variant={!status ? 'success' : 'secondary'}
        onClick={() => setStatus(false)}
        style={{ width: '40%' ,borderRadius:'15px'}}
      >
        {name2}
      </Button>
    </ButtonGroup>
  );
}

export default MenuSelection;
