import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import UploadHomeWork from './UploadHomeWork';
import { useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

import { HomePage } from '../../translations/trans';
import ShowVideoRecord from './ShowVideoRecord';
const ShowLiveSessions = ({ sessions, lesson }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state) => state.loggedUser)
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    console.log("sessss", sessions)
    console.log("lessssson", lesson)

    return (
        <div>
            <div>
                <button className=' mx-3' onClick={handleShow} style={{ background: "white", color: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>{HomePage.liveSession[lang]}</button>

                <Modal show={show} onHide={handleClose} centered size='xl' className='text-center' dir={dir}>
                    <h3>{HomePage.noteEmail[lang]}</h3>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>{HomePage.sessionName[lang]}</th>
                                    <th>{HomePage.sessionDate[lang]}</th>
                                    <th>{HomePage.enter[lang]}</th>
                                    <th>{HomePage.record[lang]}</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    sessions
                                        
                                        ?.map((session) => {
                                            const formattedDate = new Date(session?.date).toLocaleString('en-GB', { timeZone: 'UTC' });

                                            return (
                                                <tr key={session._id}>
                                                    <td>{session?.note}</td>
                                                    <td>{formattedDate}</td>
                                                    <td>
                                                        {
                                                            session.meetingEnd === true ? ('Ended') : (
                                                                <Link to={`${session?.joinLink}`}>{HomePage.goSession[lang]}</Link>
                                                            )
                                                        }

                                                    </td>
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                {HomePage.record[lang]}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {
                                                                    session.record.map((record, index) => {
                                                                        return (
                                                                            <ShowVideoRecord record={record} index={index} />
                                                                        )
                                                                    })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ShowLiveSessions;