import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../translations/trans';
import { useSelector } from 'react-redux';

const ViewComment = ({ answer }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <button className="btn" style={{ background: "#6B8F04", color: "white" }} onClick={handleShow}>{HomePage.viewComment[lang]}</button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header >
                    <Modal.Title>{HomePage.comment[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{answer?.comment}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.close[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewComment
