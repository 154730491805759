import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { getAnswersMock } from '../../utils/mock';
import { urlBase } from '../../utils/API';
import Dropdown from 'react-bootstrap/Dropdown';

const MocksModal = ({ show, handleClose, classData,
    stdData }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const loggedUser = useSelector((state) => state.loggedUser)

    const [result, setResult] = useState([]);


    const handleGetcustomizdMock = async () => {
        try {
            const res = await getAnswersMock({
                classId: classData?._id,
            }, stdData?._id, loggedUser?.user?._id)
            console.log(res)
            if (res?.data?.case) {
                setResult(res?.data?.mocks)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleGetcustomizdMock()
    }, [classData, stdData])
    const navigate = useNavigate();
    console.log(result)
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size='lg' dir={dir}>
                <Modal.Body dir={dir}>
                    <Table >
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>{HomePage.mocks[lang]}</th>
                                <th>{HomePage.desc[lang]}</th>
                                <th>{HomePage.showfile[lang]}</th>
                                <th>{HomePage.lastState[lang]}</th>
                                <th>{HomePage.view[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                result?.length > 0 ? (
                                    result?.map((mock, index) => (
                                        <tr key={mock?.mock?._id}>
                                            <td>{index + 1}</td>
                                            <td>{mock?.mock?.name}</td>
                                            <td>{mock?.mock?.description}</td>
                                            <td>
                                                <a href={`${urlBase}/${mock?.mock?.file}`} target='_blank' rel="noreferrer">{HomePage.showfile[lang]}</a>
                                            </td>
                                            <td>{mock?.lastState}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='btn' style={{ color: "white", background: "#1F3662" }} id="dropdown-basic">
                                                        {HomePage.view[lang]}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {
                                                            mock?.studentAnswers?.map((answer, index) => (
                                                                <Dropdown.Item
                                                                    key={answer?._id}
                                                                    onClick={() => navigate(`/user/${loggedUser?.user?._id}/mocks-degree`, {
                                                                        state: { mock: mock, answer: answer } // Pass mock and answer in state
                                                                    })}
                                                                >
                                                                    {HomePage.res[lang]} {index + 1} <span style={{ fontSize: "10px" }}>{answer?.createdAt}</span>
                                                                    &nbsp;<p>{HomePage.marks[lang]} : {answer?.marks}</p>
                                                                </Dropdown.Item>
                                                            ))
                                                        }

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                ) : null
                            }
                        </tbody>
                    </Table>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default MocksModal
