import React, { useEffect, useState } from "react";
import Col from "../styled-components/col";
import Contain from "../styled-components/contain";
// import { BsInstagram } from "react-icons/bs";
import { AiFillYoutube, AiOutlineTwitter, AiFillTwitterSquare } from "react-icons/ai";
// import{ImLinkedin2} from 'react-icons/im'
import { FaFacebookF, FaWhatsappSquare, FaInstagramSquare } from 'react-icons/fa'
import { Rights_Text2, whatsappNum } from "../../constants";


function Social() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

  }, []);
  return (
    <>
      <Contain height={windowSize.width > 1000 ? '76px' : '33px'} perfix='social-section social_bg'>
        <Col width={windowSize.width > 1000 ? '30%' : '50%'}>
          <span className='social-left'>
            {Rights_Text2}
          </span>
        </Col>
        {windowSize.width > 1000 && <Col width='40%'>
          <div className="social-cont">
            <a className="sociall" href={`https://wa.me/${whatsappNum}`}>
              <FaWhatsappSquare
                color='#32d121'
                size="28px"
              />
            </a>
            {/* <a className={"sociall "} href={"https://facebook.com"}>
              <ImLinkedin2 size="28px"  />
            </a> */}
            {/* <a className={"sociall "} href={"https://facebook.com"}>
              <FaInstagramSquare 
              color='#ec4a64'
              size="28px"  />
            </a> */}
            <a className={"sociall "} href={"https://www.facebook.com/profile.php?id=100064072627564&mibextid=ZbWKwL"}>
              <FaFacebookF
                color='blue'
                size="28px"
              />
            </a>
            {/* <a className={"sociall "} href={"https://facebook.com"}> */}
            {/* <a className="sociall" href={"https://facebook.com"}>
              <FaFacebookF
                size="28px"   
              />
            </a> */}


          </div>
        </Col>}
        <Col width={windowSize.width > 1000 ? '30%' : '45%'} justify='flex-end'>
          <span className='social-right'>privacy policy</span>
        </Col>
      </Contain>
    </>
  );
}

export default Social;
