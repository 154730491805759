import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Get_All_Packages, Update_Package } from '../../../actions/packages';
import { success } from '../../../utils/Toast';
import axios from "axios";
import { urlBase } from '../../../utils/API';
import { HomePage } from '../../../translations/trans';

const ModalCoverImage = ({ pack }) => {
    const [show, setShow] = useState(false)
    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    const [coverImage, setCoverImage] = useState(pack?.coverImage);
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const uploadingFileLocally = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
        return response.data.data.name;
    };

    const handlePackage = async () => {
        try {
            const fileName = await uploadingFileLocally(coverImage);
            await dispatch(Update_Package({ ...pack, coverImage: fileName, id: pack._id }));
            success(`تم تحديث صورة البرنامج`);
            handleClose(); // Close the modal after updating
        } catch (e) {
            console.log(e);
            dispatch(Get_All_Packages());
            handleClose();
            success(`تم تحديث صورة البرنامج`);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setCoverImage(file); // Set the file in state
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <>
            <button onClick={handleShow} className="btn" style={{ color: "white", background: "#0a53be" }}>{HomePage.editCoverImage[lang]}</button>
            <Modal show={show} onHide={handleClose} dir={dir}>
                <Modal.Header >
                    <Modal.Title>{HomePage.updateCoverImage[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-input">
                        <input
                            type="file"
                            name="file"
                            id="file"
                            className="form-control"
                            onChange={handleFileChange} // Handle file selection
                        />
                        {/* <label className="file-input__label" htmlFor="file-input">
                            <span>Upload file</span>
                        </label> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.close[lang]}
                    </Button>
                    <Button variant="primary" onClick={handlePackage} disabled={!coverImage}>
                        {HomePage.edit[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalCoverImage;
