import React from 'react'
import {useSelector} from "react-redux";
function TestResults() {
  
    const { userAnswers, totalScore,tries,testResults } = useSelector(({testResults}) => {
      return {userAnswers:testResults.questions
      , totalScore:(testResults.result/testResults.total)*100,
      tries:testResults.tries,
      testResults
      }
    });

    console.log(totalScore);
  
    return (
      <div className="mx-auto my-auto">
        <div dir="rtl" className="v-flex justify-content-between">
        <h4>حالة الاختبار: {testResults.passed?" ناجح " : " راسب "}</h4>
                                    <h4>الدرجة: {testResults.result} / {testResults.total} </h4>
          <h4>
            النسبة المائوية :<span className="mx-2">{`${totalScore} %`}</span>{" "}
          </h4>
          <h4>
            عدد المحاولات :<span className="mx-2">{`${tries}`}</span>{" "}
          </h4>
        </div>
  
        {userAnswers.map((answer, i) => (
          <div key={i} dir='ltr'>
            <h3 className="my-4 w-200">{answer.question}</h3>
            <div className="row">
              {answer.choices.map((c, index) => (
                <div
                  key={index}
                  className={`col-sm-2 answer mx-1 p-2 ${
                    c.correct===true ?
                     "correctAnswer" : ""
                  } ${
                    !c.correct && c.value === answer.chosenAnswer
                    ? "wrongAnswer"
                      : ""
                  }`}
                >
                  {c.value}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  
}

export default TestResults