export const SET_LESSON = 'SET_LESSON';

const setLesson = (lesson)=>{
    return {
        type:SET_LESSON,
        lesson
    }
}
export const Set_Lesson = (lesson)=>{
    return (dispatch)=>dispatch(setLesson(lesson))
}


export const SET_TEST = 'SET_TEST';
const setTestFor = (testFor)=>{
    return {
        type:SET_TEST,
        testFor
    }
}
export const Set_Test_For = (testFor)=>{
    return dispatch=>dispatch(setTestFor(testFor))
}
// SET COURSE
export const SET_COURSE = 'SET_COURSE';
const setCourse = (course)=>{
    return {
        type:SET_COURSE,
        course
    }
}
export const Set_Course = (course)=>{
    return dispatch => dispatch(setCourse(course))
}