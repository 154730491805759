
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteSession } from '../../utils/sessions';
import { ErrorMsg, success } from '../../utils/Toast';
import { addNewHomeWorktask } from '../../utils/homeworkapi';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';

const ModalUploadHomeWork = ({ show, handleClose, sessionId, classId, session }) => {

    console.log(session?.homeWorkTask)
    const [homeWork, setHomeWork] = useState("لم يتم اضافة واجب من قبل");
    const [date, setDate] = useState(null);
    const [localDate, setLocalDate] = useState(null);


    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        // const dateWithThreeHours = addThreeHours(selectedDate);
        const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
        console.log(adjustedDate)
        setLocalDate(adjustedDate.toISOString().slice(0, 16));
        const formattedDate = adjustedDate.toISOString().split('.')[0] + '+03:00';
        setDate(formattedDate);
    };

    useEffect(()=>{
        setHomeWork(session?.homeWorkTask)
    },[session])

    const handleAddHomeWork = async () => {
        try {
            const res = await addNewHomeWorktask({
                classId: classId,
                sessionId: sessionId,
                homeWorkTask: homeWork,
                homeWorkDeadline: date
            });
            if (res?.data?.case) {
                success(res?.data?.message)
            }
            handleClose();
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <Modal show={show} onHide={handleClose} centered dir={dir}>
            <Modal.Body>
                <h5>{HomePage.AddHomeWork[lang]}</h5>
                <div>
                    <textarea
                        onChange={(e) => setHomeWork(e.target.value)}
                        style={{ resize: "none", outline: "none" }}
                        value={homeWork}
                        className="w-100"
                    />




                    <input
                        type="datetime-local"
                        value={localDate}
                        onChange={handleDateChange}
                        style={{ border: "1px solid #120C54", color: "#120C54" }}
                        name="timeRoom"
                        id="timeRoom"
                        isutc={true}
                        className="form-control mt-3 py-2"
                    />

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {HomePage.cancel[lang]}
                </Button>
                <Button variant="danger" onClick={handleAddHomeWork}>
                    {HomePage.add[lang]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalUploadHomeWork;
