import React, { useEffect } from "react";
import Col from "../styled-components/col";
import Button from "../styled-components/button";
import Contain from "../styled-components/contain";
import "./table.css";
import {useDispatch, useSelector} from "react-redux"
import { Set_Schedual,Update_Teacher } from "../../actions/teachers";
import {Inetialize_Schedual} from "../../actions/schedual"
import {convertArrToObj,convertObjToArr} from '../globalFunc';
import Row from "./Row"

function Table1() {
    const days = [
        { name: "Sunday"},
        { name: "Monday"},
        { name: "Tuesday"},
        { name: "Wednesday"},
        { name: "Thursday"},
        { name: "Friday"},
        { name: "Saturday"},
      ];
    
    const dispatch = useDispatch()
    const {savedSchedual,objSchedual,userEmail,teacher}= useSelector(mapStateToProps);
    useEffect(()=>{
        const oldSchedualArr  =savedSchedual? convertObjToArr(savedSchedual):null
        oldSchedualArr&&dispatch(Inetialize_Schedual(oldSchedualArr))
    },[])
    const onSubmit=(e)=>{
        try{
         e.preventDefault();
         dispatch(Set_Schedual(objSchedual,userEmail))
       }catch(err){
          console.log(err)
        }
     }
     const ChangeActiveState = ()=>{
      try{
      const newTeacher=teacher;
      newTeacher.active=!newTeacher.active;
         dispatch(Update_Teacher(newTeacher))
     }
    catch(err){console.log(err)}
     }
  return (
    <>
     <Contain >

<Col width="70%" fg={"0"} align="start" dir="column" perfix='schedual-cont' >
  <Button name={teacher.active?'deactivate':'activate'} onClick={ChangeActiveState}/>
   
  <form onSubmit={onSubmit}>
  {days.map((d, i) => (
   <div key={i}>
        <div  className="teacher-schedual-row">
        <Row  name={d.name} index={i} oldSchedual={savedSchedual&&savedSchedual[i]} />
      
      </div>
      <br />
      </div>
  ))}
  <input type='submit' value='save' className="submit" />
  </form>
</Col>
</Contain>
    </>
  )
}

function mapStateToProps ({schedual,loggedUser}){
    const objSchedual = convertArrToObj(schedual,'index')
    const userEmail = loggedUser.user.email;
    const teacher = loggedUser.user
    const savedSchedual = loggedUser.user.schedual
   return {
    savedSchedual,objSchedual,userEmail,teacher
   }
}
export default Table1