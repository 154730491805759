import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Delete_Level } from '../../../actions/curriculums/levels';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
const ModalDelete = ({ id, setMessage }) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = () => {
        try {
            dispatch(Delete_Level(id)).then(res => {
                setMessage(res.data.message)
            })
            handleClose();
        } catch (e) {
            console.warn(e);
            setMessage(e.message)
            handleClose()
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <RiDeleteBin6Line onClick={handleShow} size='25px' />

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.delete[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body> {HomePage.wantDelete[lang]}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete()}>
                        {HomePage.yes[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDelete
