import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getStudentsByIDS } from '../../utils/API';
import { downloadHomeWork } from '../../utils/homeworkapi';
import { ErrorMsg } from '../../utils/Toast';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
import ViewHW from './ViewHW';

const ReportModal = ({ classData, session }) => {
    const [showTable, setShowTable] = useState(false);
    const [students, setStudents] = useState([]);

    const handleCloseTable = () => setShowTable(false);
    const handleShowTable = () => {
        setShowTable(true)
    }

    const getAllStudents = async () => {
        try {
            const response = await getStudentsByIDS({ IDS: classData?.studentsIDS });
            console.log("response", response)
            setStudents(response?.data?.students)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getAllStudents();
    }, [classData])

    const checkAttendance = (studentId) => {
        return session?.attendance?.some(attendance => attendance?.student?._id === studentId);
    }
    const checkHomeWork = (studentId) => {
        return session?.homeWork?.some(homework => homework?.student?._id === studentId);
    }


    const downloadHomework = async (studentId) => {
        try {
            const res = await downloadHomeWork({

                classId: classData?._id,
                studentId: studentId,
                sessionId: session?._id

            })
            console.log(res)
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }


    // console.log(students)

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <button className='btn' style={{ color: "white", background: "#4169E2" }} onClick={handleShowTable}>{HomePage.reports[lang]}</button>
            <div>
                <Modal show={showTable} onHide={handleCloseTable} centered size='xl' dir={dir}>
                    <Modal.Body >
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>num</th>
                                    <th>{HomePage.nameForm[lang]}</th>
                                    <th>{HomePage.attend[lang]}</th>
                                    <th>{HomePage.homeWork[lang]}</th>
                                    <th>{HomePage.downloadHW[lang]}</th>
                                    <th>{HomePage.viewHW[lang]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    students?.map((student, index) => {
                                        const isPresent = checkAttendance(student?._id);
                                        const isHomwwork = checkHomeWork(student?._id);
                                        return (
                                            <tr key={student?._id}>
                                                <td>{index + 1}</td>
                                                <td>{student?.name}</td>
                                                <td>{isPresent ? `${HomePage.present[lang]}` : `${HomePage.attend[lang]}`}</td>
                                                <td>
                                                    {
                                                        isHomwwork ? (
                                                            <button className='btn' style={{ color: "white", background: "#4169E2" }}>{HomePage.Send[lang]}</button>
                                                        ) : (
                                                            <button className='btn' style={{ background: "#DBEAFF", color: "#4169E2" }}>{HomePage.notSend[lang]}</button>
                                                        )
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        isHomwwork ? (
                                                            <button className='btn' onClick={() => { downloadHomework(student._id) }} style={{ color: "white", background: "#4169E2" }}>{HomePage.downloadHW[lang]}</button>
                                                        ):(
                                                            null
                                                        )
                                                    }
                                                    {/* <button className='btn' onClick={() => { downloadHomework(student._id) }} style={{ color: "white", background: "#4169E2" }}>{HomePage.downloadHW[lang]}</button> */}
                                                </td>
                                                <td>
                                                    {
                                                        isHomwwork ? (
                                                            <ViewHW session={session} studentId={student?._id} />
                                                        ):(null)
                                                    }
                                                    {/* <ViewHW /> */}
                                                    {/* <button className='btn' style={{ backgroundColor: "#4169E2" }}>
                                                        {HomePage.viewHW[lang]}
                                                    </button> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ReportModal;
