import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { GetMaterialsByIds } from '../../../utils/materials';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';
import { Update_Package } from '../../../actions/packages';
import Loading from '../../loadingEffect';

function EditPackage({ pack, currency }) {
  const [name, setName] = useState(null);
  const [level, setLevel] = useState(null);
  const [material, setMaterial] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [price, setPrice] = useState(0);
  const [selectedValues, setSelectedValues] = useState({
    levelId: '',
    materialCode: '',
    selectedLessons: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null)
  const dispatch = useDispatch();

  const { levels } = useSelector(({ levels }) => ({ levels }));

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const inetializeValues = async () => {
    try {
      setName(pack.name);
      setPrice(pack.price)
      const level = levels.find(l => l._id === pack.levelId);
      setLevel(level)
      const res = await GetMaterialsByIds({ IDs: level.materialIDS });
      setMaterials(res.data.materials);
      // console.log('res',res)
      setSelectedValues({
        ...selectedValues,
        levelId: pack.levelId,
      });
      onChangeMaterial(pack.materialCode, res.data.materials);
      console.log('start', pack)
      await pack.selectedLessons.forEach((p) => {
        onChangeLessons(p);
      })
    }
    catch (err) {
      setMessage(err.message)
      console.log(err.message)
    }


  }
  useEffect(() => {
    if (pack) {
      inetializeValues();
    }
  }, [])

  const resetAllValues = () => {
    setName(null);
    setMaterials([]);
    setLessons([]);
    setAllLessons([]);
    setPrice(0);
    setSelectedValues({
      levelId: '',
      materialCode: '',
      selectedLessons: [],
    })
  }
  const onChangeLevel = async (levelId) => {
    const selectedLevel = levels.find((level) => level._id === levelId);
    const res = await GetMaterialsByIds({ IDs: selectedLevel.materialIDS });
    const { materials } = res.data;
    setMaterials(materials);
    setSelectedValues({
      levelId,
      materialCode: '',
      selectedLessons: [],
    });
  };

  async function onChangeMaterial(materialCode, Materials) {
    const res = await getLessonsOfSpecificMaterial({ code: materialCode });
    const material = Materials.find(m => m.code === materialCode);
    setMaterial(material)
    if (res?.data?.lesson) {
      const { lessons } = res?.data?.lesson;
      setSelectedValues({
        ...selectedValues,
        materialCode: materialCode,
        selectedLessons: [],
      });
      setLessons(lessons);
      setAllLessons(lessons);
    }

  };

  function onChangeLessons(id) {

    const updatedLessons = lessons.filter((lesson) => lesson._id !== id);

    setSelectedValues({
      ...selectedValues,
      selectedLessons: [...selectedValues.selectedLessons, id],
    });
    setLessons(updatedLessons);
  };

  const onRemoveLesson = (id) => {
    const removedLesson = selectedValues.selectedLessons.find((lessonId) => lessonId === id);
    const updatedSelectedLessons = selectedValues.selectedLessons.filter((lessonId) => lessonId !== id);

    setLessons([...lessons, allLessons.find((lesson) => lesson._id === removedLesson)]);
    setSelectedValues({
      ...selectedValues,
      selectedLessons: updatedSelectedLessons,
    });
  };

  const onChangePrice = (price) => {
    setPrice(price)
  }

  // SUBMITE
  const handleSubmit = async (e) => {
    try {

      e.preventDefault();
      setLoading(true)
      const data = { ...selectedValues, price, name, _id: pack._id }
      const res = await dispatch(Update_Package(data));
      resetAllValues();
      setMessage(res.data.message);
      // Dispatch actions or perform further actions here
      setLoading(false)
    }
    catch (err) {
      console.error(err);
      setMessage(err.message);
      setLoading(false)
    }
  };
  console.log(material && material.name)

  return (
    <Container className='loading-parent'>
      <h3>تعديل الباقة {pack.name}</h3>

      {loading ? <Loading /> : <></>}
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>{name} اسم الباقة </Form.Label>
              <Form.Control
                type="text"
                placeholder='ادخل اسم الباقة'
                style={{ width: '150px' }}
                defaultvalue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="levelSelect">
              <Form.Label>{level?.name} المستوى</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLevel(e.target.value)}
                defaultValue={selectedValues.levelId}
              >
                <option value="">اختر المستوى</option>
                {levels.map((level) => (
                  <option key={level._id} value={level._id}>
                    {level.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="materialSelect">
              <Form.Label>السعر {price} {currency ? currency : 'ج.م'}</Form.Label>
              <Form.Control
                type="number"
                placeholder='سعر الباقة'
                style={{ width: '150px' }}
                // defaultValue={price&&price}
                onChange={(e) => onChangePrice(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>المواد {material?.name}</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeMaterial(e.target.value)}
              >
                <option value={null}> اختر المادة</option>
                {materials.length && materials.map((material) => (
                  <option key={material.code} value={material.code}>
                    {material.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="lessonsSelect">
              <Form.Label>الدروس</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLessons(e.target.value)}
              >
                <option value={null}>Select lessons</option>
                {lessons.map((lesson) => (
                  <option key={lesson._id} value={lesson._id}>
                    {lesson.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginTop: '20px' }}>
            <div>
              <strong>Chosen Lessons:</strong>
              {selectedValues.selectedLessons.map((selectedLesson) => (
                <div key={selectedLesson}>
                  <span>
                    {allLessons.find((lesson) => lesson._id === selectedLesson)?.name}
                  </span>
                  <CiSquareRemove
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    size={25}
                    onClick={() => onRemoveLesson(selectedLesson)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Button type="submit" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default EditPackage;
