import { getClasses, addStudentsToClass, changeClassActiveState, newClass, createMeetingRoom, changePack, addRecordManually } from "../utils/class";

export const NEW_CLASS = 'NEW_CLASS';

const newclass = (Class) => {
    return {
        type: NEW_CLASS,
        Class
    }
}
export const New_Class = (data) => {
    return dispatch => newClass(data).then(res => {
        if (res.data.case === true) {
            dispatch(newclass(res.data.class))
        }
        return res
    })
}

// get all classes 
export const GET_CLASSES = 'GET_CLASSES';
export const UPDATE_CLASSES = 'UPDATE_CLASSES';
const getclasses = (classes) => {
    return {
        type: GET_CLASSES,
        classes
    }
}
const updateclasses = (classes) => {
    return {
        type: UPDATE_CLASSES,
        classes
    }
}
export const Get_Classes = () => {
    return dispatch => getClasses().then(res => {
        if (res.data.case) {
            dispatch(getclasses(res.data.classes))
        }
        return res
    })
}

export const Update_Classes = (data) => {
    return dispatch => changePack(data).then(res => {
        if (res.data.case) {
            dispatch(updateclasses(res.data.class))
        }
        return res
    })
}
// add students to class
export const ADD_STUDENTS = 'ADD_STUDENTS';
const addStudents = (Class) => {
    return {
        type: ADD_STUDENTS,
        Class
    }
}
export const Add_Students_To_Class = (data) => { // data{students,ids}
    return dispatch => addStudentsToClass(data).then(res => {
        if (res.data.case) {
            dispatch(addStudents(res.data.class))
        }
        return res
    })
}

// change class active state 
export const CHANGE_ACTIVE_STATE = 'CHANGE_ACTIVE_STATE';
const changeActiveState = (Class) => {
    return {
        type: CHANGE_ACTIVE_STATE,
        Class
    }
}
export const Change_Class_Active_State = (data) => {
    return dispatch => changeClassActiveState(data).then(res => {
        if (res.data.case) {
            dispatch(changeActiveState(res.data.class))
        }
        return res
    })
}

export const CREATE_ROOM_ID = 'CREATE_ROOM_ID';
const addRoomId = (Class) => {
    return {
        type: CREATE_ROOM_ID,
        Class
    }
}
export const Add_Room = (classId) => {
    return dispatch => createMeetingRoom(classId).then(res => {
        if (res.data.case) {
            dispatch(addRoomId(res.data.class))
        }
        return res
    })
}

// Add Record Manually

// export const ADD_RECORD_MANUALLY = 'ADD_RECORD_MANUALLY';


// const addRec = (Class) => {
//     return {
//         type: ADD_RECORD_MANUALLY,
//         Class
//     }
// }
// export const Add_Record_Manually = (classId,userId) => {
//     return dispatch => addRecordManually(classId,userId).then(res => {
//         console.log(res)
//         if (res.data.case) {
//             dispatch(addRec(res.data.class))
//         }
//         return res
//     })
// }