import React from 'react'
import { Button } from 'react-bootstrap'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
import { editUserFromAdmin, editUserFromProfile } from '../../../utils/API';
import { ErrorMsg, success } from '../../../utils/Toast';
import { MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';

const ModalUpdateUser = ({ student, type }) => {
    const [show, setShow] = useState(false);

    // console.log(student)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, setName] = useState(student?.name)
    const [schoolName, setSchoolName] = useState(student?.schoolName)
    const [country, setCountry] = useState(student?.country)
    const [email, setEmail] = useState(student?.email)
    const [phone, setPhone] = useState(student?.phone)
    const [parentName,setParentName] = useState(student?.parentName)
    const [parentPhone, setParentPhone] = useState(student?.parentPhone)
    const [userName, setUserName] = useState(student?.username)
    const [loading, setLoading] = useState(false)
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    const handleEditAdmin = async () => {
        try {
            let res
            if (type === "admin") {
                 res = await editUserFromAdmin({
                    name: name,
                    parentName,
                    schoolName: schoolName,
                    country: country,
                    email: email,
                    phone: phone,
                    parentPhone: parentPhone,
                    username: userName,
                }, student._id)
                

            } else if (type === "student") {
                 res = await editUserFromProfile({
                    name: name,
                    parentName,
                    schoolName: schoolName,
                    country: country,
                    email: email,
                    phone: phone,
                    parentPhone: parentPhone,
                    username: userName,
                }, student._id)
               
                // console.log(res)

            }
            if(res?.data?.case){
                success(res?.data?.message)
                handleClose()
            }
            else if(!res?.data?.case){
                ErrorMsg(res?.data?.message)
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
            
        }
    }

    return (
        <div>
                <MdEdit className='edit-icon' onClick={handleShow} size={"35px"}/>
        
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <div>
                        <div>
                            <label>{HomePage.nameForm[lang]}</label>
                            <input type="text" name="name" id="name" className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div>
                            <label>{HomePage?.parentName[lang]}</label>
                            <input type="text" name="parentName" id="name" className='form-control' value={parentName} onChange={(e) => setParentName(e.target.value)} />
                        </div>
                        <div>
                            <label>{HomePage.nameSchool[lang]}</label>
                            <input type="text" name="schoolName" id="schoolName" className='form-control' value={schoolName}
                                onChange={(e) => setSchoolName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.country[lang]}</label>
                            <input type="text" name="country" id="country" className='form-control' value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                        </div>
                       
                        <div>
                            <label>{HomePage.email[lang]}</label>
                            <input type="email" name="email" id="email" className='form-control' value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.phone[lang]}</label>
                          
                            <PhoneInput
                    className={"register-field"}
                    country={'eg'}
                    value={phone}
                    onChange={phone => setPhone('+'+phone)}
                  />
                        </div>
                        <div>
                            <label>{HomePage.phoneParent[lang]}</label>
                            
                            <PhoneInput
                    className={"register-field"}
                    country={'eg'}
                    value={parentPhone}
                    onChange={phone => setParentPhone("+"+phone)}
                  />
                        </div>
                        <div>       
                            <label>{HomePage.userName[lang]}</label>
                            <input type="text" name="username" id="username" className='form-control' value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={!loading?handleEditAdmin:()=>{}}>
                        {!loading?HomePage.saveChanges[lang]:"Loading..."}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalUpdateUser
