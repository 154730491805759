import React,{useEffect} from "react";
import { useRef, useState } from "react";
import { Delete_Current, New_Product } from "../../actions/product";
import "./newProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubCategory from "./subCategory.";
import DropDownSelect from "../styled-components/DropDownSelect";
// import { useEffect } from "react";
function NewProduct() {
  
  const dispatch = useDispatch();
  const { currentP } = useSelector(mapStateToProps);
  const [type, setType] = useState("multi");
  const [subCategory, setSubCategory] = useState([]); // subcategories
  const name = useRef();
  const price = useRef();
  const discription = useRef();
  const [sessionDays, setSessionDays] = useState(null);
  const count = useRef();
  const navigate = useNavigate();
  const [max, setMax] = useState(1);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const onSelectDays = (o, i) => {
    // for Drop Down select with single product
    return setSessionDays({ [o.name]: o.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // if(sessionDays===null)return alert("please select course days");
    let data = null;
    if (type === "single") {
      data = {
        name: name.current.value,
        price: price.current.value,
        discription: discription.current.value,
        count: count.current.value,
        type: type,
        ...sessionDays,
      };
    } else if (type === "multi") {
      data = {
        name: name.current.value,
        discription: discription.current.value,
        subCategory: subCategory,
        type: type,
      };
    }
    dispatch(New_Product(data));
    
    setTimeout(() => {
      if (currentP & (type === "single")) {
        navigate("/admin-dashboard/cover");
      } else if (currentP & (type === "multi")) {
        navigate("/admin-dashboard/sub-category");
      }
    }, 1000);
  };

  const onChange = (e) => {
    try {
      let subC = [];
      let i = 1;
      while (i <= e) {
        subC.push({ index: i, name: "", price: "" });
        i++;
      }
      setSubCategory(subC);
    } catch (err) {
      console.log(err);
    }
  };
  //  const [startTransition, isPending] = useTransition({ timeoutMs: 3000 });

  const editSubCategory = (e, o) => {
    try {
      const newSub = subCategory.map((s) => {
        if (s.index == o) {
          return (s = { ...s, [e.name]: e.value });
        } else return s;
      });
      setSubCategory(newSub);
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(sessionDays)
useEffect(()=>{
setSubCategory([])
},[type])
  return (
    <form
      className="create-form "
      onSubmit={onSubmit}
      // encType="multipart/form-data"
    >
      
        {/* <label  htmlFor="account-type-list">Product Type</label>
        <select
          id="account-type-list"
          onChange={(event) => {
            setType(event.target.value);
          }}
          name="Account type"
        >
          <option> Product Type</option>
          <option value="single">Single </option>
          <option value="multi">Multible</option>
        </select> */}
        <label  htmlFor="co">Course name:</label>
        <input
          className="tex"
          id='co'
          ref={name}
          type="text"
          name="name"
          placeholder="course"
          required
        />
        {type === "single" && (
          <>
        <label  htmlFor="pr">Course Price:</label>
            <input
              className="tex"
              ref={price}
              type="number"
              id='pr'
              name="price"
              placeholder="price"
              required
            />
        <label  htmlFor="ns">Sessions per week:</label>

            <input
              className="tex "
              id='ns'
              ref={count}
              type="number"
              name="max"
              placeholder="number of sessions"
              onChange={(event) => setMax(event.target.value)}
              required
            />
        <label >Select Days:</label>

            <DropDownSelect
              name="Days"
              options={days}
              maxSelection={max}
              onSelect={onSelectDays}
            />
          </>
        )}
        <label  htmlFor="dis">Discription:</label>
       
        <input
          className="tex "
          id='dis'
          ref={discription}
          type="text"
          name="discription"
          placeholder="discription"
          required
        />

        {type === "single" ? (
          <input
            className="tex "
            ref={count}
            type="Number"
            name="count"
            placeholder="In Stock"
            required
          />
        ) : (
          <>
          <label  htmlFor="nos">Number of subCategories:</label>
          <input
            type="number"
            id='nos'
            className="tex "
            min="0"
            placeholder="number of subCategories"
            onChange={(event) => onChange(event.target.value)}
          /></>
        )}

        {subCategory &&
          subCategory.map((s, i) => (
            <SubCategory
              key={i}
              p={s.index}
              onChange={editSubCategory}
              options={days}
            />
          ))}
        <input type="submit" name="save" />
      
    </form>
  );
}

function mapStateToProps({ general }) {
  return { currentP: general.currentP };
}
export default NewProduct;
