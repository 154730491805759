import React, { useEffect, useState } from 'react';
import { getStudentsInProgramNotInClass } from '../../../utils/packagesRequests';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { getStudentsByIDS } from '../../../utils/API';
import { Add_Students_To_Class } from '../../../actions/class';
import { HomePage } from '../../../translations/trans';
import { IoPersonAddSharp } from "react-icons/io5";
function AddStudents({ Class }) {
    const [message, setMessage] = useState(null)
    const [students, setStudents] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState([]); // ids
    const [selectedStudentsObj, setSelectedStudentsObj] = useState([])

    const allStudents = useSelector(state => state.users.students)

    // Function to fetch students from API
    const getStudents = async () => {
        try {
            const res = await getStudentsInProgramNotInClass({ programId: Class.packID });
            console.log(res)
            const studentsIDS = res.data.studentsDoesNotInClass;
            const response = await getStudentsByIDS({ IDS: studentsIDS });
            console.log(response)
            setStudents(response.data.students);
        } catch (err) {
            console.log(err.message); // Logging error message for debugging
        }
    }

    // Fetch students when component mounts
    useEffect(() => {
        getStudents();
    }, [Class]); // Dependency array to ensure useEffect runs when packID changes

    // Selector to get levels from Redux store
    const { levels } = useSelector(({ levels }) => ({
        levels
    }));

    // Function to handle opening modal
    const openModal = () => {
        setShowModal(true);
    }

    // Function to handle closing modal
    const closeModal = () => {
        setShowModal(false);
    }

    // Function to handle select change
    const handleSelectChange = (event) => {
        const id = event.target.value
        setSelectedStudent(prev => [...prev, id]);
        const stObj = students.find(s => s._id === id)
        setSelectedStudentsObj(prev => [...prev, stObj])
    }

    // Function to handle form submission
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        // event.preventDefault();
        console.log("aaaaaa")
        console.log("Selected Student:", selectedStudent);
        const res = await dispatch(Add_Students_To_Class({ students: selectedStudent, id: Class._id }))
        // Perform any action with the selected student, like adding it to the class
        // Reset the selected student state

        setSelectedStudent([]);
        setMessage(res.data)
        // Close the modal
        // closeModal();
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            {/* Button to open modal */}
            {/* <Button onClick={openModal} className='btn' style={{ background: "#4169E2" }}></Button> */}

            <IoPersonAddSharp onClick={openModal} color={"#4169E2"} size={20}/>

            {/* Modal */}
            <Modal show={showModal} onHide={closeModal} dir={dir}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.addStudent[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Selected Students List */}
                    <h5>Selected Students:</h5>
                    <ul>
                        {selectedStudentsObj.map(student => (
                            <li key={student._id}>{student.username}</li>
                        ))}
                    </ul>
                    {/* Form inside the modal */}
                    <Form >
                        <Form.Group controlId="selectStudent">
                            <Form.Label>{HomePage.chooseStudents[lang]}:</Form.Label>
                            {message && <Alert variant={message.case ? 'success' : 'danger'}>
                                {message.message}
                            </Alert>}
                            <Form.Control as="select" value={selectedStudent} onChange={handleSelectChange}>
                                <option value="">{HomePage.chooseStuden[lang]}</option>
                                {students && students.map(student => (
                                    <option key={student._id} value={student._id}>{student.username}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" className='mt-3' onClick={handleSubmit}>
                            {HomePage.add[lang]}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddStudents;
