
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteSession } from '../../utils/sessions';
import { success ,ErrorMsg} from '../../utils/Toast';
import { HomePage } from '../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import { handleDelSession } from '../../actions/sessions';

const ModalDeleteSession = ({ show, handleClose, sessionId, classId, onDelete }) => {

    const dispatch = useDispatch();
    const loggedUser = useSelector((state)=>state.loggedUser)
    const handleDelete = async () => {
        try {
            const res = await dispatch(handleDelSession({
                classId: classId,
                sessionId: sessionId,
            },loggedUser?.user?._id));

            success("تم حذف الجلسة بنجاح");
            // onDelete(sessionId); // Trigger the filter operation
            handleClose(); // Close the modal
        } catch (e) {
            console.error(e);
            if(e?.response){
                ErrorMsg(e?.response?.data?.message)
            }
            // Handle the error if needed
        }
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <h5>{HomePage.deleteSession[lang]}</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {HomePage.cancel[lang]}
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    {HomePage.delete[lang]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeleteSession;
