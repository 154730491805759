// import { GET_ANSWERS_BY_MOCK, UPDATE_ANSWER } from "../actions/mockActions";

import { GET_ANSWERS_BY_MOCK, UPDATE_ANSWER } from "../actions/answerAction";

const initialState = {};

export const mockAnswersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANSWERS_BY_MOCK:
            console.log("Action Redux",action)
            return {
                ...state,
                [action.mockId]: action.answers, // Store answers by mock ID
            };
        case UPDATE_ANSWER:
            return {
                ...state,
                [action.mockId]: state[action.mockId]?.map((answer) =>
                    answer._id === action.updatedAnswer._id ? action.updatedAnswer : answer
                ),
            };
        default:
            return state;
    }
};
