import React from "react";
import Courses from "../Student/courses";
function Appointments() {
  return (
    <>
      <Courses />
    </>
  );
}

export default Appointments;
