import React, { useState, useEffect, useRef } from 'react';

function VideoPlayer({ stream }) {
  const [videoStream, setVideoStream] = useState(null);
console.log('stream',stream)
  const videoRef = useRef(null)  
useEffect(() => {
    if(stream){

        // setVideoStream(stream);
        videoRef.current.srcObject = stream.stream
        // videoRef.current.play()
    }
  }, [stream]);
if(stream){
    return <video controls={true} ref={videoRef} autoPlay className='user-video' />;

}
else {return <h2 style={{color:'black'}}>User Out</h2>}
}

export default VideoPlayer;