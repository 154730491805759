import { SET_COURSE, SET_LESSON,SET_TEST } from "../actions/curriculums/view"
export const view = (state={},action)=>{
  switch(action.type){
    case SET_LESSON:
        return state = {...state,lesson:action.lesson}
    case SET_TEST:
      return state= {...state,testFor:action.testFor}
    case SET_COURSE:
      return state ={...state,course:action.course}
    default: return state
  }
}