import React, { useEffect, useState, useRef } from 'react';
import Peer from 'peerjs';
import { io } from 'socket.io-client';
import VideoPlayer from './videoPlayer';
import "./video.css"
import { useParams } from 'react-router-dom';
import { urlBase } from '../../utils/API';
import { IoVideocam,IoVideocamOff ,IoMicCircleSharp ,IoMicOffCircleSharp  } from "react-icons/io5";
import { MdScreenShare,MdStopScreenShare  } from "react-icons/md";
import { TbArrowsJoin } from "react-icons/tb";


const getUserMedia =
navigator.getUserMedia ||
navigator.webkitGetUserMedia ||
navigator.mozGetUserMedia;

const Meeting=()=> {
  const params = useParams();
  const id = params.id;
  const roomId = params.roomId;
  const userPeer = useRef(null);
  const socketRef = useRef(null);
  const myStream = useRef(null);
  const [peers, setPeers] = useState([]);
  const [usersStreams, setUsersStreams] = useState([]);
  const [userswaiting, setUsersWaiting] = useState([]);
  const [rejoin,setRejoin] = useState(true);
  const [share,setShare] = useState(false);
  const shareStream = useRef(null);
  const [camera ,setCamera] = useState(true);
  const [audio,setAudio] = useState(true);
  useEffect(()=>{
   // Check for getUserMedia support
   
   if (!getUserMedia) {
    console.error('getUserMedia is not supported in this browser');
    return;
  }

  // Initialize media devices
  getUserMedia({ video: true, audio: true }, (mediaStream) => {
    myStream.current.srcObject = mediaStream;
  }, (err) => {
    console.error('Failed to access media devices:', err);
  });

  },[])
    // CALL USER
const callUser=async(peerId)=> {
  try {
    console.log('Calling', peerId)
    const call =await userPeer.current.call(peerId, myStream.current.srcObject,{metadata:{
      userId:id,
      socketId:socketRef.current.id,
      peerId
    }});

    // call.answer(myStream.current.srcObject);
    setTimeout(()=>{
      call.on('stream', (stream) => { 
       
       // const [check] = usersStreams.filter(u=>u.metadata.socketId ===call.metadata.socketId);
       console.log('Respons Stream' , stream)
        setUsersStreams((prevStreams) => [...prevStreams, {stream,metadata:call.metadata}]);
 
       stream.oninactive = () => {
         // Handle stream inactive event
         console.log('Stream inactive:', stream);
       };
     });

     setPeers((prevPeers) => [...prevPeers, call]);
    },[100])

  } catch (error) {
    console.warn('Error calling user:', error);
  }
}
// Answer user 
const answerCall =async()=>{
try{
 await userPeer.current.on("call",async function (call) {
    setPeers((prevPeers) => {    
  return [...prevPeers, call]});
    console.log('In Comming Call:', call?.peer)
    await call.answer(myStream.current.srcObject,{metadata:{
      userId:id,
      socketId:socketRef.current.id,
      peerId:userPeer.current.peer
    }});
    await call.on("stream", function (stream) {
      console.log('Sending Stream', stream)
    //   const [check] = usersStreams.filter(u=>u.metadata.socketId ===call.metadata.socketId);
    //  console.log('Check',check)


setUsersStreams((prevStreams) => [...prevStreams, {stream,metadata:call.metadata}]);


      
      // otherStream.current.play();
    });
  
  });
}
catch(err){
  console.warn(err)
}
}
console.log('IO',socketRef)
  useEffect(() => {

   if(myStream.current){
    
   
    // Connect to Socket.io server
    socketRef.current = io.connect(urlBase);

    // Initialize Peer instance
    userPeer.current = new Peer();
    userPeer.current.userId=id
    userPeer.current.on('open', (peerId) => {
      socketRef.current.emit('join-room', { roomId, userId: id, peerId });
    });

    socketRef.current.on('users in room', ({ usersInRoom }) => {
      // Call each user in the room
      console.log('Joining, User in Room:', usersInRoom)
      for (let user of usersInRoom){
        console.log('userToCall',user?.socketId)

        if(user&&user?.peerId){

          callUser(user.peerId);
        }
      }
    });

    socketRef.current.on('user left',({userId,socketId})=>{
      // alert(`User Left Room: ${socketId}`)
     setUsersStreams(prev=>prev.filter(s=>s.metadata.socketId !== socketId));
   
    })
  }
  answerCall()

    return () => {
      // Clean up: disconnect from Socket.io and close Peer connection
      socketRef.current.disconnect();
      userPeer.current.destroy();
    };

  }, [rejoin]);

// share screen 
const shareScreen =async () => {
  try{
  // setShare(true);
  if (share) {
    stopScreenSharing();
  }
  const stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
  let videoTrack = stream.getVideoTracks()[0];
  videoTrack.onended = () => {
    stopScreenSharing();
  };
  for(let peer of peers){

    let sender = peer.peerConnection?.getSenders().find(function (s) {

      return s.track.kind == videoTrack.kind;
    });
    sender?.replaceTrack(videoTrack);
  }
  setShare(true);
}catch(err){
  console.warn(err)
}
}
    // console.log('peeeeeeeeeeeeeeeers', myStream.current.srcObject.getVideoTracks())
  //------------stop screen sharing
  function stopScreenSharing() {
    try {
      let videoTrack = myStream.current.srcObject.getVideoTracks()[0];
      for(let peer of peers){
        let sender = peer.peerConnection?.getSenders().find(function (s) {
          return s.track.kind == videoTrack.kind;
        });
    sender?.replaceTrack(videoTrack);
      }
  setShare(false);
      

    } catch (err) {
      console.log(err);
    }
  }


const shareScreenForNewJoinedUser = ()=>{
  try{
   
  }
  catch(err){
    console.warn(err)
  }
}
// Toggel Cam
const ToggleLocalCamera = async () => {
  const tracks = myStream.current.srcObject.getTracks();
  tracks.forEach((track) => {
    if (track.kind === "video") {
      track.enabled = !camera;
      setCamera(!camera);
    }
  });
  console.log("toggel");
  setCamera(!camera);
};

// Toggel Audio
const ToggleLocalAudio = async () => {
  const tracks = myStream.current.srcObject.getTracks();
  tracks.forEach((track) => {
    if (track.kind === "audio") {
      track.enabled = !audio;
      setAudio(!audio);
    }
  });
  console.log("toggel");
  setAudio(!audio);
};
const RejoinFunc = ()=>{
  socketRef.current.disconnect();
  userPeer.current.destroy();
  setUsersStreams([])
  setRejoin(!rejoin)
  
}

// Icons Style
const openedIconsStyle= {
  size: '30',
  color:'red',
  cursor:'pointer'

}
const closedIconsStyle ={
  size:'30',
  color:'#333',
  cursor:'pointer'

}


  return (
    <div  className='meeting-cont'>
      {/* Render local video stream */}
      
      <div className='videos'>
           <video muted={true} className="my-video" ref={myStream} autoPlay width="300px" />

         <div className='users-videos'>
       {usersStreams.map((s,i)=>{
         if(i===0 || i%2 === 0)return <VideoPlayer key={i} stream ={s}/>
       }
      )}
      </div>
      </div>
      <div className="meeting-options">
      <button 
      className='join-button'
       style={{
        border:'0px',
        background:'#8C1C13',
        color:'#fff'
       }}
      onClick={()=>RejoinFunc()}>
        <TbArrowsJoin size={'40px'}
        
        />
        Join Meeting
      </button>
      <div className="video-stop">
          <span onClick={() => ToggleLocalCamera()}>
            {!camera ? (
              <IoVideocamOff 
            size={'50px'}

              style={closedIconsStyle}
              />
            ) : (
              <IoVideocam 
            size={'50px'}
              
              style={openedIconsStyle}/>
            )}
          </span>
        </div>
        <div className="video-stop">
          <span onClick={() => ToggleLocalAudio()}>
            {!audio ? (
              <IoMicOffCircleSharp 
            size={'50px'}

              style={closedIconsStyle}
              />
            ) : (
              <IoMicCircleSharp
            size={'50px'}

              style={openedIconsStyle}
              />
            )}
          </span>
        </div>
        <div className="share-screen">
          {!share?<MdScreenShare
            onClick={shareScreen}
            
            size={'50px'}
            style={{
              color:'green',
            cursor:'pointer'

            }}
          />:<MdStopScreenShare
          onClick={stopScreenSharing}
          size={'50px'}

          style={{
            color:'green',
            cursor:'pointer'
          }}
          />}
       
        </div>
      </div>
    </div>
  );
}

export default Meeting;