import React, { useState, useRef, useEffect } from "react";
import "./login.css";
import {  useSelector } from "react-redux";
import LoginForm from "./loginForm";
import {  useNavigate } from "react-router-dom";
import {OLP_Logo as Logo  } from '../../constants'

const RegisterTeacher = () => {
  const [mode, setMode] = useState("signup");
  const navigate = useNavigate();

  const user = useSelector(mapStateToProps)
 
  // const setValue = (key, value) => {
  //   setData({ ...data, [key]: value });
  // };
  const toggleMode = () => {
    var newMode = mode === "login" ? "signup" : "login";
    setMode(newMode);
  };
  //const dispatch = useDispatch();

  useEffect(()=>{
      if(user.auth){
        navigate(-1)
      }
  },[user])
  return (
    <>
      <div>
         <div
          className={`form-block-wrapper form-block-wrapper--is-${mode}`}
          >

        </div>
        <section className={`form-block form-block--is-${mode}`}>
        <a href='/'> <img src={Logo} alt="AGA ENGLISH" width='100px'/></a>
          <header className="form-block__header">
            <h1>{mode === "login" ? "Welcome back!" : "Sign up"}</h1>
            <div className="form-block__toggle-block">
              <span>
                {mode === "login" ? "Don't" : "Already"} have an account? Click
                here &#8594;
              </span>
              <input
                name="form-toggler "
                id="form-toggler"
                type="checkbox"
                onClick={() => toggleMode()}
                value={mode}  
                className='toggler'
              />
              <label className='toggler-label'  htmlFor="form-toggler"></label>
              
             
            </div>
          </header>
          <LoginForm mode={mode} setMode={setMode} type='teacher' />
          
        </section>
      </div>
    </>
  );
};
function mapStateToProps({loggedUser}){
  return loggedUser
}

 export default RegisterTeacher;
