export const HomePage = {
    // NavBar
    home: {
        AR: 'الرئيسية',
        EN: 'Home',
    },
    whatsApp: {
        AR: " واتس اب",
        EN: "Send WhatsApp"
    },
    selectedLessons: {
        AR: "اختر الدرس",
        EN: "select Lesson"
    }
    ,

    alreadyUpload: {
        AR: "الواجب مرفوع",
        EN: "Uploaded"
    }
    ,
    record: {
        AR: "تسجيلات",
        EN: "Records"
    },
    noLessonsSelected: {
        AR: "لا يوجد دروس",
        EN: "No select Lesson"
    }
    ,
    studyProgram: {
        AR: "البرامج الدراسية ",
        EN: "Courses"
    },
    notSubscripeProgram: {
        AR: "  انت غير مشترك فى اى برامج دراسية بعد.",
        EN: "You are not participating in any study programs yet."
    },
    aboutUs: {
        AR: "من نحن",
        EN: "About us"
    },
    resetPass: {
        AR: "اعادة تعيين كلمة السر",
        EN: "Reset Password"
    },
    from: {
        AR: "من",
        EN: "from"
    },
    to: {
        AR: "الي",
        EN: "To"
    },
    // Hero Section Header
    heroText: {
        AR: "نوفر بيئة تعليمية متكاملة تدعم الطالب في كل خطوة، بدءًا من تخطيط الدروس وحتى تقييم أداء الطلاب، لضمان تقديم تعليم ذو جودة عالية ونتائج مميزة.",
        EN: "We provide an integrated learning environment that supports the student at every step, from lesson planning to evaluating student performance, to ensure high quality education and outstanding results."
    },
    heroSubText: {
        AR: " تتيح المنصة  الأدوات والموارد اللازمة لجعل تعليم الفيزياء أكثر تفاعلًا وفهمًا للطلاب. من خلال دمج التكنولوجيا الحديثة وأساليب التدريس الفعّالة، ",
        EN: " The platform provides the tools and resources needed to make physics education more interactive and understanding for students. By integrating modern technology and effective teaching methods, "
    },

    startNow: {
        AR: "أبدء الان",
        EN: "Start Now"
    },
    seeMore: {
        AR: "شاهد المزيد",
        EN: "See More",
    },
    registerNow: {
        AR: "سجل الآن",
        EN: "Register"
    },
    logOut: {
        AR: "تسجيل الخروج",
        EN: "Log Out"
    },

    // learn abou platform
    learnPlatform: {
        AR: "لماذا أتعلم عبر المنصة ؟",
        EN: "Why am I learning through the platform?"
    },
    learnPlatCon: {
        AR: "لأنه نموذجٌ للتعليم الآمن والفعال والمتاح بأسعار معقولة، لجميع متعلمي اللغة، مثلك تمامًا.",
        EN: "Because it is a model of safe, effective and affordable education,"
    },
    platOne: {
        AR: "تعليم موثوق ",
        EN: "All-in-one platform"
    },
    platOneContent: {
        AR: "نلتزم بتقديم محتوى تعليمي ذو جودة عالية، معتمد على أسس علمية متينة وأساليب تدريس مبتكرة ",
        EN: "We provide a variety of educational tools, interactive lessons, and advanced tests covering all levels"
    },
    platTwo: {
        AR: "أسعار معقولة",
        EN: "Flexible schedule"
    },
    platContent: {
        AR: "نقدم خطط اشتراك مرنة تناسب مختلف الميزانيات عن بُعد يناسب أي ميزانية.ابدأ رحلتك نحو النجاح الأكاديمي",
        EN: "We coordinate learning schedules according to your schedule. Book lessons when you want to learn."
    },
    platThree: {
        AR: "جدول مرن",
        EN: "Affordable prices"
    },
    platThreeContent: {
        AR: "نُنسق مواعيد التعلّم حسب جدولك. احجز الدروس في الوقت الذي تريد التعلّم به.",
        EN: "We offer flexible subscription plans that suit different budgets remotely to suit any budget."
    },
    platFour: {
        AR: "منصة شاملة",
        EN: "Trusted teachers"
    },
    platFourContent: {
        AR: "نوفر مجموعة متنوعة من الأدوات التعليمية، الدروس التفاعلية، والاختبارات المتقدمة التي تغطي جميع المستويات",
        EN: "We are committed to providing high quality educational content, based on solid scientific foundations and innovative teaching methods"
    },

    statics: {
        AR: "إحصائيات",
        EN: "Statistics"
    },
    quiz: {
        AR: "أختبارات وكويزات",
        EN: "Quiz and Tests"
    },
    quizDes: {
        AR: "معتمدة",
        EN: "Certified"
    },
    highCollect: {
        AR: "مجاميع مرتفعه",
        EN: "High Scores"
    },
    highCollectDes: {
        AR: "محققة",
        EN: "Activated"
    },
    sessionMark: {
        AR: "حصص تجريبية",
        EN: "Trial Classes"
    },
    sessionMarkDes: {
        AR: "منجزة",
        EN: "Done"
    },
    // Subscripe With Us
    subData: {
        AR: "اشترك معنا وتميز في تحصيلك الدراسي",
        EN: "Subscribe with us and excel in your academic achievement"
    },
    subContent: {
        AR: "، ستجد الدعم الكامل الذي تحتاجه لتفوقك في مادة الفيزياء. استعد لرحلة تعليمية تفاعلية وممتعة تفتح لك أبواب النجاح وتجعلك في طليعة المتفوقين. لا تتردد في الانضمام وابدأ رحلتك نحو التميز اليوم!",
        EN: "you will find the full support you need to excel in physics. Get ready for an interactive and fun learning journey that opens doors to success and puts you at the forefront of excellence. Feel free to join and start your journey towards excellence today!"
    },
    regis: {
        AR: "اشترك الان",
        EN: "Login"
    },
    details: {
        AR: "التفاصيل",
        EN: "Details"
    },
    follow: {
        AR: "متابعه",
        EN: "Continue"
    }
    ,
    subscribeNow: {
        AR: "اشترك الان",
        EN: "subscribe Now"
    },

    contentLesson: {
        AR: "لا يوجد محتوى متاح لهذا الدرس",
        EN: "No Content For This Lesson"
    },

    // Book Session
    bookSession: {
        AR: "اختر جدولك واحجز حصصك التجريبية",
        EN: "Choose your schedule and book your trial sessions"
    },
    bookSessionCon: {
        AR: "كيف تعمل المنصة",
        EN: "How the platform works"
    },
    sessionOne: {
        AR: "اختر صفك المناسب",
        EN: "Choose your appropriate class",
    },
    sessionTwo: {
        AR: "حدد الوقت المناسب",
        EN: "Select the right time",
    },
    sessionThree: {
        AR: "أستمتع بتجربتك التعليمية",
        EN: "Enjoy your learning experience",
    },
    freeTrial: {
        AR: "تجربه مجانيه",
        EN: "Free Trial"
    },

    // Footer
    footerContent: {
        AR: `
            نهدف إلى رفع كفائة المُعلمين وتعزيز قدراتهم من عدة جوانب "مهارية ،علمية ،تربوية" كما نهدف ايضًا إلى تأهيلهم لسوق العمل وتزويد خبراتهم المهنية ومساعدتهم على مواكبة التطورات الرقمية المستمرة التي تطرأ على مجال التعليم


        `,
        EN: "Subscribe with Sara Physics Platform and achieve excellence in Physics, and you can through our platform, study online, get your books, home work, and live sessions, subscribe to the course and all the information",
    },

    Abouttheplatform: {
        AR: "عن المنصه",
        EN: "About the platform"
    },
    quickLinks: {
        AR: "روابط سريعه",
        EN: "Quick Links"
    },
    privacy: {
        AR: "سياسة الخصوصيه والاستخدام",
        EN: "Privacy & Usage Policy"
    },
    contactUs: {
        AR: "تواصل معنا",
        EN: "contact us"
    },
    contactInformation: {
        AR: "معلومات التواصل",
        EN: "Contact Information"
    },

    // About
    teachers: {
        AR: "المعلمين",
        EN: "Teachers"
    },
    saraPlatform: {
        AR: "منصة Sara Physics التعليمية",
        EN: "sara physics Educational Platform"
    },
    future: {
        AR: "اصنع مستقبلك!",
        EN: "Create your future"
    },
    futureContent: {
        AR: "لتعليم مادة الفيزياء وبامكانكم عبر موقعنا الالكتروني اختيار الحصص والتوقيت المناسب والحجز والاشتراك بالحصص وجميع معلومات",
        EN: "For teaching physics, you can choose the classes and suitable timing through our website, book and subscribe to the classes, and find all the information."
    },
    whyplatform: {
        AR: "لماذا منصة Sara Physics التعليمية",
        EN: "Why the sara physics educational platform"
    },
    whyContent: {
        AR: "لتعليم مادة الفيزياء وبامكانكم عبر موقعنا الالكتروني اختيار الحصص والتوقيت المناسب والحجز والاشتراك بالحصص وجميع معلومات",
        EN: "Why the AGA educational platform for teaching physics, and you can choose the classes, suitable timings, book, and subscribe to the sessions through our website, along with all the information."
    },
    lookDevelop: {
        AR: "نحن نبحث عن التطور الدائم",
        EN: " strive for development"
    },
    looktamiz: {
        AR: "نسعى دائما للتميز",
        EN: "excellence strive  "
    },
    looksuccess: {
        AR: "معنا نضمن لك النجاح كمعلم تربوى",
        EN: "outstanding education"
    },
    Specialprice: {
        AR: "اسعارنا مميزة",
        EN: "special prices "
    },
    work: {
        AR: "كيف تعمل؟",
        EN: " How does it work?"
    },
    workOne: {
        AR: "احصل على التفوق",
        EN: "Achieve excellence."
    },
    workOneContent: {
        AR: "اصنع مستقبلك",
        EN: "Create your future!"
    },
    workTwo: {
        AR: "اشترك في دورتك",
        EN: "Subscribe to your course"
    },
    workTwoContent: {
        AR: "قم بالأشتراك في الباقة و تعلم كل جديد",
        EN: "learn everything new"
    },
    workThree: {
        AR: "ابحث عن صفك",
        EN: "Find your class.",
    },
    workThreeContent: {
        AR: "ابحث عن المعلم المناسب لك و الذي تريده",
        EN: "Find the class for you "
    },
    // Privacy
    privacyContent: {
        AR: "سياسة الخصوصية –منصة التعليمية",
        EN: "Privacy & Usage Policy - Sara Physics"
    },
    privacyData: {
        AR: "الخصوصية وبيان سريّة المعلومات",
        EN: "Privacy and Confidentiality Statement"
    },
    privacyForIt: {
        AR: `
        نأخذ على محمل الجد خصوصية زوار موقعنا الإلكتروني وأمن أية معلومات شخصية قد يقدمها زوار الموقع. ونؤمن بحقك في معرفة نوع المعلومات التي يجمعها الموقع، وكيف يتم حمايتها واستخدامها، وتحت أية ظروف يتم الكشف عنها. ولهذا أعددنا سياسة الخصوصية هذه ونشرناها على موقعنا لكي تساعدكم في فهم طبيعة البيانات التي نقوم بتجميعها عنكم عند زيارتكم لموقعنا على شبكة الإنترنت وكيفية تعاملنا مع هذه البيانات.
        `,
        EN: "We take the privacy of our website visitors and the security of any personal information that visitors may provide very seriously. We believe in your right to know what types of information the site collects, how it is protected and used, and under what circumstances it may be disclosed. Therefore, we have prepared this privacy policy and published it on our site to help you understand the nature of the data we collect about you when you visit our website and how we handle this data."
    },

    browsePrivacy: {
        AR: "تصفح الموقع",
        EN: "Browse the site  "
    },

    browserPrivacy: {
        AR: "لا يهدف هذا الموقع إلى جمع بياناتك الشخصية أثناء تصفحك للموقع، وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك",
        EN: "This site does not aim to collect your personal data while you are browsing the site, but the data provided by you will only be used with your knowledge and of your own free will."
    },
    // contact Us

    Stayincontact: {
        AR: "ابقى على تواصل",
        EN: "Stay in contact"
    },
    helContactUs: {
        AR: "نحن هنا لمساعدتك .. لا تتردد في التواصل معنا",
        EN: "We are here to help you.. do not hesitate to contact us."
    },
    phone: {
        AR: "رقم الهاتف",
        EN: "Phone number"
    },
    email: {
        AR: "البريد الإلكتروني",
        EN: "Email"
    },
    follwo: {
        AR: "تابعنا علي",
        EN: "Follow us :"
    },

    // form conatct us
    leaveMessage: {
        AR: "اترك رسالة",
        EN: "Send Message"
    },
    contentLeave: {
        AR: "منصة Sara Physics التعليمية - طريقك نحو التميز والتفوق والإبداع، انتهز الفرصة!",
        EN: "Sara Physics Educational Platform - Your path to excellence, excellence and creativity, seize the opportunity"
    },
    nameForm: {
        AR: "الاسم",
        EN: "First Name"
    },
    parentName: {
        AR: "اسم الوالد",
        EN: "Second Name"
    },
    attend: {
        AR: "الغياب",
        EN: "Absence"
    },
    message: {
        AR: "رساله",
        EN: "Message"
    },

    sendMsg: {
        AR: "ارسال",
        EN: "Send"
    },

    notSend: {
        AR: "لم يتم الارسال",
        EN: "Not Send"
    },
    Send: {
        AR: " تم الارسال",
        EN: "Send"
    },

    // Register Form
    forRegister: {
        AR: "تسجيل الدخول",
        EN: "login"
    },

    registerData: {
        AR: "تسجيل",
        EN: "Register"
    },

    loginRegisrer: {
        AR: "دخول",
        EN: "Login"
    },

    welcomeRegister: {
        AR: "مرحبا فى صفحة تسجيل الدخول",
        EN: "Welcome to the login page"
    },
    welcomeLogin: {
        AR: "مرحبا فى صفحة تسجيل الجديد",
        EN: "Welcome to the Register page"
    },
    pass: {
        AR: "كلمة السر",
        EN: "Password"
    },
    Newpass: {
        AR: "كلمة السر الجديده",
        EN: "New Password"
    },
    oldPAss: {
        AR: "كلمة السر القديمه",
        EN: "Old Pass"
    },
    confirmPass: {
        AR: "تاكيد كلمة السر",
        EN: "Confirm Password"
    }
    ,
    code: {
        AR: "ادخل الكود المرسل",
        EN: "Enter The Code"
    },
    enter: {
        AR: "دخول",
        EN: "Enter"
    },
    registerEnter: {
        AR: "تسجيل",
        EN: "Register"
    },

    repass: {
        AR: "اعد ادخال الرقم السري",
        EN: "Please Re Enter Your Password"
    },
    type: {
        AR: "النوع",
        EN: "Type"
    },
    male: {
        AR: "ذكر",
        EN: "Male"
    },
    female: {
        AR: "انثي",
        EN: "Female"
    },
    age: {
        AR: "العمر",
        EN: "Age"
    },

    // Dashboards

    dashboard: {
        AR: "لوحة التحكم",
        EN: "Dashboard"
    },
    subscriptions: {
        AR: "الاشتراكات",
        EN: "Subscriptions"
    },
    levels: {
        AR: "المستويات",
        EN: "Levels"
    },

    Lesson: {
        AR: "الدرس",
        EN: "Lesson"
    }
    ,
    materials: {
        AR: "المواد",
        EN: "Units"
    },

    programs: {
        AR: "البرامج",
        EN: "Courses"
    },
    classes: {
        AR: "الفصول",
        EN: "Classes"
    },
    profile: {
        AR: "حسابي",
        EN: "Profile"
    },
    students: {
        AR: "الطلاب",
        EN: "Students"
    },
    oldSubscriptions: {
        AR: "طلبات تم الرد عليها",
        EN: "Requests answered"
    },
    newSubscriptions: {
        AR: "طلبات جديده",
        EN: "New Subscriptions"
    },
    Requestswaiting: {
        AR: "طلبات في الانتظار",
        EN: "Requests are waiting"
    },
    package: {
        AR: "الباقه",
        EN: "Package"
    },
    price: {
        AR: "المبلغ",
        EN: "Price"
    },
    date: {
        AR: "التاريخ",
        EN: "Date"
    },
    action: {
        AR: "الاكشن",
        EN: "Action"
    },

    addLevel: {
        AR: "اضافة مستوي",
        EN: "Add Level"
    },
    addLevelName: {
        AR: "ادخل اسم المستوي",
        EN: "Add Level Name"
    },
    levelName: {
        AR: "اسم المستوي",
        EN: "Level Name"
    },
    noLevels: {
        AR: "لا يوجد مستويات",
        EN: "No Levels"
    },
    editLevel: {
        AR: "تعديل المستوي",
        EN: "Edit Level"
    },
    editLevelDetails: {
        AR: "اضف تفاصيل المستوي",
        EN: "Edit Level Details"
    },
    chooseLevelsystem: {
        AR: "اختر نظام المستوي",
        EN: "Choose Level System"
    },
    chooseMaterial: {
        AR: "اختر ماده",
        EN: "Choose Unit"
    },
    currentMaterials: {
        AR: "المواد الحاليه",
        EN: "Current Units"
    },
    addMaterial: {
        AR: "اضافة ماده",
        EN: "Add Unit"
    },
    AddMaterial: {
        AR: "اضافة الماده",
        EN: "Add Unit"
    },
    material: {
        AR: "ماده",
        EN: "Unit"
    },
    materialCode: {
        AR: "كود الماده",
        EN: "Unit Code"
    },
    materialLesson: {
        AR: "دروس الماده",
        EN: "Unit Lesson"
    },
    addLesson: {
        AR: "اضافة الدرس",
        EN: "Add Lesson"
    },
    addNewLesson: {
        AR: "اضافة درس جديد",
        EN: "Add New Lesson"
    },

    lessons: {
        AR: "الدروس",
        EN: "Lessons"
    },
    nameLesson: {
        AR: "اسم الدرس",
        EN: "Name Lesson"
    },
    deleteLesson: {
        AR: "حذف الدرس",
        EN: "Delete Lesson"
    },
    uploadImage: {
        AR: "رفع الصوره",
        EN: "Upload Image"
    },
    uploadVideo: {
        AR: "رفع الفيديو",
        EN: "Upload Video"
    },
    uploadPdf: {
        AR: "رفع PDF",
        EN: "Upload PDF"
    },
    addTest: {
        AR: "اضافةأختبار",
        EN: "Add Test"
    },
    test: {
        AR: "اختبار",
        EN: "Test"
    }
    ,
    addProgram: {
        AR: "اضافة برنامج",
        EN: "Add Program"
    },
    choices: {
        AR: "اختيارات",
        EN: "Choices"
    },

    editCoverImage: {
        AR: "تعديل الصوره",
        EN: "Edit Cover Image"
    }
    ,
    updateLesson: {
        AR: "تحديث الدرس",
        EN: "Update Lesson"
    },
    update: {
        AR: "تحديث",
        EN: "Update"
    },
    saveChanges: {
        AR: "حفظ التغييرات",
        EN: "Save Changes"
    },
    show: {
        AR: "عرض",
        EN: "Show"
    },
    showTest: {
        AR: "عرض الامتحان",
        EN: "Show Test"
    },
    showResultsLesson: {
        AR: "نتائج الاختبار",
        EN: "Test Result"
    },
    edit: {
        AR: "تعديل",
        EN: "Edit"
    },
    delete: {
        AR: "حذف",
        EN: "Delete"
    },
    didSuredelStu: {
        AR: "هل انت متاكد من حذف الطالب",
        EN: "Are you sure you want to delete the student"
    },
    close: {
        AR: "اغلاق",
        EN: "Close"
    },
    add: {
        AR: "اضافه",
        EN: "Add"
    },
    updateCoverImage: {
        AR: "تعديل صورة الكورس",
        EN: "Update Cover Image"
    }
    ,

    // Programs Admin
    addNewProgram: {
        AR: "اضافة برنامج جديد",
        EN: "Add New Course"
    },

    packageName: {
        AR: "اسم الباقه",
        EN: "Package Name"
    },
    level: {
        AR: "المستوي",
        EN: "Level"
    },
    // price
    // materials
    chooseLesson: {
        AR: "اختر الدرس",
        EN: "Choose Lesson"
    },

    thePrograms: {
        AR: "البرامج الحاليه",
        EN: "Current Courses"
    },

    // Calsses
    addCalss: {
        AR: "اضافة الفصل",
        EN: "Add Class"
    },
    currentclasses: {
        AR: "الفصول الحاليه",
        EN: "Current Classes"
    },
    nonActiveClasses: {
        AR: "فصول غير مفعله",
        EN: "Classes are inactive"
    },

    class: {
        AR: "الفصل",
        EN: "class"
    },
    // studyProgram
    studentLength: {
        AR: "عدد الطلاب",
        EN: "Student Length"
    },
    sendMessage: {
        AR: "ارسل رساله",
        EN: "Sending Message"
    },
    addStudent: {
        AR: "اضف طلاب",
        EN: "Add Students"
    },
    // students
    createRoom: {
        AR: "الحصص",
        EN: "session"
    },
    createSession: {
        AR: "انشاء حصه",
        EN: "Create Session"
    },
    showMeetings: {
        AR: "الغرف",
        EN: "Sessions"
    },

    sendAll: {
        AR: "ارسل للجميع",
        EN: "Send All"
    },

    sendReports: {
        AR: "ارسال التقارير ",
        EN: "Send Reports"
    },

    deactiveCalss: {
        AR: "تعطيل الفصل",
        EN: "Deactive Class"
    },

    address: {
        AR: "العنوان",
        EN: "Address"
    },
    content: {
        AR: "المحتوي",
        EN: "Content"
    },

    send: {
        AR: "ارسل",
        EN: "Send"
    },
    chooseStudents: {
        AR: "اختر طلاب",
        EN: "Choose Students"
    },
    chooseStuden: {
        AR: "اختر طالب",
        EN: "Choose Student"
    },

    // Report

    addreport: {
        AR: "اضافة التقارير",
        EN: "Add Report"
    },
    addreport2: {
        AR: "ارسال التقارير",
        EN: "Send Report"
    },
    chooseTypeReport: {
        AR: "اختر نوع التقارير",
        EN: "Choose Type Report"
    },
    attendAndhomeWork: {
        AR: "غياب & واجب",
        EN: "Homework & attendence",
    },
    addRoom: {
        AR: "اضافة غرف",
        EN: "Add Room"
    },
    hwonly: {
        AR: "الواجب",
        EN: "H.W"
    },
    attendOnly: {
        AR: "الغياب",
        EN: "Attendence"
    },
    lessonName: {
        AR: "اسم الدرس",
        EN: "Name Lesson"
    },
    homeWork: {
        AR: "الواجب",
        EN: "H.W"
    },
    sessionName: {
        AR: "اسم الحصه",
        EN: "Session Name"
    }
    ,
    sessionDate: {
        AR: "وقت الحصه",
        EN: "Session Date"
    }
    ,
    downloadHW: {
        AR: "تنزيل الواجب",
        EN: "downlaod H.W"
    },
    viewHW: {
        AR: "عرض الواجب",
        EN: "view H.W"
    },
    present: {
        AR: "حضور",
        EN: "Present"
    },
    dateAndTime: {
        AR: "التاريخ والوقت",
        EN: "Date & Time"
    },
    materialRecord: {
        AR: "تسجيل الماده",
        EN: "Unit Record"
    },
    addNote: {
        AR: "اضف ملاحظه",
        EN: "Add Note"
    },
    AddHomeWork: {
        AR: "اضافة الواجب",
        EN: "Add HomeWork"
    },
    create: {
        AR: "انشاء",
        EN: "Create"
    },
    showSessions: {
        AR: "عرض الحصص",
        EN: "Show Session"
    },
    session: {
        AR: "حصه",
        EN: "session"
    },
    adminLink: {
        AR: "رابط غرفة الادمن",
        EN: "Zoom Admin Link"
    },
    adminStudents: {
        AR: "رابط غرفة الطالب",
        EN: "Zoom Student Link"
    },
    reports: {
        AR: "التقارير",
        EN: "Reports"
    },
    mettingStatus: {
        AR: "حالة الاجتماع",
        EN: "Meeting Status"
    },
    materialRecords: {
        AR: "تسجيلات الماده",
        EN: "unit Records"
    },
    addNewClass: {
        AR: "اضافة فصل جديد",
        EN: "Add New Class"
    },
    chooseLevel: {
        AR: "اختر المستوي",
        EN: "Choose Level"
    },
    chooseProgram: {
        AR: "اختر البرنامج",
        EN: "Choose Program"
    },
    forgetPass: {
        AR: "هل نسيت كلمة السر",
        EN: "Did You Forget Password"
    },
    phoneStudent: {
        AR: "رقم تلفون الطالب ( واتساب)",
        EN: "Student Phone (Whatsapp )"
    },
    phoneParent: {
        AR: "رقم تلفون ولي الامر (رقم واتساب)",
        EN: "Parent Phone (Whatsapp )"
    },
    nameSchool: {
        AR: "اسم المدرسه",
        EN: "Name School"
    },
    grade: {
        AR: "في اي صف تتواجد",
        EN: "What grade are you in?"
    },
    addHW: {
        AR: "اضافة واجب",
        EN: "Add H.W"
    },
    uploadHomeWork: {
        AR: "اضافة الواجب",
        EN: "Upload H.W"
    },
    addRecodd: {
        AR: "اضافة تسجيل",
        EN: "Add Record"
    },
    finish: {
        AR: "تم الانتهاء",
        EN: "Finsih"
    },
    country: {
        AR: "الدوله",
        EN: "Country"
    },
    cancel: {
        AR: "الغاء",
        EN: "Cancel"
    },
    detailsProgram: {
        AR: "تفاصيل البرنامج",
        EN: "Program Details "
    },
    discountPrice: {
        AR: "سعر الخصم",
        EN: "Discount Price"
    },
    updateLessonontent: {
        AR: "تحديث محتوي الدرس",
        EN: "Update Lesson Content"
    },
    coursPhoto: {
        AR: "صورة البرنامج",
        EN: "Course Photo"
    },
    coin: {
        AR: "ج.م",
        EN: "EGP"
    },
    free: {
        AR: "مجانا",
        EN: "Free"
    },
    coursePackage: {
        AR: "سعر الباقه",
        EN: "Course Price"
    },
    namePackage: {
        AR: "اسم الباقه",
        EN: "Package Name"
    },
    Confirmation: {
        AR: "تاكيد",
        EN: "Confirmation"
    },

    deactiveClass: {
        AR: "هل انت متاكد من تعطيل الفصل",
        EN: "Are you sure you want to deactivate this class?"
    },

    deactive: {
        AR: "تعطيل",
        EN: "Deactivate"
    },

    pound: {
        AR: "جنيه",
        EN: "pound"
    },

    startDate: {
        AR: "اختر تاريخ البدايه",
        EN: "Select Start Date"
    },
    endDate: {
        AR: "اختر تاريخ النهايه",
        EN: "Select End Date"
    },
    MaterialName: {
        AR: "اسم الماده",
        EN: "Unit Name"
    },
    CreateMaterial: {
        AR: "اضافة الماده",
        EN: "Create Unit"
    },
    selectedStudents: {
        AR: "الطلاب المحددين",
        EN: "Selected Students"
    },
    selectedStudentsDel: {
        AR: "حذف الطلاب المحددين ",
        EN: "Delete Selected Students"
    },

    selectAllStd: {
        AR: "تحديد كل الطلاب",
        EN: "Select All Students"
    },
    selectAllStdCancel: {
        AR: "إلغاء تحديد الكل",
        EN: "Cancel Select"
    },

    enterMessage: {
        AR: "من فضلك قم بادخال الرساله",
        EN: "Please Enter Message"
    },
    chooseType: {
        AR: "اختر نوع الرساله",
        EN: "Please Select Message Type"
    },
    sendToStudent: {
        AR: "ارسل الي الطالب",
        EN: "Send To Student"
    },
    sendToParent: {
        AR: "ارسل الي الوالد",
        EN: "Send To Parent"
    },
    sendToParentAndStudent: {
        AR: "ارسال الي الطالب  والوالد",
        EN: "Send To Parent and Student"
    },

    wantDelete: {
        AR: "هل انت متاكد من الحذف",
        EN: "Did You Sure From Delete ?"
    },
    yes: {
        AR: "تاكيد",
        EN: "yes"
    },
    showMaterials: {
        AR: "الماده",
        EN: "Units"
    },
    editDetails: {
        AR: "تعديل التفاصيل",
        EN: "Edit Details"
    },
    courseName: {
        AR: "اسم البرنامج",
        EN: "Course Name"
    },
    coursePrice: {
        AR: "سعر البرنامج",
        EN: "Course Price"
    },
    courseDiscount: {
        AR: "سعر الخصم",
        EN: "Course Discount"
    },
    addedLesson: {
        AR: " الدروس المضافة",
        EN: "Added Lessons"
    },
    showSession: {
        AR: " عرض الحصص",
        EN: "Show Session"
    },
    reuploadHW: {
        AR: "اعادة الواجب",
        EN: "Re Upload H.W"
    },
    uploadHW: {
        AR: "اضافة الواجب",
        EN: "Upload H.W"
    },
    viewHw: {
        AR: "عرض الواجب",
        EN: "Show H.W"
    },
    upload: {
        AR: "رفع",
        EN: "Upload"
    },
    goSession: {
        AR: "دخول الي الاجتماع",
        EN: "Go To Meeting"
    },
    noteEmail: {
        AR: "يجب دخول الحصه الايف من خلال الايميل المسجل علي المنصه",
        EN: "Should Login To Meeting From Email Registered On The Platform"
    },
    liveSession: {
        AR: "دخول الحصه",
        EN: "Live Session"
    },

    dwVideo: {
        AR: " تنزيل فيديو",
        EN: "Download Video"
    },
    notSessionRecord: {
        AR: "لا توجد حصص مضافه",
        EN: "There are no added servings"
    },
    deleteSession: {
        AR: "هل أنت متأكد أنك تريد حذف هذه الجلسة؟",
        EN: "Did you want to delete Session"
    },
    deleteStudent: {
        AR: "هل أنت متأكد أنك تريد حذف هذا الطالب",
        EN: "Did you want to delete this student"
    },
    deleteMat: {
        AR: "هل أنت متأكد أنك تريد حذف هذه الماده ",
        EN: "Did you want to delete this Material"
    },
    branchSystem: {
        AR: "النظام",
        EN: "System"
    },
    infoParent: {
        AR: "معلومات ولي الامر",
        EN: "Info Parent"
    },
    stdResult: {
        AR: "نتائج الطالب",
        EN: "Student Result"
    },
    testName: {
        AR: "اسم الاختبار",
        EN: "test Name"
    },
    testStatus: {
        AR: "حالة الاختبار",
        EN: "test Status"
    },
    testRes: {
        AR: "نتيجة الاختبار",
        EN: "test Result"
    },
    triesNum: {
        AR: "عدد المحاولات",
        EN: "num of tries"
    },
    triesNumAdd: {
        AR: "اضف عدد المحاولات",
        EN: " Add  tries"
    },
    stdNotTest: {
        AR: "الطالب لم يدخل الامتحان بعد",
        EN: "Student Dont Enter This Test"
    },
    success: {
        AR: "ناجح",
        EN: "Success"
    },
    fail: {
        AR: "راسب",
        EN: "Fail"
    },
    pricePackage: {
        AR: "السعر",
        EN: "Price"
    },
    classSettings: {
        AR: "اعدادات الفصل",
        EN: "Class Settings"
    },
    userName: {
        AR: "اسم المستخدم",
        EN: "User Name"
    },
    Record: {
        AR: "ريكود",
        EN: "Record"
    },
    mocks: {
        AR: "اختبارات",
        EN: "Mocks"
    },
    view: {
        AR: "الاجابات",
        EN: "Answers"
    },
    stateDegree: {
        AR: "حالة الاختبار",
        EN: "State Degree"
    },
    addMock: {
        AR: "اضافة احتبار",
        EN: "Add Mock"
    },
    showMocks: {
        AR: "عرض الاختبارات",
        EN: "show Mocks"
    },
    editMock: {
        AR: "تعديل الاختبار",
        EN: "Edit Mocks"
    },
    desc: {
        AR: "الوصف",
        EN: "Describtion"
    },
    showfile: {
        AR: "عرض الملف",
        EN: "Show File"
    },
    lastState: {
        AR: "اخر حاله",
        EN: "Last State"
    },
    onlyfile:{
        AR:"ملف",
        EN:"File"
    },
    viewAnswers:{
        AR:"عرض الاجابه",
        EN:"View Answers"
    },
    addAnswer:{
        AR:"اضف الاجابه",
        EN:"Add Answer"
    },
    lastMark:{
        AR:"اخر درجه",
        EN:"Last Mark"
    },
    notRes:{
        AR:"لا توجد اجابات",
        EN:"No Results Available<"
    },
    status:{
        AR:"حالة الاجابه",
        EN:"Status"
    },
    loading:{
        AR:"تحميل",
        EN:"Loading"
    },
    sure:{
        AR:"تاكيد",
        EN:"Sure"
    },
    marks:{
        AR:"الدرجه",
        EN:"Mark"
    },
    uploadAnswer:{
        AR:"رفع املف الاجابه",
        EN:"Upload Answer"
    },
    nameMock:{
        AR:"اسم الاختبار",
        EN:"Name Mock"
    },
    descMock:{
        AR:"وصف الاختبار",
        EN:"Mock Describtion"
    },
    name:{
        AR:"الاسم" ,
        EN:"Name"
    },
    learnMore:{
        AR:"معرفة المزيد",
        EN:"More"
    },
    noFile:{
        AR:"لا يوجد ملف",
        EN:"No File"
    },
    mockName:{
        AR:"اسم الاختبار",
        EN:"Mock Name"
    },
    deleteMock:{
        AR:"حذف الاختبار",
        EN:"Delete Mock"
    },
    infoDelMock:{
        AR:"هل انت متاكد من حذف الاختبار",
        EN:"Are You Sure For Delete Mock"
    },
    noMarks:{
        AR:"لا يوجد درجات",
        EN:"No Marks"
    },
    answer:{
        AR:"الاجابات",
        EN:"Answers"
    },
    res:{
        AR:"نتيجة",
        EN:"Result"
    },
    stdName:{
        AR:"اسم الطالب",
        EN:"Student Name"
    },
    addDegree:{
        AR:"اضافة درجه",
        EN:"Add Degree"
    },
    changeState:{
        AR:"تغيير الحاله",
        EN:"Change State"
    },
    again:{
        AR:"محاولة اخري",
        EN:"Try Again"
    },
    submit:{
        AR:"تاكيد",
        EN:"Submit"
    },
    viewComment:{
        AR:"عرض التعليق",
        EN:"View Comment"
    },
    comment:{
        AR:"التعليق",
        EN:"Commemnt"
    }
};

// AR:'',
// EN:'',
// FR:''