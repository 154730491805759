
import { ADD_RECORD_MANUALLY, GET_SESSIONS_LESSON, HANDLE_DELETE_SESSION } from "../actions/sessions"

export const sessionsRed = (state = [], action) => {
    switch (action.type) {
        case GET_SESSIONS_LESSON:
            return state = action.sessions
        case ADD_RECORD_MANUALLY:
            console.log(action)
            return state = state.map(s => {
                if (s._id === action.session._id) {
                    return action.session
                }
                return s
            })
        case HANDLE_DELETE_SESSION:
            console.log(action)
            return state = state.filter(s => s._id !== action.id)
        // case GET_MATERIALS:
        //     console.log('reducer')
        //     return state= action.materials
        default: return state
    }
}
