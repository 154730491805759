import { deleteRecManually } from "../utils/API";
import { addRecordManually } from "../utils/class";
import { deleteSession, getSession, updateSessionNote } from "../utils/sessions";

export const GET_SESSIONS_LESSON = 'GET_SESSIONS_LESSON';


const getSessionsData = (sessions) => {
    return {
        type: GET_SESSIONS_LESSON,
        sessions
    }
}
export const Get_Sessions = (classId, lesson) => {
    return dispatch => getSession(classId, lesson).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(getSessionsData(res?.data?.sessions))
        }
        return res
    })
}



export const ADD_RECORD_MANUALLY = 'ADD_RECORD_MANUALLY';


const addRec = (session) => {
    return {
        type: ADD_RECORD_MANUALLY,
        session
    }
}
export const Add_Record_Manually = (classId, userId) => {
    return dispatch => addRecordManually(classId, userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(addRec(res.data.session))
        }
        return res
    })
}


export const HANDLE_DELETE_SESSION = 'HANDLE_DELETE_SESSION';


const handleSession = (id) => {
    return {
        type: HANDLE_DELETE_SESSION,
        id
    }
}
export const handleDelSession = (data, userId) => {
    return dispatch => deleteSession(data, userId).then(res => {
        console.log(res)
        dispatch(handleSession(data?.sessionId))
        return res
    })
}


// export const HANDLE_DELETE_RECORD_MANUALLY = 'HANDLE_DELETE_SESSION';


// const handleSession = (id) => {
//     return {
//         type: HANDLE_DELETE_SESSION,
//         id
//     }
// }
export const handleDelSessionManually = (data, userId) => {
    return dispatch => deleteRecManually(data, userId).then(res => {
        if (res.data.case) {
            dispatch(addRec(res.data.session))
        }
        return res
    })
}

// Update Session Note
export const update_session_note = (data, classId, sessionId, userId) => {
    return dispatch => updateSessionNote(data, classId, sessionId, userId).then(res => {
        if (res.data.case) {
            dispatch(addRec(res.data.updatedSession))
        }
        return res
    })
}