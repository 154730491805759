import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaRegCopy } from "react-icons/fa6";
import { deleteSession, getSession } from '../../utils/sessions';
import { ErrorMsg, success } from '../../utils/Toast';
import ReportModal from './ReportModal';
import ShowRecords from './ShowRecords';
import Dropdown from 'react-bootstrap/Dropdown';
import { HomePage } from '../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import ModalDeleteSession from './ModalDeleteSession';
import ModalUploadHomeWork from './ModalUploadHomeWork';
import AddRecordMan from "./AddRecordMan"
import { FaEdit } from 'react-icons/fa';
import { Get_Sessions, update_session_note } from '../../actions/sessions';
const TableModal = ({ lessonId, classData }) => {
    const [showTable, setShowTable] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [deleteSessionId, setDeleteSessionId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [sessionData, setSession] = useState([]);
    const [editingSessionId, setEditingSessionId] = useState(null); // Editing session
    const [note, setNote] = useState(null); // Note for editing

    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const allSessions = useSelector((state) => state.sessionsRed);
    const { lang, dir } = useSelector(({ language }) => ({ lang: language.lang, dir: language.dir }));

    const handleCloseTable = () => setShowTable(false);
    const handleShowTable = () => setShowTable(true);
    const handleDeleteModalClose = () => setShowDeleteModal(false);
    const handleUploadModalClose = () => setShowUploadModal(false);

    const handleDeleteModalShow = (sessionId) => {
        setDeleteSessionId(sessionId);
        setShowDeleteModal(true);
        handleCloseTable();
    };

    const handleUploadModal = (sessionId, session) => {
        setDeleteSessionId(sessionId);
        setSession(session);
        setShowUploadModal(true);
        handleCloseTable();
    };

    const getSessionForClass = async () => {
        try {
            const res = await dispatch(Get_Sessions({ classId: classData?._id, lesson: lessonId }));
            setSessions(res?.data?.sessions);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getSessionForClass();
    }, [lessonId, classData?._id]);

    const handleDeleteSession = (sessionId) => {
        setSessions((prevSessions) => prevSessions.filter(session => session._id !== sessionId));
    };

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link);
        success("تم النسخ");
    };

    const handleEditClick = (sessionId, currentNote) => {
        setEditingSessionId(sessionId);
        setNote(currentNote); // Set current note for editing
    };

    const updateSessionNoteData = async (sessionId) => {
        try {
            await dispatch(update_session_note({ note }, classData?._id, sessionId, loggedUser?.user?._id));
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message);
            }
        }
    };

    const handleFormSubmit = async (e, sessionId) => {
        e.preventDefault(); // Prevent form from reloading the page
        await updateSessionNoteData(sessionId);
        setEditingSessionId(null); // Exit edit mode after update
    };

    const formatToCairoTime = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Africa/Cairo',
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleString('en-US', options);
    };


    return (
        <div>
            <button className='btn' style={{ color: "white", background: "#4169E2" }} onClick={handleShowTable}>
                {HomePage.show[lang]}
            </button>
            <Modal show={showTable} onHide={handleCloseTable} centered size='xl' dir={dir}>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>num</th>
                                <th>{HomePage.session[lang]}</th>
                                <th>{HomePage.adminLink[lang]}</th>
                                <th>{HomePage.adminStudents[lang]}</th>
                                <th>{HomePage.date[lang]}</th>
                                <th>{HomePage.uploadHomeWork[lang]}</th>
                                <th>{HomePage.addRecodd[lang]}</th>
                                <th>{HomePage.reports[lang]}</th>
                                <th>{HomePage.mettingStatus[lang]}</th>
                                <th>{HomePage.delete[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allSessions?.map((session, index) => (
                                <tr key={session?._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {editingSessionId === session?._id ? (
                                            <form onSubmit={(e) => handleFormSubmit(e, session?._id)}>
                                                <input
                                                    type="text"
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                    autoFocus
                                                />
                                            </form>
                                        ) : (
                                            <>
                                                {session?.note}
                                                <FaEdit
                                                    onClick={() => handleEditClick(session?._id, session?.note)}
                                                    style={{ cursor: 'pointer', marginLeft: '1px', fontSize: "12px" }}
                                                />
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {
                                            !session.meetingEnd ? (
                                                <>
                                                    {session?.startLink.slice(0, 10)}
                                                    <FaRegCopy
                                                        onClick={() => copyToClipboard(session?.startLink)}
                                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                    />
                                                </>
                                            ) : (<span>{HomePage.finish[lang]}  </span>)
                                        }
                                    </td>
                                    <td className='text-center'>
                                        {
                                            !session.meetingEnd ? (
                                                <>
                                                    {session?.joinLink.slice(0, 10)}
                                                    <FaRegCopy
                                                        onClick={() => copyToClipboard(session?.joinLink)}
                                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                    />
                                                </>
                                            ) : (<span>{HomePage.finish[lang]}</span>)
                                        }

                                    </td>
                                    <td>{formatToCairoTime(session?.date)}</td>
                                    <td>
                                        <AddRecordMan classData={classData} session={session} lessonId={lessonId} />
                                    </td>
                                    <td>
                                        <button className='btn'
                                            onClick={() => handleUploadModal(session._id, session)}
                                            style={{ color: "white", background: "#4169E2", fontSize: "13px" }}>{HomePage.addHW[lang]}</button>
                                    </td>
                                    <td>
                                        {
                                            session.meetingEnd ? (
                                                <>
                                                    <ReportModal session={session} classData={classData} />

                                                </>
                                            ) : (

                                                <>
                                                    <button className='btn' style={{ color: "white", background: "#4169E2" }} disabled>{HomePage.reports[lang]}</button>
                                                    {/* <ReportModal session={session} classData={classData} /> */}
                                                </>
                                            )
                                        }

                                    </td>
                                    <td>
                                        {session.meetingEnd ? (
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn" style={{ background: "rgb(65, 105, 226)" }} id="dropdown-basic">
                                                    {HomePage.materialRecords[lang]}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        session?.record?.map((rec, index) => {
                                                            return (
                                                                <Dropdown.Item>
                                                                    <ShowRecords
                                                                        isManual={rec?.isManual}
                                                                        classData={classData} session={session}
                                                                        recordingCode={rec?.recordingCode}
                                                                        title={`ريكورد ${index + 1}`} urlVideo={rec?.play_url} downloadVideo={rec?.download_url} />
                                                                </Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : (
                                            <a href={`${session.startLink}`} target="_blank" rel="noreferrer">{HomePage.enter[lang]}</a>
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            className='btn'
                                            style={{ backgroundColor: "#D43B43" }}
                                            onClick={() => handleDeleteModalShow(session._id)}
                                        >
                                            {HomePage.delete[lang]}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            {/* Delete Session Modal */}
            <ModalDeleteSession
                show={showDeleteModal}
                handleClose={handleDeleteModalClose}
                sessionId={deleteSessionId}
                classId={classData?._id}
                onDelete={handleDeleteSession}
            />

            <ModalUploadHomeWork
                show={showUploadModal}
                handleClose={handleUploadModalClose}
                sessionId={deleteSessionId}
                classId={classData?._id}
                session={sessionData}

            />

            {/* <ReportModal session={session} classData={classData} /> */}

        </div>
    );
};

export default TableModal;
