import { getAnswerStudent } from "../utils/mock";
export const GET_ANSWERS_BY_MOCK = "GET_ANSWERS_BY_MOCK";
export const UPDATE_ANSWER = "UPDATE_ANSWER";

// Fetch answers for a specific mock
const getAnswersByMock = (mockId, answers) => ({
    type: GET_ANSWERS_BY_MOCK,
    mockId,
    answers,
});

export const Get_Answers_By_Mock = (mockId, userId) => {
    return async (dispatch) => {
        try {
            const res = await getAnswerStudent(mockId, userId);
            console.log("redux DATA A ",res)
            if (res?.data) {
                dispatch(getAnswersByMock(mockId, res?.data?.mockAnswers));
            }
        } catch (err) {
            console.error(err);
        }
    };
};

// Update an answer
const update_answer = (mockId, updatedAnswer) => ({
    type: UPDATE_ANSWER,
    mockId,
    updatedAnswer,
});

export const Update_Answer = (mockId, updatedAnswer) => {
    return (dispatch) => {
        // Add logic to update the answer using an API if needed
        dispatch(update_answer(mockId, updatedAnswer));
    };
};
