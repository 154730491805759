import React from 'react'
import {BsFillArrowRightSquareFill,BsArrowLeftSquareFill} from "react-icons/bs"
import {ImHome} from "react-icons/im"
import {useNavigate} from 'react-router-dom'
function NavigationBar() {
  const navigate = useNavigate()

  return (
    <div className='top-bar'>
    <div className='navigat'>
   <BsArrowLeftSquareFill onClick={()=>{navigate(-1)}} size='3em' style={{marginRight:'5px'}} />
    <BsFillArrowRightSquareFill onClick={()=>{navigate(1)}} size='3em' style={{marginLeft:'5px'}}/>
    </div>
    <div className='hom'>
    <ImHome size='3em' onClick={()=>{navigate('/')}} />
    </div>
  </div>
    )
}

export default NavigationBar