import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Mocks } from '../../../actions/mockAction';
import ShowFileMock from './ShowFileMock';
import UpdateMock from './UpdateMock';
import DeleteMock from './DeleteMock';
import { urlBase } from '../../../utils/API'
const ShowMocks = ({ materialId, mocks }) => {
    const [show, setShow] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState(null); // To handle full description
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const handleallMocks = async () => {
        try {
            const resMocks = await dispatch(Get_Mocks(materialId));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleallMocks();
    }, []);

    // const mocks = useSelector((state) => state.mockReducer);
    console.log(mocks);

    // Function to handle showing the full description in a small div
    const handleShowFullDescription = (description) => {
        setSelectedDescription(description);
    };

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                {HomePage.showMocks[lang]}
            </Button>

            <Modal show={show} onHide={handleClose} centered dir={dir} size="lg">
                <Modal.Header>
                    <Modal.Title>{HomePage.showMocks[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{HomePage.name[lang]}</th>
                                <th>{HomePage.desc[lang]}</th>
                                <th>{HomePage.onlyfile[lang]}</th>
                                <th>{HomePage.edit[lang]}</th>
                                <th>{HomePage.delete[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mocks?.map((mock) => {
                                    return (
                                        <tr key={mock?._id}>
                                            <td>{mock?.name}</td>
                                            <td>
                                                {/* Show only the first 10 characters of the description */}
                                                {mock?.description?.slice(0, 10)}...
                                                {/* Clickable span to show full description */}
                                                <span
                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                    onClick={() => handleShowFullDescription(mock?.description)}>
                                                    {HomePage.learnMore[lang]}
                                                </span>
                                            </td>
                                            <td>
                                                {/* <ShowFileMock mockFile={`${urlBase}/${mock?.file}`} /> */}
                                                {mock?.file ? (
                                                    <ShowFileMock mockFile={`${urlBase}/${mock?.file}`} />
                                                ) : <p>{HomePage.noFile[lang]}</p>}
                                            </td>
                                            <td>
                                                <UpdateMock mock={mock} materialId={materialId} />
                                            </td>
                                            <td>
                                                <DeleteMock mock={mock?._id} materialId={materialId} />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>

                    {/* Conditionally render the full description in a div */}
                    {selectedDescription && (
                        <div className="full-description-popup" style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            padding: '20px',
                            border: '1px solid #ccc',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            zIndex: 1000
                        }}>
                            <p>{selectedDescription}</p>
                            <Button variant="secondary" onClick={() => setSelectedDescription(null)}>
                                Close
                            </Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.close[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShowMocks;
