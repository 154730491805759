import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { FcFullTrash } from "react-icons/fc";
import { BiMessageDetail } from "react-icons/bi";
import { Delete_Student } from "../../actions/students.js";
import { Current_Product } from "../../actions/product.js";
import NewAccount from "./newAccount/newAccount.jsx";
import { urlBase } from "../../utils/API.js";
import NewRequest from "../curriculums/requests/newRequest.js";
import { HomePage } from "../../translations/trans.js";
import { useNavigate } from "react-router-dom";
import ModalUpdateUser from "./requests/ModalUpdateUser.js";

function Students() {
  const [render, setRender] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const dispatch = useDispatch();
  const { students } = useSelector(mapStateToProps);

  useEffect(() => {
    // Do something on component mount or when render state changes
  }, [render]);

  const handleDeleteStudent = (studentId) => {
    setStudentToDelete(studentId);
    setShowModal(true);  // Show confirmation modal
  };

  const confirmDelete = () => {
    if (studentToDelete) {
      dispatch(Delete_Student(studentToDelete));
      setRender(!render);
      setShowModal(false);  // Close modal after deletion
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const filteredStudents = students?.filter(
    (student) =>
      (student?.username ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student?.email ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student?.phone ?? "").toLowerCase().includes(searchTerm.toLowerCase())
  );


  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={8}>
          <NewAccount type="student" />
        </Col>
        <Col xs={12} md={4}>
          <Form>
            <Form.Group controlId="searchTerm">
              <Form.Control
                type="text"
                placeholder="Search by name, email, or phone"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          {filteredStudents.map((student, index) => (
            <StudentList key={index} student={student} onDelete={() => handleDeleteStudent(student._id)} />
          ))}
        </Col>
      </Row>

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} dir={dir}>
        <Modal.Body>{HomePage.deleteStudent[lang]}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {HomePage.cancel[lang]}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {HomePage.yes[lang]}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

function StudentList({ student, onDelete }) {
  const personalImage = student?.personalImage ? student?.personalImage : null;
  const imageLink = personalImage ? `${urlBase}/uploads/${personalImage}` : "";
  const dispatch = useDispatch();
  const { loggedUser } = useSelector(mapStateToProps);
  const navigate = useNavigate();

  const handleViewMessages = () => {
    dispatch(Current_Product(student));
    navigate(`/user/${loggedUser.user._id}/messages`);
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  // console.log(student)

  return (
    <Row className="mb-3">
      <Col xs={2} md={1}>
        <img src={imageLink} alt='' className="img-fluid" />
      </Col>
      <Col xs={12} md={12}>
        <h5>Username: {student?.username}</h5>
        <p>Email: {student?.email}</p>
        <p>Code: {student?.code}</p>
        <p>Phone: {student?.phone}</p>
        <p>Registeration Date: {student?.date?.split("T")[0]}</p>
        <h5>{HomePage.infoParent[lang]}</h5>
        <p>Parent Name: {student?.parentName}</p>
        <p>Parent Phone{student?.parentPhone}</p>
      </Col>
      <Col xs={2} md={2}>
        <Button style={{ width: '50px' }} variant="danger" onClick={onDelete}>
          <FcFullTrash size="20px" />
        </Button>
      </Col>
      <Col xs={2} md={2}>
        <Button style={{ width: '50px' }} variant="info" onClick={handleViewMessages}>
          <BiMessageDetail size="20px" />
        </Button>
      </Col>
      <Col xs={2} md={2}>
        <NewRequest userID={student?._id} valid={true} />
      </Col>
      <Col xs={2} md={2}>
        <ModalUpdateUser student={student} type="admin"/>
      </Col>
    </Row>
  );
}

function mapStateToProps({ users, loggedUser }) {
  const students = users?.students;

  return {
    students,
    loggedUser,
  };
}

export default Students;
