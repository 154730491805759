

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { HomePage } from '../../translations/trans';
import SubmitAnswer from './SubmitAnswer';
import { getAnswerStudent, handleMockForStd } from '../../utils/mock';
import ViewAnswer from './ViewAnswer';
import { urlBase } from '../../utils/API';

const MocksStd = ({ materialCode }) => {
    const [show, setShow] = useState(false);
    const [mocks, setMocks] = useState([]);
    const [selectedDescription, setSelectedDescription] = useState(null); // To handle full description
    const [singleMock, setSingleMock] = useState(null);
    const [mockAnswers, setMockAnswers] = useState({}); // Store answers for each mock

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state) => state.loggedUser);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    const [showSubmitModal, setShowSubmitModla] = useState(false);
    const handleCloseSubmitmodal = () => setShowSubmitModla(false);

    const openSubmitAnswerModal = (mock) => {
        handleClose();
        setShowSubmitModla(true);
        setSingleMock(mock);
    };

    const handleGetMaterial = async () => {
        try {
            // const res = await getMaterialByCode({ code: materialCode });
            const res = await handleMockForStd(materialCode, loggedUser?.user?._id);
            if (res?.data?.case) {
                setMocks(res?.data?.mocks);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Fetch answers for each mock
    const getStudentAnswerMock = async (mockId) => {
        try {
            const res = await getAnswerStudent(mockId, loggedUser?.user?._id);
            setMockAnswers((prevAnswers) => ({
                ...prevAnswers,
                [mockId]: res?.data?.mockAnswers, // Assuming res.data contains the mock results
            }));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleGetMaterial();
    }, [materialCode, show]);

    useEffect(() => {
        mocks.forEach((mock) => {

            getStudentAnswerMock(mock._id);
        });
    }, [mocks, show]);


    // Function to handle showing the full description in a small div
    const handleShowFullDescription = (description) => {
        setSelectedDescription(description);
    };


    const [showViewAnswerModal, setShowViewAnswerModal] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);

    const handleCloseViewAnswerModal = () => setShowViewAnswerModal(false);

    const openViewAnswerModal = (answer, index) => {
        handleClose();
        setSelectedAnswer(answer);
        setSelectedAnswerIndex(index);
        setShowViewAnswerModal(true);
    };




    return (
        <div>
            <div>
                <button
                    className='mx-3'
                    onClick={handleShow}
                    style={{ background: "white", color: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}
                >
                    {HomePage.mocks[lang]}
                </button>

                <Modal show={show} onHide={handleClose} centered size='xl' className='text-center' dir={dir}>
                    <Modal.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{HomePage.mocks[lang]}</th>
                                    <th>{HomePage.onlyfile[lang]}</th>
                                    <th>{HomePage.desc[lang]}</th>
                                    <th>{HomePage.viewAnswers[lang]}</th>
                                    <th>{HomePage.addAnswer[lang]}</th>
                                    <th>{HomePage.lastState[lang]}</th>
                                    <th>{HomePage.lastMark[lang]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mocks?.map((mock, index) => {
                                        return (
                                            <tr key={mock?._id}>
                                                <td>{index + 1}</td>
                                                <td>{mock?.name}</td>
                                                <td>
                                                    {
                                                        mock?.file ? (
                                                            <a href={`${urlBase}/${mock?.file}`} target="_blank" rel="noreferrer">PDF</a>
                                                        ) : (<p>{HomePage.noFile[lang]}</p>)
                                                    }
                                                    {/* <a href={`${urlBase}/${mock?.file}`} target="_blank" rel="noreferrer">PDF</a> */}
                                                </td>
                                                <td>

                                                    {mock.description?.slice(0, 10)}...

                                                    <span
                                                        style={{ color: 'blue', cursor: 'pointer' }}
                                                        onClick={() => handleShowFullDescription(mock.description)}
                                                    >
                                                        {HomePage.learnMore[lang]}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle style={{ backgroundColor: "#1F3662", color: "white" }} id="dropdown-basic">
                                                            {HomePage.viewAnswers[lang]}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ border: "none" }}>
                                                            {
                                                                mockAnswers[mock._id]?.length > 0 ? (
                                                                    mockAnswers[mock._id].map((answer, i) => (
                                                                        <Dropdown.Item
                                                                            key={i}
                                                                            onClick={() => openViewAnswerModal(answer, i)}
                                                                        >
                                                                            <div
                                                                                style={{

                                                                                    backgroundColor:
                                                                                        answer?.state === "pending"
                                                                                            ? "#6c757d"
                                                                                            : answer?.state === "cancelled"
                                                                                                ? "#dc3545"
                                                                                                : answer?.state === "submitted"
                                                                                                    ? "#28a745"
                                                                                                    : "#007bff",
                                                                                    color: "white",
                                                                                    padding: "5px",
                                                                                    borderRadius: "5px"
                                                                                }}
                                                                            > {HomePage.viewAnswers[lang]}{i + 1}
                                                                                <p>{HomePage.marks[lang]}:
                                                                                    {answer?.marks === null ? HomePage.noMarks[lang] : answer?.marks}</p>
                                                                            </div>

                                                                        </Dropdown.Item>
                                                                    ))
                                                                ) : (
                                                                    <Dropdown.Item>{HomePage.notRes[lang]}</Dropdown.Item>
                                                                )
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>

                                                <td>
                                                    <button
                                                        className='btn'
                                                        onClick={() => { openSubmitAnswerModal(mock) }}
                                                        style={{ background: "#6B8F04", color: "white" }}
                                                    >
                                                        {HomePage.addAnswer[lang]}
                                                    </button>
                                                </td>
                                                <td>
                                                    {mock?.lastState}
                                                </td>
                                                <td>
                                                    {mock?.lastMarks}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>

                        {/* Conditionally render the full description in a div */}
                        {selectedDescription && (
                            <div className="full-description-popup" style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white',
                                padding: '20px',
                                border: '1px solid #ccc',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                zIndex: 1000
                            }}>
                                <p>{selectedDescription}</p>
                                <button
                                    className='btn btn-secondary'
                                    onClick={() => setSelectedDescription(null)}
                                >
                                    {HomePage.close[lang]}
                                </button>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
            <SubmitAnswer show={showSubmitModal} handleClose={handleCloseSubmitmodal} singleMock={singleMock} />
            <ViewAnswer
                show={showViewAnswerModal}
                handleClose={handleCloseViewAnswerModal}
                answer={selectedAnswer}
                index={selectedAnswerIndex}
            />
        </div>
    );
};

export default MocksStd;

