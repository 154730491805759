import React from 'react'


function Input({ id, type, placeholder, setValue }) {
    return (
      <input
        className="form-group__input"
        type={type}
        id={id}
        placeholder={placeholder}
        // onChange={(event)=>setValue(event.target.name,event.target.value)}
        name={id}
        required
      />
    );
  }
export default Input