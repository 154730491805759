import React from 'react'
import { useSelector } from "react-redux";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import ListRow from "../../styled-components/listRow";
import NewAccount from ".././newAccount/newAccount";
import {FcFullTrash} from "react-icons/fc"
// import {Inetialize_Ads} from "../../../actions/login&register.js"
function Accounts() {
      const { accounts } = useSelector(mapStateToProps);
  return (
    <Contain perfix="teacher-admin-page" height='100%'>
    <Col fg='1' dir='column'>
    <NewAccount type='admin'/>
    <Col width={"80%"} dir="column" align={"flex-start"}>
    <div className="scoll-overflow" style={{ width: "100%" }}>
      {accounts.map((t, i) => (
     <List key={i} item={t} 
     icon={<FcFullTrash 
        size='30px' 
        onClick={()=>{
            // dispatch(Delete_Teacher(t._id));
            //  setRender(!render)
            }}  style={{ 
     // top: "10px",
     right: "5px",
     cursor: "pointer",}}/>}/>))}
      </div>
    </Col>
    </Col>
  </Contain>

  )
}
function List({item,icon}){
   
      return(
        <>
        <ListRow two={item.username} three={item.email} four={item.date.split('T')[0]} icon={icon}/>
        </>
      )
  }
function mapStateToProps({ admins }) {
  const accounts=admins
    return {
      accounts
    };
  }
export default Accounts;