import React from 'react'
import "./listRow.css";
import {useLocation} from 'react-router-dom';
import GetText from '../translation/getText';
function ListRow({item,one,two,three,four,five,icon}) {

  const location = useLocation();
  console.log(location.pathname)
  return (
    <GetText>
    <div className='list-cont'>
        
        <article className="leaderboard__profile">
      {one?<img src={one} alt={two} className="leaderboard__picture"/>:<div className='leaderboard__picture'></div>}
      <span className="leaderboard__name" id={location.pathname.split('/').join('')+'2'}>{two}</span>
      <span className="leaderboard__value" ><span id={location.pathname.split('/').join('')+'3'}>{three}</span></span>
      <span className="leaderboard__name" id={location.pathname.split('/').join('')+'4'}>{four}</span>
      <span className="leaderboard__name" id={location.pathname.split('/').join('')+'5'}>{five}</span>
      <span className="leaderboard__name" id={location.pathname.split('/').join('')+'6'}>{icon}</span>
      
    </article>

    </div>
    </GetText>
  )
}


export default ListRow
