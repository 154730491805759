import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { urlBase } from '../../../utils/API';
import { createLesson } from '../../../utils/lessons';
import { BoxLoading } from 'react-loadingg';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
// import S3FileUpload from 'react-s3';
// import { uploadFile } from 'react-s3';

function AddLesson({ materialCode }) {

  //   const config = {
  //     bucketName: 'osbash-demo1',
  //     // dirName: '', /* optional */
  //     region: 'me-central-1',
  //     accessKeyId: 'AKIA5LSNJPKWPMFJUFTN',
  //     secretAccessKey: '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'
  // }

  // ------------
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lessonName, setLessonName] = useState('');

  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [pdf, setPdf] = useState(null);

  const [imageUrl, setImageUrl] = useState();
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0); // State to handle upload progress

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLessonNameChange = (e) => {
    setLessonName(e.target.value);
  };
  //-------
  //  const uploadReact_S3= (file)=>{
  //   S3FileUpload.uploadFile(file,config).then(data=>{
  //     console.log(data)
  //   })
  //   .catch(err=>console.log(err))
  //  }
  //-------
  const s3 = new S3Client({
    region: 'me-central-1',
    credentials: {
      accessKeyId: 'AKIA5LSNJPKWPMFJUFTN',
      secretAccessKey: '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'
    }
  });
  //  const uploadingImageOfLesson = (data) => {
  //   console.log('data',data)
  //   return axios({
  //     method:'POST',
  //     url:`${urlBase}/api/upload-image`,
  //     data:data,
  //     onUploadProgress:(progressEvent)=>{
  //       const percentage = Math.round(
  //         (progressEvent.loaded * 100) / progressEvent.total
  //       );
  //       setProgress(percentage)

  //     }
  //   }) 
  //     }

  const handleFileChange = (type) => {
    console.log(type)
    switch (type) {
      case 'image':
        setMessage('Uploading image...');
        break;
      case 'video':
        setMessage('Uploading video...');
        break;
      case 'pdf':
        setMessage('Uploading PDF...');
        break;
      default:
        setMessage('Uploading...');
    }

    // You may want to handle the file upload process here
    // For now, let's assume the file upload happens instantly
  };

  //-------------
  //                                 LOCAL UPLOAD ENDPOINT
  const uploadingFileLocally = (data) => {
    const formData = new FormData();
    formData.append("file", data)
    return axios({
      method: "POST",
      url: `${urlBase}/api/upload-file-locally`,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentage)
      }
    })
  }
  //-------------



  const handleSaveChanges = async () => {
    try {
      setLoading(true);

      let content = [];

      if (image) {
        // await AWSUpload(image)
        handleFileChange(image.type)
        const res = await uploadingFileLocally(image)
        // await uploadReact_S3(image);
        content.push({ name: `${res.data?.data?.name}`, type: image.type, source: res.data.data.source });

      }
      if (video) {

        handleFileChange(video.type)
        const res = await uploadingFileLocally(video)

        content.push({ name: `${res.data?.data?.name}`, type: video.type, source: res.data.data.source });

      }
      if (pdf) {

        handleFileChange(pdf.type)
        const res = await uploadingFileLocally(pdf)

        content.push({ name: `${res.data?.data?.name}`, type: pdf.type, source: res.data.data.source });

      }

      const lessonData = {
        name: lessonName,
        materialCode,
        content,
      };

      const response = await createLesson(lessonData);
      setMessage(response.data.message);
      setLoading(false);

      handleCloseModal();
      alert(response.data.message);
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message);
      setLoading(false);
    }
  };
  // const handleSaveChanges = async () => {
  //   try {
  //     setLoading(true);

  //     let content = [];

  //     if (image) {
  //       handleFileChange('image', image);
  //       const formData = new FormData();
  //       formData.append('file', image);
  //       const res = await uploadingImageOfLesson(formData);
  //       content.push({ name: res.data.data.name, type: res.data.data.type });
  //     }
  //     if (video) {
  //       handleFileChange('video', video);
  //       const formData = new FormData();
  //       formData.append('file', video);
  //       const res = await uploadingImageOfLesson(formData);
  //       content.push({ name: res.data.data.name, type: res.data.data.type });
  //     }
  //     if (pdf) {
  //       handleFileChange('pdf', pdf);
  //       const formData = new FormData();
  //       formData.append('file', pdf);
  //       const res = await uploadingImageOfLesson(formData);
  //       content.push({ name: res.data.data.name, type: res.data.data.type });
  //     }

  //     const lessonData = {
  //       name: lessonName,
  //       materialCode,
  //       content,
  //     };

  //     const response = await createLesson(lessonData);
  //     setMessage(response.data.message);
  //     setLoading(false);

  //     handleCloseModal();
  //     alert(response.data.message);
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setMessage(error.message);
  //     setLoading(false);
  //   }
  // };


  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  return (
    <div>
      <Button variant="primary" onClick={handleOpenModal}>
        {HomePage.addLesson[lang]}
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} dir={dir}>
        <Modal.Header closeButton>
          <Modal.Title>{HomePage.addNewLesson[lang]}</Modal.Title>
          <div>{message}</div>
        </Modal.Header>
        <Modal.Body>
          <div dir={"ltr"}>{progress}% uploaded / {message}</div>
          {!loading ? (
            <>
              <Form.Group controlId="lessonName">
                <Form.Label>{HomePage.nameLesson[lang]}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ادخل اسم الدرس"
                  value={lessonName}
                  onChange={handleLessonNameChange}
                  multiple={false}
                />
              </Form.Group>

              <Form.Group controlId="lessonFile">
                <Form.Label>{HomePage.uploadImage[lang]}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => setImage(event.target.files[0])}
                />
              </Form.Group>

              <Form.Group controlId="lessonFile">
                <Form.Label>{HomePage.uploadVideo[lang]}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => setVideo(event.target.files[0])}
                />
              </Form.Group>

              <Form.Group controlId="lessonFile">
                <Form.Label>{HomePage.uploadPdf[lang]}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => setPdf(event.target.files[0])}
                />
              </Form.Group>
<br/>
           
            </>
          ) : (
            <BoxLoading />
          )}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleSaveChanges}>
                {HomePage.addLesson[lang]}
              </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddLesson;
