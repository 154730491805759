import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import UploadHomeWork from './UploadHomeWork';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';
import ViewHw from './ViewHw';
import { useParams } from 'react-router-dom';
import { getSession } from '../../utils/sessions';
const HomeWork = ({ lesson }) => {
    const [show, setShow] = useState(false);
    const [showHW, setShowHW] = useState(false);
    const [uploadHW, setUploadHW] = useState(false);
    const [sessionData, setSession] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowHW = () => setShowHW(true);
    const handleUploadHW = () => setUploadHW(true);
    const loggedUser = useSelector((state) => state.loggedUser);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [sessions, setSessions] = useState([]);



    const { leesson } = useSelector(({ view }) => {
        return { lesson: view?.lesson };
    });

    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", sessions)

    const handleViewHW = (session) => {
        setSession(session)
        handleShowHW();
        handleClose()
    }

    const handleHW = (session) => {
        setSession(session)
        handleUploadHW();
        handleClose()
    }

    const params = useParams();
    const getAllSessionForCalss = async () => {
        try {
            const res = await getSession({
                classId: params?.classId,
                lesson: lesson ? lesson?._id : leesson?._id
            });
            // console.log(res)
            if (res?.data?.case) {
                setSessions(res?.data?.sessions)
            }
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        if (show) {
            getAllSessionForCalss()
        }
    }, [params?.classId, lesson, show])

    return (
        <div>
            <button className=' mx-3' onClick={handleShow} style={{ background: "white", color: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>
                {HomePage.homeWork[lang]}
            </button>

            <Modal show={show} onHide={handleClose} centered size='xl' dir={dir}>
                <Modal.Body>
                    {sessions?.some(session => session?.lesson?._id === lesson._id) ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>{HomePage.sessionName[lang]}</th>

                                    <th>{HomePage.homeWork[lang]}</th>
                                    <th>{HomePage.uploadHW[lang]}</th>
                                    <th>{HomePage.viewHw[lang]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessions
                                    ?.filter(session => session?.lesson?._id === lesson._id)
                                    ?.map((session) => {
                                        return (
                                            <tr key={session._id}>
                                                <td>{session?.note}</td>
                                                <td>
                                                    {session?.homeWorkTask}
                                                </td>
                                                <td>
                                                    {
                                                        session?.homeWork?.some(hw => hw?.student?._id === loggedUser.user?._id)
                                                            ?
                                                            <button className='btn' style={{
                                                                background: "rgb(219, 234, 255)"
                                                                , color: " rgb(65, 105, 226)"
                                                            }}>{HomePage.alreadyUpload[lang]}</button>
                                                            : (
                                                                <button className='mx-3' onClick={() => handleHW(session)}
                                                                    style={{ color: "white", background: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>
                                                                    {HomePage.uploadHW[lang]}
                                                                </button>
                                                                // <UploadHomeWork session={session} />
                                                            )
                                                    }
                                                </td>
                                                <td>
                                                    <button className='mx-3' onClick={() => handleViewHW(session)}

                                                        style={{ color: "white", background: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>
                                                        {HomePage.viewHW[lang]}

                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </Table>
                    ) : (
                        <h1>{HomePage.notSessionRecord[lang]}</h1>
                    )}
                </Modal.Body>
            </Modal>
            <ViewHw session={sessionData} show={showHW} handleClose={() => { setShowHW(false) }} />
            <UploadHomeWork session={sessionData} show={uploadHW} handleClose={() => { setUploadHW(false) }} />
        </div>
    );
}

export default HomeWork;
