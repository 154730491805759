import { CREATE_LEVELE,DELETE_LEVEL,INETIALIZE_LEVELS,EDIT_LEVEL } from "../actions/curriculums/levels";

export const levels = (state=[],action)=>{
    switch (action.type){
        case CREATE_LEVELE:
            return state = [...state,action.level]
        case INETIALIZE_LEVELS :
            return state = action.levels
        case DELETE_LEVEL:
            return state = state.filter(l=>l._id!==action.id)
        case EDIT_LEVEL:
            return state = state.map(s=>{
                if(s._id===action.level._id){
                    return action.level
                }
                return s
            })
        default: return state
    }
}