import { addMock, deleteMockApi, getMocksApi, updateSingleMock } from "../utils/mock";


// Types
export const ADD_NEW_MOCK = 'ADD_NEW_MOCK';
export const GET_ALL_MOCKS = "GET_ALL_MOCKS"
export const UPDATE_MOCK = "UPDATE_MOCK"
export const DELETE_MOCK = "DELETE_MOCK"

// Actions
const newMock = (mock,materialId) => {
    return {
        type: ADD_NEW_MOCK,
        mock,
        materialId
    }
}

const getMocks = (mocks) => {
    return {
        type: GET_ALL_MOCKS,
        mocks
    }
}

const updateMock = (mock,materialId) => {
    return {
        type: UPDATE_MOCK,
        mock,
        materialId
    }
}
const deleteMock = (id,materialId) => {
    return {
        type: DELETE_MOCK,
        id,
        materialId
    }
}


// Dispatches
// Create Mock
export const New_Mock = (data, id, userId) => {
    return dispatch => addMock(data, id, userId).then(res => {
        console.log(res)
        if (res.data.case === true) {
            dispatch(newMock(res.data.mock , id))
        }
        return res
    })
}

// Get Mocks



export const Get_Mocks = (materialId) => {
    return dispatch => getMocksApi(materialId).then(res => {
        console.log(res)
        if (res.data.case === true) {
            dispatch(getMocks(res?.data?.mocks))
        }
        return res
    })
}

// Update
export const Update_mock = (data,id,materialId, userId) => {
    return dispatch => updateSingleMock(data,id, userId).then(res => {
        console.log(res)
        if (res.data.case === true) {
            dispatch(updateMock(res?.data?.mock , materialId))
        }
        return res
    })
}


// Delete 
export const Delete_Single_Mock = (id,materialId, userId) => {
    return dispatch => deleteMockApi(id, userId).then(res => {
        console.log(res)

        dispatch(deleteMock(id , materialId))

        return res
    })
}
