import OLP_Logo from
  "./assets/images/logo.png"
//'./assets/olp-logo.png';
import OLP_White_Logo from './assets/images/logo.png'//'./assets/logo-white.png';
import Hero_Section_Image from './assets/home-choose.jpeg'//"./assets/herortl.png"
import Sign_Image from
  './assets/images/loginImage.png'
import Login_Image from
  './assets/images/registerImage.png'
import statisticsImage from "./assets/about.png"

//"./assets/logo-white.png"
export const platformName = "Sara Physics"
const year = new Date().getFullYear();
export const Rights_Text = `جميع حقوق الملكية محفوظة لمنصة SaraPhysics @${year}`
export const Rights_Text2 = `
 SaraPhysics    @ Copyright ${year}. All Rights Reserved
`
//" OLP.OSBASH © Copyright 2023. All Rights Reserved";
export const { whatsapp, phone, email } =
{
  whatsapp: "+201020088624" //"+201551661850"
  , phone: "+201020088624",
  email: "saraphysi@gmail.com"

  //'info@osbash.com'
};


export const whatsappNum = "+201020088624"

export const Wallet_Number = "+201020088624"
export const hero_section_title = `
نوفر بيئة تعليمية متكاملة تدعم الطالب في كل خطوة، بدءًا من تخطيط الدروس وحتى تقييم أداء الطلاب، لضمان تقديم تعليم ذو جودة عالية ونتائج مميزة.
`

export const second_hero_section_title = `

 تتيح المنصة  الأدوات والموارد اللازمة لجعل تعليم الفيزياء أكثر تفاعلًا وفهمًا للطلاب. من خلال دمج التكنولوجيا الحديثة وأساليب التدريس الفعّالة، 
`

// "منصة OLP  التعليمية مقدمة من شركة اوسباش كخطوة حقيقية للمساهمة فى التحول الرقمى للعملية التعليمية"
export const join_Sec_Title = `انضم الى منصة Sara Physics الآن وطور مهاراتك كمعلم`
export const join_Sec_Text = `منصة Sara Physics 
منصة لكل المُعلمين تابعة لمنظمة منتدى التربويين ،تم إنشاؤها في 2024 
تهدف إلى تدريب المُعلمين وتهيأتهم إلى إدارة الصف الدراسي بكفاءة عالية وإستغلال كل الإمكانات التربوية والعلمية والتكنولوجية لتحقيق عملية التعلم ونقل العلم بكفاءة عالية ونستهدف من خلال المنصة كل من هو مهتم بمجال التدريس سواء اثناء دراسته الأكاديمية أو أثناء ممارسته لمهنة التدريس بشكل عملي`

export const start_Sec_title = `ابدأ الآن مع Sara Physics`;
export const start_Sec_Text = `سجل الدخول - احجز البرنامج التعليمى المناسب لك - ابدا التعلم
 واضمن النجاح والشهرة كمعلم.`
export const footerText = `نهدف إلى رفع كفائة المُعلمين وتعزيز قدراتهم من عدة جوانب "مهارية ،علمية ،تربوية" كما نهدف ايضًا إلى تأهيلهم لسوق العمل وتزويد خبراتهم المهنية ومساعدتهم على مواكبة التطورات الرقمية المستمرة التي تطرأ على مجال التعليم`
export { OLP_Logo, OLP_White_Logo, Hero_Section_Image, Sign_Image, statisticsImage, Login_Image };