import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import NavButton from "../styled-components/navButton";
import VideoPlayer from "../videoPlayer";
import "./card.css";
import {urlBase} from "../../utils/API"

function TeacherVideo({ user, index,margin,onClick }) {
  // const imageUrl =user.personalImage && require(`${user.personalImage}`);
  // const videoUrl =user.personalVideo && require(`${user.personalVideo}`);
  const [image, setImage] = useState(true);
  const mouseOver = () => {
    setImage(false);
  };
  const mouseOut = () => {
    setImage(true);
  };

  useEffect(() => {
    // mouseOver()
    const card = document.getElementById(index);
    card.addEventListener("mouseover", () => mouseOver());
    card.addEventListener("mouseout", () => mouseOut());
    return ()=>{
      card.removeEventListener("mouseover", () => mouseOver());
      card.removeEventListener("mouseout", () => mouseOut());

    }
  }, []);
console.log(user)
  return (
    <>
      <div className="card" style={{margin:margin}}>
        <div className="card-container" id={index}>
          <div className="vid-cont">
            {!image
              ? user.personalVideo && (
                  <VideoPlayer link={user.personalVideo} height="220px" width='100%'/>
                )
              : user.personalImage && (
                  <img src={user.personalImage} alt="Avatar" width="100%" />
                )}
          </div>
        </div>
        <div className="card-btn">
          <p id='tv1'>{user.username}</p>
          <button onClick={()=>onClick(user)} id='tv2'>Book now</button>
          {/* <NavButton name="Book now" to="/booking" bgColor="#293476" width="150px" /> */}
        </div>
      </div>
    </>
  );
}
export default TeacherVideo;
