import React, { useState, useRef } from 'react'
import { ResetPassRequest, verifyCodeRequest } from '../../utils/API';
import resetImage  from '../../assets/reset-pass.png'
import './resetPass.css';
import Header from '../userDashboard/Header.js';
import BlueHeader from '../publicPages/blueHeader.js'
import Footer from '../Home/footer'
import { BoxLoading,WaveLoading,SolarSystemLoading		 } from 'react-loadingg';

function ResetPass() {

  const [userEmail,setUserEmail]=useState(null);
  const [loading,setLoading]= useState(false);
  
  const OTP_Code = useRef(null);
  const pass1 = useRef(null);
  const pass2 = useRef(null);
  const [OTPResponse, setOTPResponse] = useState(null)
  const [OTPResponseStatus, setOTPResponseStatus] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false)
  const Sending_OTP_To_Email = (e) => {

    e.preventDefault();
    setLoading(true)
    // clearTimeout(removeResponseMessage);
    ResetPassRequest({ email: userEmail }).then(res => {
      console.log(res.status)
      setLoading(false)
      setOTPResponse(res.data);
      if (res.status === 200) {
        setOTPResponseStatus(true);
      }
      else {
        setOTPResponseStatus(false)
      }
      setTimeout(() => {
        setOTPResponse(null)
      }, 10000)        // console.log(res.data)
    }).catch((e) => {
      console.log(e)
      setLoading(false)
      setOTPResponse('server error '+ e.message)
      console.log(e.message)
    })

  }

  //  send Verification code {OTP}

  const verifyCode = (e) => {
    e.preventDefault();
    setLoading(true)
    if (pass1.current.value !== pass2.current.value || pass1.current.value === null || pass2.current.value === null) {
     setLoading(false)
      return setOTPResponse("password1 doesn't matching password2")
    }

    if (OTP_Code.current.value && userEmail && pass1.current.value === pass2.current.value) {
      verifyCodeRequest({ code: OTP_Code.current.value, email: userEmail, newPassword: pass1.current.value }).then(res => {
       setLoading(false)
        if (res.status === 200) {
          setOTPResponse(res.data)
          console.log('password changed successfully')
        } else {
          setOTPResponse('Error:'+res.data)
          setShowPasswordForm(false);
          console.log(res)

        }
      }).catch(e=>{console.log(e)
           setLoading(false)
           setOTPResponse("server error "+e.message)
      })
    }

  }





  return (
    <>
     <Header />
<BlueHeader title='Reset Password'/>
    <div className='reset-pass-cont'>
     
<img src={resetImage} alt='reset-password'/>

      <div className='reset-forms'>
       {loading? <div className='overlay-loading'><SolarSystemLoading/></div>
:null
}
      {!OTPResponseStatus?
        <form onSubmit={Sending_OTP_To_Email} >
          <input required type='email' onChange={(event)=>setUserEmail(event.target.value)} placeholder='Insert your email' />
          <input type='submit' value='Send Reset Code' />
          <div >
            <h3>{OTPResponse}</h3>
          </div>
        </form>
        :
          <>
            <form onSubmit={verifyCode}>
              <div><span>
                Check your email, Verification code was sent to your email.
              </span>
              </div>
              <input type='text' placeholder='Insert Verification code' ref={OTP_Code} />
              <input type='password' placeholder='New password' ref={pass1} />
              <input type='password' placeholder='Retype new password' ref={pass2} />
              <input type='submit' value='send' />
              <div><span>
                {OTPResponse}
                </span></div>
            </form>
          </>
        }
      </div>    </div>
      <Footer/>
      </>
  )
}

export default ResetPass