import React, { useEffect, useState, useMemo, useCallback } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

// import Contain from "../styled-components/contain";
import { useSelector, useDispatch } from "react-redux";
import NavButton from "../styled-components/navButton";
import { convertArrToObj, convertObjToArr } from "../globalFunc";
import { Booking_Results } from "../../actions/booking.js";
import { getDateXDays, getBusyHours } from "../globalFunc"; // getBusyHours(teacherAppointments,packageName,subCategory)
// import ShowDetails from "./showDetails";
import ChooseStart from "./chooseStart";
import {getMonthAfter} from "../globalFunc";
import {groupIntersection} from "../globalFunc";
import GetText from '../translation/getText';
function ChooseDate() {
  //
  const [message, setMessage] = useState(null);
  //days array
  const {lang} = useSelector(mapStateToProps)
  // console.log(lang)
  const days =lang==='en'? [
    { name: "Sunday" },
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
  ]:[
    { name: "الاحد" },
    { name: "الاثنين" },
    { name: "الثلاثاء" },
    { name: "الاربعاء" },
    { name: "الخميس" },
    { name: "الجمعه" },
    { name: "السبت" },
  ];
  // get hours {from => to}
  const getHouers = (from, to) => {
    let hours = [];
    const start = parseInt(from);
    const end = parseInt(to);
    for (let i = start; i < end; i++) {
      hours.push(i);
    }
    return hours;
  };

  //----- delete array elements from other array

  const diffrence = (arr1, arr2) => {
    return arr1.filter((a) => !arr2.includes(a));
  };
  //----------------------------------------------------------------
  const dispatch = useDispatch();
  //-----------------
  // get  choosen packag and teacher from redux store.
  const { packag, teacher, packagDays,user } = useSelector(mapStateToProps);
// console.log('package',packag);
  // available houres per day----------> from result of current calculations.
  const [hours, setHours] = useState([]); // available hours per day
  //----------------------------------------------------------------
  //---------------------- get teacher days as package--------------
  const TeacherDaysAsPackages = (teacher, packag) => {
    const schedual = teacher.schedual;
    const requiredDays = [];
    packag.days.forEach((day) => {
      schedual[day] && requiredDays.push(day);
    });

    return requiredDays.length === packag.days.length ? requiredDays : null;
  };
  //-----------------getBreaks hours array of teacher for on day---------------
  const breaksHours = (breaks) => {
    const breaksNum = breaks.from.length;
    let allBreaksArr = [];
    for (let i = 0; i < breaksNum; i++) {
      allBreaksArr = [
        ...allBreaksArr,
        ...getHouers(breaks.from[i], breaks.to[i]),
      ];
      // console.log('jj',getHouers(breaks.from[i], breaks.to[i]))
    }
    return allBreaksArr;
  };
  //----------------------------------------------------------------
  //---------get available hours array of teacher in one day--------------

  const availableHours = (avail) => {
    //avail parameter from teacher.schedual.avail
    const start = parseInt(avail.from);
    const end = parseInt(avail.to);
    return getHouers(start, end);
  };

  //----------------------------------------------------------------
  //----------------- Days of course package in next week-------------------
  const daysOfCourse = (packag) => {
    const days = packag.days ? packag.days : [];
    const result = days.map((d, i) => {
      // days of starting course
      let newDay;
      for (let j = 1; j <= 7; j++) {
        const newD = getDateXDays(j);
        if (newD.getDay() === parseInt(d)) {
          newDay = { [d]: newD, index: d };
        }
      }
      return newDay;
    });
    return convertArrToObj(result, "index");
  };
  //-----------------------------------------------------------------
  //------------ get diffrence between breaks and available hours---

  const getTotalHoures =useCallback( (avail, breaks) => {
    const availArr = availableHours(avail); // available hours as array
    // console.log(availArr);

    const breaksArr = breaksHours(breaks); // breaks hours as array

    return diffrence(availArr, breaksArr); // available houres not incuded breaks hours for one day
  },[availableHours, breaksHours]);
  //---------------------------------------------------------------------
  //-------------- available houres object , keys is days inexes , values is available hours
  const busyHours = getBusyHours(teacher.appointments, packag.packag, packag.name)
  const finalAvail = useCallback(
    (teach, packag) => {
      try {
        const teacher = teach;
        const finalArr = [];
        if (TeacherDaysAsPackages(teacher, packag) === null) return [];
        else {
          const nextWeekDays = daysOfCourse(packag);
          const schedualArr = convertObjToArr(teacher.schedual);
          TeacherDaysAsPackages(teacher, packag).forEach((t) => {
            const [day] = schedualArr.filter((d) => d.index == t);
            const busyFrom = busyHours[t]
              ? busyHours[t].map((b) => parseInt(b))
              : [];
            const busyTo = busyFrom.map((b) => b + 1); // b+1 beacuse session time one hour.
            let newFrom = [...day.breaks.from, ...busyFrom];
            let newTo = [...day.breaks.to, ...busyTo];
            day.breaks.from = newFrom;
            day.breaks.to = newTo;
            finalArr.push({
              hours: getTotalHoures(day.avail, day.breaks),
              index: day.index,
              ...nextWeekDays[day.index],
            });
          });
        }
        return finalArr;
      } catch (e) {
        console.log(e.message);
      }
    },
    [busyHours, getTotalHoures]
  );

  // console.log(packag);
  // console.log(getBusyHours(teacher.appointments,packag.packag,packag.name))
  //----------------------------------------------------------------
  const final = useMemo(() => finalAvail(teacher, packag)
  , [finalAvail, teacher, packag]);
  // console.log("final:", final);
  useEffect(() => {
    try {
      if (!teacher) {
        setMessage("Please choose a teacher");
      } else if (!packag) {
        setMessage("Please choose a package");
      }
      if (final.length) {
        setHours(final);
      } else {
        setMessage("This Teacher is not available, please choose another");
      } 
    } catch (err) {
      console.error(err);
      console.log(err);
    } 
  }, [packag]);

  //----------------------------------------------------------------

  //---------------- On Select time by user---------------------------------
  const navigate = useNavigate();
  const [selectedTime, setSelectedTime] = useState(null);
  // const [multiSlection, setMultiSelection] = useState(false); // for multi choice time of booking if true
  
  //--------------- Choose free session day---------
  const [freeSession, setFreeSession] = useState(null);
  //-----------------------------
  const [startDay,SetStart] = useState(null)
  function onTime(e) {
    setSelectedTime(e);
  }

  //  Submition
// console.log(selectedTime)
  const numberOfMonth =parseInt( packag.period)/(parseInt(packag.session)*4)
  const endDate = getMonthAfter(numberOfMonth,startDay?startDay[startDay.index]:null)
  
  const onSubmit = (s, p) => {
      // const state = selectedTime;
      // console.log('p',p)
      if(!startDay) return alert('من فضلك اختر يوم البدا')
      if (s) {
        const details = {
          price: p.price,
          packag:p,
          start:startDay[startDay.index].setHours(s),
          end:endDate
        };
     
        dispatch(Booking_Results(details));
        user.auth? navigate("/booking/confirm") : navigate("/login");
        // navigate("/booking/confirm");
      } else {
        alert("من فضلك اختر توقيت الحصة");
      }
    
  };

  //PM & AM
  const pmAm = (t) => {
    
    if (t > 12) {
      return t - 12 +(lang==='en'?" PM":' م');
    } else {
      return t +(lang==='en'?"AM":"ص");
    }
  };
  // console.log(hours[0]['hours'])
// hours.length&&console.log(groupIntersection(hours,'hours'))
// set active class
const [activeButton, setActiveButton] = useState(null);

  const handleClick = (event) => {
    const button = event.target;
    setActiveButton(button.id);
  }; 
return (
  <GetText>
    <div>
      {/* <Col width={"60%"} dir="column" > */}
        {hours.length && (
          <>
            {/* <ChooseDay/> */}
            <fieldset className='choose-date'>
              <legend id='chdatleg1'>Choose Date of start</legend>
           <ChooseStart lang={lang} hours = {hours} packag={packag} days={days} freeSessionFunc={(h) => {
                    setFreeSession(h[h.index]);
                  }} startDay={(e)=>SetStart(e)}/>
                  </fieldset>

                  <fieldset className='choose-time'>
            <legend id='chdatleg2'>choose session time </legend>
     
            <div className='book-times'>
            {parseInt(packag.price)?groupIntersection(hours,'hours').map((t, i) => (

              <span key={i} 
              id={'booktime'+i}
              className={activeButton === 'booktime'+i ? 'book-time-sp book-time-sp-active' : 'book-time-sp'}
              onClick={(event) => {
                onTime(t)
                handleClick(event)
                }}>
                  {pmAm(t)}
              </span>
              )):<>{startDay?startDay.hours.map((t, i) => (
<span key={i} id={'freetime'+i}
              className={activeButton === 'freetime'+i ? 'book-time-sp book-time-sp-active' : 'book-time-sp'}

onClick={(event) => {onTime(t)
  handleClick(event)
}}>
{pmAm(t)}
</span>
              ))
:null}</>}
            </div>
            </fieldset>

            <div className='book-sub'>
            <button
            id='booksub6'
              className="book-submit-btn"
              onClick={() => onSubmit(selectedTime, packag)}
            >
              
              subscription
            </button></div>
            {/* <NavButton name="Submit" to="" /> */}
          </>
        )}
        {message && (
          <div>
            <h1 id='messh1'> {message}</h1>
            <NavButton name="Click Here" to="/booking" bgColor="#293476" />
          </div>
        )}
     
    </div>
    </GetText>
  );
}
function mapStateToProps({ loggedUser,booking, users,translation }) {
  const packag = booking.package; // booked package details
  const packagDays = packag.days; // days
  const [teacher] = users.teachers.filter((t) => t._id == booking.teacher);
  const user = loggedUser;
  const lang =translation.current
  return {
    packag,
    teacher,
    packagDays,
    user,
    lang
  };
}

export default memo(ChooseDate);
