import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addTestException } from '../../../utils/tests';
import { success } from '../../../utils/Toast';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
const ModalAddException = ({ userId, name, testId }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exception, setException] = useState(null)


    const handleTestException = async () => {
        try {
            const res = await addTestException({
                studentID: userId,
                testID: testId,
                exception: exception
            })
            if (res?.data?.case) {
                success(`تمت الاضافه`)
                handleClose();
            }
        } catch (e) {
            console.log(e)
        }
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                {HomePage.triesNumAdd[lang]}
            </Button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <h4>{HomePage.triesNumAdd[lang]} {name}</h4>
                    <div className='mt-3'>
                        <input type="number" name="testException" id="testException" className='form-control' value={exception} onChange={(e) => setException(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={handleTestException}>
                        {HomePage.add[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddException
