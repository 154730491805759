import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
// import { Add_Record_Manually } from '../../actions/class';
import { ErrorMsg, success } from '../../utils/Toast';
import { Add_Record_Manually } from '../../actions/sessions';
const AddRecordMan = ({
    classData,
    session,
    lessonId
}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const loggedUser = useSelector((state) => state.loggedUser)

    const [codeRec, setCodeRec] = useState(null)
    const [play_url, setPlayUrl] = useState(null)
    const [loading, setLoading] = useState(false)


    const dispatch = useDispatch();

    const addRec = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Add_Record_Manually({
                recordingCode: codeRec,
                play_url: play_url,
                classId: classData?._id,
                sessionId: session?._id
            }, loggedUser?.user?._id))
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <Button style={{ color: "white", background: "#4169E2", fontSize: "13px" }} onClick={handleShow}>
                {HomePage.addRecodd[lang]}
            </Button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header>
                    <Modal.Title>{HomePage.addRecodd[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form >
                            <div>
                                <label>Add Record Code</label>
                                <input type="text" name="recodrCode" id="recodrCode" className='form-control'
                                    value={codeRec}
                                    onChange={(e) => setCodeRec(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Link Record</label>
                                <input type="text" name="linkrec" id="linkrec" className='form-control'
                                    value={play_url}
                                    onChange={(e) => setPlayUrl(e.target.value)} />
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={addRec} disabled={loading}>
                        {loading ? 'Loading...' : HomePage.add[lang]}
                        {/* {HomePage.add[lang]} */}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddRecordMan
