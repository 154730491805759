
export const SET_AVAIL = 'SET_AVAIL';

const setSchedual = (index,key,subKey,value)=>{
    return {
        type: SET_AVAIL,
        index,
        key,
        subKey,
        value
    }
}
export const Set_Schedual = (index,key,subKey,value)=>{
    return(dispatch)=>{
        return dispatch(setSchedual(index,key,subKey,value));
    }
}

export const SET_BREAKS  = 'SET_BREAKS';
const setBreaks = (index,subIndex,key,subKey,value)=>{
    return{
        type: SET_BREAKS,
        index,
        subIndex,
        key,
        subKey,
        value
    }
}
export const Set_Breaks = (index,subIndex,key,subKey,value)=>{
    return(dispatch)=>{
        return dispatch(setBreaks(index,subIndex,key,subKey,value));
    }
}
export const DELETE_BREAK = 'DELETE_BREAK';
 const deleteBreak =(index,subIndex)=>{
    return{
        type: DELETE_BREAK,
        index,
        subIndex
    }
}
export const Delete_Break =(index,subIndex)=>{
    return(dispatch)=>{
            return dispatch(deleteBreak(index,subIndex));
        }
}
export const SET_DAY = 'SET_DAY';
const setDay = (index)=>{
    return{
        type: SET_DAY,
        index
    }
}
export const Set_Day = (index)=>{
    return(dispatch)=>{
        return dispatch(setDay(index));
    }
}
export const DELETE_DAY = 'DELETE_DAY';
const deleteDay = (index)=>{
    return{
        type: DELETE_DAY,
        index
    }
}
export const Delete_Day = (index)=>{
    return(dispatch)=>{
        return dispatch(deleteDay(index));
    }
}
export const INETIALIZE_SCHEDULE = 'INETIALIZE_SCHEDULE';

const inertializeSchedule = (schedual)=>{
    return{
   type: INETIALIZE_SCHEDULE,
   schedual
    }
}
export const Inetialize_Schedual= (schedual)=>{
    return(dispatch)=>{
            return dispatch(inertializeSchedule(schedual));
        }
}
