

import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { GetMaterialsByIds } from '../../../utils/materials';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';
import { Create_New_Package } from '../../../actions/packages';
import Loading from '../../loadingEffect';
import { HomePage } from '../../../translations/trans';
import axios from "axios";
import { urlBase } from '../../../utils/API';

function AddPackage({ currency }) {
  const [name, setName] = useState('');
  const [materials, setMaterials] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [image, setImage] = useState(null);
  const [cover, setCover] = useState(null);
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [selectedValues, setSelectedValues] = useState({
    levelId: '',
    materialCode: '',
    selectedLessons: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  const { levels } = useSelector(({ levels }) => ({ levels }));

  const resetAllValues = () => {
    setName('');
    setMaterials([]);
    setLessons([]);
    setAllLessons([]);
    setPrice(0);
    setDiscountPrice(0);
    setImage(null);
    setCover(null);
    setSelectedValues({
      levelId: '',
      materialCode: '',
      selectedLessons: [],
    });
  };

  const onChangeLevel = async (levelId) => {
    try {
      if (!levelId || levelId.trim() === '') return;
      const selectedLevel = levels.find((level) => level._id === levelId);
      const res = await GetMaterialsByIds({ IDs: selectedLevel.materialIDS });
      const { materials } = res.data;
      setMaterials(materials);
      setSelectedValues({
        levelId,
        materialCode: '',
        selectedLessons: [],
      });
      setAllLessons([]);
      setLessons([]);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onChangeMaterial = async (materialCode) => {
    try {
      if (!materialCode || materialCode.trim() === '') return;
      const res = await getLessonsOfSpecificMaterial({ code: materialCode });
      const { lessons } = res.data;
      setAllLessons(lessons);
      setSelectedValues((prev) => ({
        ...prev,
        materialCode,
        selectedLessons: lessons.map((lesson) => lesson._id),
      }));
      setLessons([]); // All lessons are selected, no lessons left to add
    } catch (err) {
      console.log(err.message);
    }
  };

  const onRemoveLesson = (id) => {
    const removedLesson = selectedValues.selectedLessons.find((lessonId) => lessonId === id);
    const updatedSelectedLessons = selectedValues.selectedLessons.filter((lessonId) => lessonId !== id);

    setLessons([...lessons, allLessons.find((lesson) => lesson._id === removedLesson)]);
    setSelectedValues({
      ...selectedValues,
      selectedLessons: updatedSelectedLessons,
    });
  };

  const onChangePrice = (price) => {
    setPrice(price);
  };

  const onChangeDiscountPrice = (price) => {
    setDiscountPrice(price);
  };

  const loggedUser = useSelector((state) => state.loggedUser);

  const uploadingFileLocally = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
    console.log(response);
    return response.data.data.name;
  };

  // SUBMIT
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!name || !price) return alert('من فضلك قم باكمال البيانات المطلوبة!');
      setLoading(true);
      const fileName = image ? await uploadingFileLocally(image) : null;
      console.log(fileName);
      const data = { ...selectedValues, price, name, coverImage: fileName, discountPrice };
      const res = await dispatch(Create_New_Package(data));
      resetAllValues();
      setMessage(res.data.message);
      setLoading(false);
    }
    catch (err) {
      console.error(err);
      setMessage(err.message);
      setLoading(false);
    }
  };

  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }));

  console.log(materials);

  return (
    <Container className='loading-parent' style={{ border: '0px solid red', width: '100%' }}>
      <h3>{HomePage.addNewProgram[lang]}</h3>

      {loading && <Loading />}
      {message && <p>{message}</p>}
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col md={12}>
            <Form.Group controlId="packageName">
              <Form.Label>{HomePage.packageName[lang]} </Form.Label>
              <Form.Control
                type="text"
                placeholder={`${HomePage.namePackage[lang]}`}
                style={{ width: '100%' }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <hr />
          </Col>

          <Col md={12}>
            <Form.Group controlId="program-cover-image">
              <Form.Label>{HomePage.coursPhoto[lang]} </Form.Label>
              <Form.Control
                type="file"
                onChange={(event) => {
                  setImage(event.target.files[0]);
                  const url = window.URL.createObjectURL(event.target.files[0]);
                  setCover(url);
                }}
              />
            </Form.Group>
            {cover &&
              <img width="300px" src={cover} alt="imageprogram" style={{ marginTop: '10px' }} />
            }
          </Col>

          <Col md={12} style={{ marginTop: '20px' }}>
            <Form.Group controlId="levelSelect">
              <Form.Label>{HomePage.chooseLevel[lang]}</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '100%' }}
                value={selectedValues.levelId}
                onChange={(e) => onChangeLevel(e.target.value)}
                required
              >
                <option value="">{HomePage.chooseLevel[lang]}</option>
                {levels.map((level) => (
                  <option key={level._id} value={level._id}>
                    {level.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          
            <Col md={12} style={{ marginTop: '20px' }}>
              <Form.Group controlId="materialSelect">
                <Form.Label>{HomePage.material[lang]}</Form.Label>
                <Form.Control
                  as="select"
                  style={{ width: '100%' }}
                  value={selectedValues.materialCode}
                  onChange={(e) => onChangeMaterial(e.target.value)}
                  required
                >
                  <option value="">{HomePage.chooseMaterial[lang]}</option>
                  {materials.map((material) => (
                    <option key={material.code} value={material.code}>
                      {material.name} ({material.code})
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          

          
            <>
              <Col md={6} style={{ marginTop: '20px' }}>
                <Form.Group controlId="price">
                  <Form.Label>{HomePage.price[lang]} {currency ? currency : `${HomePage.coin[lang]}`}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={`${HomePage.coursePackage[lang]}`}
                    style={{ width: '100%' }}
                    value={price}
                    onChange={(e) => onChangePrice(e.target.value)}
                    required
                    min="0"
                  />
                </Form.Group>
              </Col>

              <Col md={6} style={{ marginTop: '20px' }}>
                <Form.Group controlId="discountPrice">
                  <Form.Label>{HomePage.discountPrice[lang]} {currency ? currency : `${HomePage.coin[lang]}`}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={`${HomePage.discountPrice[lang]}`}
                    style={{ width: '100%' }}
                    value={discountPrice}
                    onChange={(e) => onChangeDiscountPrice(e.target.value)}
                    min="0"
                  />
                </Form.Group>
              </Col>

              {/* Automatically added lessons are displayed here */}
              <Col md={12} style={{ marginTop: '20px' }}>
                <div>
                  <strong>{HomePage.selectedLessons[lang]}:</strong>
                  {selectedValues.selectedLessons.length === 0 && <p>{HomePage.noLessonsSelected[lang]}</p>}
                  {selectedValues.selectedLessons.map((selectedLesson) => (
                    <div key={selectedLesson} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                      <span>
                        {allLessons.find((lesson) => lesson._id === selectedLesson)?.name}
                      </span>
                      <CiSquareRemove
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                        size={25}
                        onClick={() => onRemoveLesson(selectedLesson)}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </>
          

        </Row>
        <Button type="submit" style={{ marginTop: '20px' }} disabled={loading}>
          submit
        </Button>
      </Form>
      <hr />
      <hr />
      <hr />
      <hr />
    </Container>
  );
}

export default AddPackage;
