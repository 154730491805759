import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getLessonById } from '../../../utils/lessons';
import { HomePage } from '../../../translations/trans';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { getResultByUserByTest } from '../../../utils/results';
import ModalAddException from "./ModalAddException"

const ModalTestResult = ({ lessonId, userId, name }) => {
    const [show, setShow] = useState(false);
    const [lessonName, setLessonName] = useState([]);
    const [testResults, setTestResults] = useState({}); // To store results for each test

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Fetch Lesson
    const getLesson = async () => {
        try {
            const res = await getLessonById({
                _id: lessonId,
            });
            setLessonName(res?.data?.lesson);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getLesson();
    }, [lessonId]);

    // Fetch test result for a specific user and test
    const getTestUser = async (testId) => {
        console.log(testId);
        console.log(userId);
        try {
            const res = await getResultByUserByTest({
                userID: userId,
                testID: testId,
            });
            if (res?.data?.case) {
                return res?.data?.result; // Return result if found
            }
        } catch (e) {
            console.log(e);
        }
        return null; // Return null if no result found or error occurred
    };

    // Fetch results for all tests
    const fetchTestResults = async () => {
        if (lessonName?.testIDS) {
            const results = {};
            for (const test of lessonName?.testIDS) {
                const result = await getTestUser(test?._id);
                results[test._id] = result; // Store result using test ID as key
            }
            setTestResults(results);
        }
    };

    useEffect(() => {
        if (lessonName?.testIDS?.length) {
            fetchTestResults(); // Fetch results when tests are available
        }
    }, [lessonName]);



    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <button
                style={{ border: 'none', outline: 'none', background: 'transparent', color: 'black' }}
                onClick={handleShow}
            >
                {lessonName?.name}
            </button>

            <Modal show={show} onHide={handleClose} size="lg" centered dir={dir}>
                <Modal.Header>
                    <Modal.Title>{HomePage.stdResult[lang]} {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{HomePage.testName[lang]}</th>
                                <th>{HomePage.testStatus[lang]}</th>
                                <th>{HomePage.testRes[lang]}</th>
                                <th>{HomePage.triesNum[lang]}</th>
                                <th>{HomePage.triesNumAdd[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lessonName?.testIDS?.map((test, index) => (
                                <tr key={test?._id}>
                                    <td>{index + 1}</td>
                                    <td>{test?.name}</td>
                                    <td>
                                        {testResults[test?._id] ? (
                                            testResults[test?._id]?.passed ? `${HomePage.success[lang]}` : `${HomePage.fail[lang]}`
                                        ) : (
                                            `${HomePage.stdNotTest[lang]}`
                                        )}
                                    </td>
                                    <td>
                                        {testResults[test?._id] ? (
                                            `${testResults[test?._id]?.result} / ${testResults[test?._id]?.total}`
                                        ) : (
                                            "N/A"
                                        )}
                                    </td>
                                    <td>
                                        {testResults[test?._id] ? (
                                            <span className="mx-2">{`${testResults[test?._id]?.tries}`}</span>
                                        ) : (
                                            "N/A"
                                        )}
                                    </td>
                                    <td>
                                        <ModalAddException userId={userId} name={name} testId={test?._id} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.close[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModalTestResult;
