import React from 'react'

function Button({onClick,name,width,hight,bgColor,id,className}) {
    const styleSheet = {
      backgroundColor:bgColor,
        width:width,
        hight:hight,
        dispalay:'inline-block',
        

    }
  return (
    <button id={id} onClick={onClick} style={{styleSheet}} className={`btn ${className}`}>
       {name}
    </button>
  )
}

export default Button