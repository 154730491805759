import React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { uploading } from "../../utils/API";
import {urlBase} from "../../utils/API"

import { useState } from "react";
import Progress from "../upload/progress";
import axios from "axios";
import { Current_Product } from "../../actions/product";
import Image from "./Image";
import FileUpload from "../file-upload/FileUpload.js"

function UploadImage() {
  //   const name = useRef();
  const dispatch = useDispatch();
  const files = useRef();
  //   const video = useRef();
  // const navigate = useNavigate();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  // const [uploadedFile, setUploadedFile] = useState(null);
  const user = useSelector(mapStateToProps);
  const [message, setMessage] = useState(null);

  // const img = user.currentP.image
  //   ? require(`../../assets/${user.currentP.image}`)
  //   : "";
  // const [imageLink, setImageLink] = useState(img);
  //  console.log(img)
  // const fileUpload = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("name", user.currentP.name + Date.now());
  //   formData.append("type", user.currentP.type);
  //   formData.append("product", user.currentP.name);
  //   for (let i = 0; i < files.current.files.length; i++) {
  //     formData.append("files", files.current.files[i]); 
  //   }
  //   const res = await axios.post(
  //     `${urlBase}/api/addcover`,
  //     formData,
  //     {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         setUploadPercentage(
  //           parseInt(
  //             Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //           )
  //         );
  //       },
  //     }
  //   );

  //   // Clear percentage
  //   //setUploadedFile(res.data);
  //   setTimeout(() => setUploadPercentage(0), 3000);
  //   setMessage("File Uploaded");
  //   // update current Image in redux
  //   const currentP = await user.currentP;
  //   currentP.image = await res.data;
  //   await dispatch(Current_Product(currentP));
  // };
  return (
    <div className="container">
    <FileUpload/>
    </div>
  );
}
function mapStateToProps({ loggedUser, general }) {
  return {
    ...loggedUser.user,
    auth: loggedUser.auth,
    currentP: general.currentP,
  };
}

export default UploadImage;
