import React from 'react'
// import Col from '../styled-components/col'
// import Contain from '../styled-components/contain'
import {Rights_Text} from "../../constants"
function Rights() {
  return (
<div className='rights public_bg'>
        <a className='rights-link' href='/'  id='rights1'>
{Rights_Text}
        </a>
        <a className='rights-link'target="_blank" href='https://osbash.com' rel="OSBASH_Company" >
Developed By OSBASH
        </a>
</div>
    )
}

export default Rights