import React from 'react'
import {ImWhatsapp} from "react-icons/im"
import "./chat.css"
import { whatsapp } from '../constants'
function Chat() {
  
  return (
    <>
    <span className="chat-icon">
        <a href={`https://wa.me/${whatsapp}`} target="_blank">
    <ImWhatsapp color='green' size='3em'/>
    </a>
    </span>
    </>
  )
}

export default Chat