import {ROOM_ID,MEETING_DETAILS} from "../actions/etcMeeting";

export const meeting = (state={},action)=>{
   switch (action.type){
    case ROOM_ID:
        return state={...state,roomId:action.id.roomId,userId:action.id.userId}
    case MEETING_DETAILS:
        return state = {...state,...action.details}
    default: return state
   }
}