import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getLessonById, updateLesson } from '../../../utils/lessons';
import { urlBase } from '../../../utils/API';
import { Form } from 'react-bootstrap';
import { HomePage } from '../../../translations/trans';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { success } from '../../../utils/Toast';
import { SolarSystemLoading } from 'react-loadingg';
import { MdDelete } from "react-icons/md";
import { TbReplaceFilled } from "react-icons/tb";
import UpdateLessonContent from './UpdateLessonContent';
import DeleteLessonContent from './DeleteLessonContent';
import ModalAddLessson from './ModalAddLessson';


const ModalUpdateLesson = ({ lessonId, lessonData, lessonNameData, materialCode, show, handleClose }) => {
    // const [show, setShow] = useState(false);
    const [lesson, setLesson] = useState([]);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // Fetch Lesson
    const getLesson = async () => {
        try {
            const res = await getLessonById({ _id: lessonData?._id });
            setLesson(res?.data?.lesson);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        if (lessonData?._id) getLesson();
    }, [lessonData, lessonData?._id]);

    const preventContextMenu = (e) => {
        e.preventDefault();
    };


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [loading, setLoading] = useState(false);
    const [lessonName, setLessonName] = useState(lessonNameData);

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [pdf, setPdf] = useState(null);

    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0); // State to handle upload progress


    const handleLessonNameChange = (e) => {
        setLessonName(e.target.value);
    };


    const handleFileChange = (type) => {
        console.log(type)
        switch (type) {
            case 'image':
                setMessage('Uploading image...');
                break;
            case 'video':
                setMessage('Uploading video...');
                break;
            case 'pdf':
                setMessage('Uploading PDF...');
                break;
            default:
                setMessage('Uploading...');
        }

        // You may want to handle the file upload process here
        // For now, let's assume the file upload happens instantly
    };

    //-------------
    //                                 LOCAL UPLOAD ENDPOINT
    const uploadingFileLocally = (data) => {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally`,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentage)

            }
        })
    }
    //-------------


    const handleSaveChanges = async () => {
        try {
            setLoading(true);

            // Start with the existing content of the lesson
            let content = [...lesson.content];

            // Handle image upload
            if (image) {
                handleFileChange(image.type);
                const res = await uploadingFileLocally(image);
                content = content.filter(c => !c.type.split('/').includes('image')); // Remove old image content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: image.type,
                    source: res.data.data.source,
                });
            }

            // Handle video upload
            if (video) {
                handleFileChange(video.type);
                const res = await uploadingFileLocally(video);
                content = content.filter(c => !c.type.split('/').includes('video')); // Remove old video content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: video.type,
                    source: res.data.data.source,
                });
            }

            // Handle PDF upload
            if (pdf) {
                handleFileChange(pdf.type);
                const res = await uploadingFileLocally(pdf);
                content = content.filter(c => !c.type.split('/').includes('pdf')); // Remove old pdf content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: pdf.type,
                    source: res.data.data.source,
                });
            }

            const lessonData = {
                id: lessonId,
                materialCode: materialCode,
                name: lessonName || lesson.name, // Use the old name if no new name is provided
                content,
                testIDS: []
            };

            const response = await updateLesson(lessonData);
            setMessage(response.data.message);
            setLoading(false);
            handleClose();
            success(response.data.message);
            getLesson();
        } catch (error) {
            console.error('Error:', error);
            setMessage(error.message);
            setLoading(false);
        }
    };


    console.log("Lesssssssoooonnn", lesson)

    const [updateLesson, setUpdateLesson] = useState(false);
    const [addLess, setAddLess] = useState(false);
    const [contentLesson, setContentLesson] = useState(null)
    const [lessonIdData, setLessonId] = useState(null)

    const [contentLessonDel, setContentLessonDel] = useState(null)
    const [idLessonDel, setIdLessonDel] = useState(null)
    const [delLesson, setDelLesson] = useState(false);

    const handleShowUpdateLesson = (c, lessonId) => {
        setUpdateLesson(true)
        setContentLesson(c)
        setLessonId(lessonId)
        handleClose();
    }


    const handleContentDel = (c, lessonId) => {
        setDelLesson(true)
        setContentLessonDel(c)
        setIdLessonDel(lessonId)
        handleClose();
    }

    


    const handleAddLesson = ()=>{
        setAddLess(true);
        handleClose();
    }

    return (
        <div>
            {/* <Link variant="primary" style={{ cursor: 'pointer' }} onClick={handleShow}>
                {HomePage.updateLessonontent[lang]}
            </Link> */}

            <Modal show={show} onHide={handleClose} centered size="lg" dir={dir}>
                <Modal.Header>
                    <Modal.Title>{HomePage.updateLessonontent[lang]}</Modal.Title>
                </Modal.Header>

                <button className='btn btn-primary w-25' onClick={() => { handleAddLesson() }}>Add New Lesson</button>
                {
                    !loading ? (
                        <Modal.Body>





                            {/* View Content */}
                            <div>
                                {lesson?.content?.length > 0 ? (
                                    lesson?.content.map((c, i) => {
                                        if (c.type.split('/').includes('image') || c.type === "png" || c.type === "jpeg") {
                                            return (
                                                <div key={i} onContextMenu={preventContextMenu} className="mt-5" style={{ height: "350px", textAlign: "center" }}>
                                                    <div>
                                                        <MdDelete color="red" size={"25px"} onClick={() => { handleContentDel(c, lesson?._id) }} />
                                                        <TbReplaceFilled onClick={() => { handleShowUpdateLesson(c, lesson?._id) }} size={"25px"} color="#283476" />
                                                        {/* <p >data</p> */}

                                                    </div>
                                                    <hr />
                                                    <img
                                                        src={`${urlBase}/${c.name}`}
                                                        alt="lesson cover"
                                                        style={{ width: '50%', height: '70%' }}
                                                    />
                                                </div>
                                            );
                                        } else if (c.type.split('/').includes('video')) {
                                            return (
                                                <div key={i} style={{ marginBottom: '20px', marginTop: "40px", height: "400px" }} onContextMenu={preventContextMenu}>
                                                    <div>
                                                        <MdDelete color="red" size={"25px"} onClick={() => { handleContentDel(c, lesson?._id) }} />
                                                        <TbReplaceFilled onClick={() => { handleShowUpdateLesson(c, lesson?._id) }} size={"25px"} color="#283476" />
                                                    </div>
                                                    <hr />
                                                    <video controls controlsList="nodownload" width="100%" height="auto">
                                                        <source src={`${urlBase}/api/files/${c.name}?type=${c?.type}`} type={c.type} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            );
                                        } else if (c.type.split('/').includes('pdf')) {
                                            return (
                                                <div key={i} style={{ marginBottom: '20px', textAlign: "center", marginTop: "40px", width: '100%', height: "500px" }} onContextMenu={preventContextMenu}>
                                                    <div>
                                                        <MdDelete color="red" size={"25px"} onClick={() => { handleContentDel(c, lesson?._id) }} />
                                                        <TbReplaceFilled onClick={() => { handleShowUpdateLesson(c, lesson?._id) }} size={"25px"} color="#283476" />
                                                    </div>
                                                    <div className="pdf-container mt-3">
                                                        <iframe
                                                            style={{ backgroundColor: '#fff' }}
                                                            src={`${urlBase}/${c.name}`}
                                                            width="100%"
                                                            height="350px"
                                                            title="fullOption"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })
                                ) : null}
                            </div>
                        </Modal.Body>
                    ) : (
                        <div>
                            <SolarSystemLoading	></SolarSystemLoading	>
                        </div>
                    )
                }
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
            <UpdateLessonContent lessonContent={contentLesson} lessonId={lessonIdData} materialCode={materialCode} show={updateLesson}
                handleClose={() => setUpdateLesson(false)}
            />

            <DeleteLessonContent show={delLesson}
                handleClose={() => setDelLesson(false)} contentLessonDel={contentLessonDel} idLessonDel={idLessonDel} />


            <ModalAddLessson show={addLess}  handleClose={() => setAddLess(false)} lessonData={lessonData}/>
        </div>
    );
};

export default ModalUpdateLesson;
