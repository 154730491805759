import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
import GetLessonId from '../../shared/GetLessonId';
import { addReportToAllStd } from '../../../utils/lessons';
import { ErrorMsg } from '../../../utils/Toast';

const SendReportToAllstd = ({ packClass }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [packageData, setPackageData] = useState(null);
    const [lesson, setLesson] = useState(null);

    const [loading, setLoading] = useState(false);

    const { packages } = useSelector(({ packages }) => ({
        packages
    }));

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const loggedUser = useSelector((state) => state.loggedUser);


    const fetchPackage = async () => {
        const pack = packages.find(p => p._id === packClass.packID);
        setPackageData(pack);
    };

    const [type, setType] = useState(null)


    const handleAddReport = async () => {
        try {
            setLoading(true)
            const res = await addReportToAllStd({
                about: type
            }, packClass?._id, lesson, loggedUser?.user?._id);
            console.log(res)
            handleClose();
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPackage();
    }, [packClass, packages]);
    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow}>Send Report</button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Body>
                    <h3>Send Report To All Students</h3>
                    <select style={{ border: "none" }} value={lesson} onChange={(e) => setLesson(e.target.value)} className='form-control'>
                        <option>{HomePage.lessonName[lang]}</option>
                        {packageData?.selectedLessons.map((lesson) => (
                            <option key={lesson} value={lesson}>
                                <GetLessonId lessonId={lesson} />
                            </option>
                        ))}
                    </select>

                    <select className='form-control mt-5' value={type} onChange={(e) => setType(e.target.value)}>
                        <option hidden>Select Type</option>
                        <option value={"test"} >Test</option>
                        <option value={"activity"} >activity</option>
                    </select>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}> cancel</Button>
                    <Button variant='primary' onClick={handleAddReport} disabled={loading}>
                        {loading ? "Loading.." : "Add"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SendReportToAllstd
