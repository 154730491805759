import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { New_Mock, Update_mock } from '../../../actions/mockAction';
import { ErrorMsg, SuccessMsg } from '../../../utils/Toast';  // Assume you have this for success messages
import { urlBase } from '../../../utils/API';
import axios from 'axios';

const UpdateMock = ({ mock, materialId }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);  // Loading state for file upload

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState(mock?.name);
    const [desc, setDesc] = useState(mock?.description);
    const [file, setFile] = useState(mock?.file);  // To store the uploaded file name

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    // Upload File Locally
    const uploadingFileLocally = async (data) => {
        const formData = new FormData();
        formData.append("file", data);

        try {
            setFileUploading(true);
            const response = await axios({
                method: "POST",
                url: `${urlBase}/api/upload-file-locally`,
                data: formData,
            });
            return response.data; // Assuming this contains { name: "uploadedFileName.pdf" }
        } catch (error) {
            console.error(error);
            ErrorMsg('File upload failed');
            return null;
        } finally {
            setFileUploading(false);
        }
    };

    // Handle file change and upload
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const uploadResult = await uploadingFileLocally(selectedFile);
            console.log(uploadResult)
            if (uploadResult && uploadResult?.data) {
                setFile(uploadResult?.data?.name);  // Set the file name in state
                // SuccessMsg('File uploaded successfully');
            }
        }
    };

    // Handle New Mock Creation
    const handleNewMock = async () => {
        try {
            setLoading(true);
            const res = await dispatch(Update_mock({
                name: name,
                description: desc,
                file: file // This is the file name from the upload
            }, mock?._id, materialId, loggedUser?.user?._id));
            // console.log(res);
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                {HomePage.editMock[lang]}
            </Button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header>
                    <Modal.Title>{HomePage.editMock[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div>
                            <label>{HomePage.mockName[lang]}:</label>
                            <input
                                type="text"
                                name="nameFile"
                                id="namefile"
                                className='form-control'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='mt-3'>
                            <label>{HomePage.descMock[lang]}:</label>
                            <input
                                type="text"
                                name="desc"
                                id="desc"
                                className='form-control'
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </div>
                        <div className='mt-3'>
                            <label>{HomePage.onlyfile[lang]}</label>
                            <input
                                type="file"
                                name="filePdf"
                                id="filePdf"
                                accept="application/pdf"
                                className='form-control'
                                onChange={handleFileChange}
                            />
                            {fileUploading && <Spinner animation="border" role="status" />}
                        </div>
                        <div>
                            <iframe src={`${urlBase}/${mock?.file}`} height="500px" title="mock" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={handleNewMock} disabled={loading || fileUploading}>
                        {loading ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : HomePage.add[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateMock;
