import React,{useState,useTransition,useEffect} from 'react'
import axios from 'axios'
import {useDispatch,useSelector} from 'react-redux';
import {Booking_Results} from '../../../actions/booking.js'
import GetText from '../../translation/getText'
function InserCode({urlBase,price}) {
    
    const [amount,setAmount] = useState(0)
    const [isPending, startTransition] = useTransition()
    const [validationRes,setValidationRes] =useState(null);
    // const [diss,setDiss] = useState();
    const [data,setData] =useState({});
    const [newBook,setNewBook] = useState(null);
    const [message,setMessage] = useState()
const [code,setCode] = useState(null);
//
const [disable,setDisable] = useState(true)
//
// const defferedCode = useDeferredValue(code);

    const validateData = (data) => {
        const url = `${urlBase}/api/validate/code`;
        return axios({
          method: "POST",
          data: data,
          withCredentials: true,
          url: url,
        });
      };
    const onChange = (e)=>{
        startTransition(()=>{
            validateData({code:e}).then(res=>{
                if(res.status===200){
                    setMessage(res.data.state)
                    setAmount(res.data.data.amount)
                    setValidationRes(res.data)
                    setDisable(false);
                    setCode(e);
                }else{
                    setMessage(res.data)
                    setDisable(true)
                }     
            }
                  )
        })
    }

//--------------------------
// submit discount function
// console.log("disable")

const dispatch = useDispatch();
const {booking} = useSelector(mapStateToProps);
useEffect(()=>{
            // var data;
            const newBooking = booking;
            if(!amount) return;
            if(validationRes.data.type==='amount'){
                // console.log('disscount',validationRes)
                let diss=(parseInt(booking.package.price)-parseInt(amount))// 
            // remain =parseInt(amount) - parseInt(price);
                newBooking.disscountAmount=diss>0?amount:parseInt(booking.package.price);
                // console.log('diss',diss);
              setData({code:code,remain:diss>0?0:-diss});
              newBooking.price = diss>0?diss:0;
            //   console.log('price',newBooking.price)
            }
            else{
                const remain = parseInt(booking.package.price)*(1-(parseInt(amount)/100))
                setData({code:code,remain:amount});
                newBooking.price = remain;
                newBooking.disscountAmount=parseInt(booking.package.price)-remain;
            }
            newBooking.disscountCode=code;
            setNewBook(newBooking);
},[code,amount,validationRes])
const submitDisscount =(data)=>{
    const url = `${urlBase}/api/edit/code`;
    return axios({
        method: "POST",
        data: data,
        withCredentials: true,
        url: url,
      });
}
// console.log(code.current.value)
    const onSubmit = (e)=>{
        try{
            e.preventDefault();  
            submitDisscount(data)
           .then(res=>{
               if(res.status===200){
                dispatch(Booking_Results(newBook))
                setMessage(res.data.state)
                setDisable(true)
            }else{setMessage(res.data)}
        })
        }catch(e){console.log(e)}
    } 
  return (
<GetText>
<form className='disscount-form' onSubmit={onSubmit}>
    <br/>
       
        <span className='mess add-disscount-message'>{message}</span>
        <label id='inscidlab'>Do you have disscout code?</label>
       <div className='diss-in-sub'>
        <input className='c dissIn' id='diss1' 
        onChange={(event)=>onChange(event.target.value)}
          name='code' type='text' 
          placeholder='insert code' required />
       {/* <span>{message}</span> */}<br/><br/>
        <input id='diss2' className = 's' type='submit' disabled={disable} value='submit'/>
        </div>
    </form>
</GetText>
    )
}
function mapStateToProps({ booking }) {
    
    return {booking}
  }
export default InserCode