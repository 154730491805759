import React,{ useEffect } from 'react'
import SidNav from './sideNav'
import "./admin.css"
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Inetialize_Products } from '../../actions/product'
import Contain from '../styled-components/contain'
import Col from '../styled-components/col'
import {Inetialize_Ads} from "../../actions/login&register.js"
import {Inetialize_Appointments} from "../../actions/booking.js";
import NavigationBar from './navigationBar.js';
function Admin() {
  const dispatch = useDispatch();
   useEffect(()=>{
     dispatch(Inetialize_Products())
     dispatch(Inetialize_Ads())
     dispatch(Inetialize_Appointments())
   },[])
      const taps = [
      {name:"Add Product",path:"New-Product"},
      {name:'Products',path:'products'},
      {name:"Teachers",path:"teachers-list"},
      {name:"Students",path:"students"},
      {name:"Accounts",path:"accounts"},
      {name:"Appointments",path:"appointments"},
      {name:"Create Disscount", path:'new/disscount'},
      {name:'Hi whats',path:'whats-message'}
      ]
  return (
    <Contain height={'100vh'}>
      
      <Col dir='column' width='20%'>
 
      <SidNav taps={taps}/>
      </Col>
      <div className='shadow-border'>
          <div className='top-bar-cont'>
     <NavigationBar/>
      </div><br/><br/>
      <Outlet/>

      </div>
    </Contain>
  )
}

export default Admin