import React from 'react'
// import { io } from "socket.io-client"
import {Save_Room_Id} from '../../actions/etcMeeting';
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
function OpenRoom({roomId,userId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const GoToRoom = (roomId,userId)=>{
    dispatch(Save_Room_Id({roomId,userId}))
    navigate('/call');
  }
  return (
<>
 <button className='meeting-navigation' onClick = {()=>GoToRoom(roomId,userId)}>meeting</button>
</>
    )
}

export default OpenRoom