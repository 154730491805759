import React, { useState, useRef, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { useSelector } from "react-redux";
// import LoginForm from "./loginForm";
import { useNavigate, NavLink } from "react-router-dom";
// import Logo from '../../assets/logo.png'
import "./newlogin.css";
// import Header from '../userDashboard/Header.js';
import BlueHeader from '../publicPages/blueHeader.js'
import { Sign_Image as LogIMG, platformName, Login_Image } from '../../constants.js'
import { useDispatch } from "react-redux";
import serializeForm from "form-serialize";
import Footer from '../Home/footer'
import UAParser from 'ua-parser-js';

import { SolarSystemLoading } from 'react-loadingg';
import {
  Register_Admin,
  Register_Teacher,
  Register_Student,

  logIn,
} from "../../actions/login&register";
import Header from "../Home/header.jsx";
import { HomePage } from "../../translations/trans.js";
import ModalforgetPass from "./ModalforgetPass.js";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

// Device Info
const parser = new UAParser();
const result = parser.getResult();
let browserInfo = {
  ...result.os,
  ...result.cpu
};


const LoginComponent = ({ type, currentMode }) => {

  const [mode, setMode] = useState(currentMode);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const user = useSelector(mapStateToProps)
  const [message, setMessage] = useState()
  const dispatch = useDispatch();
  const [phone, setPhone] = React.useState("");
  const [phoneParent, setPhoneParent] = React.useState("");
  const [countryCode, setCountryCode] = useState("us"); // Default to 'us' until detected



  //------------login func----------
  const signUp = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      const data = serializeForm(formRef.current, { hash: true });
      console.log(data)
      data.role = type;
      data.username = data.name;
      data.phone = phone;
      data.parentPhone = phoneParent;

      const res = await dispatch(Register_Student(data));
      setLoading(false);
      setMessage(res.data.message)
      if (res.case) {
        setMode('login');
      }
      const loginData = { email: data.email, password: data.password, device: browserInfo }
      // data.device=browserInfo;
      const loginRes = await dispatch(logIn(loginData));

      setMessage('login successfully')
      setLoading(false)
      // setMessage('')
      // navigate(`/user/${loginRes.user._id}`)
      navigate(`/subscription`)

    }
    catch (err) {
      setMessage('حدث خطا اثناء محاولة تسجيل المستخدم برجاء المحاولة مرة اخرى')
      console.log(err);
      if (err?.response?.data) {
        setMessage(err?.response?.data?.message)
      }
      setLoading(false)
    }
  };

  //-------Login Function--------
  const logInFunc = (e) => {

    try {
      e.preventDefault();
      setLoading(true)
      const data = serializeForm(formRef.current, { hash: true });
      data.device = browserInfo;
      dispatch(logIn(data)).then(res => {
        setLoading(false)
        setMessage('')
        if (res.auth) {
          if (res.user.role === 'Admin') {
            setMessage('login successfully')
            // navigate('/admin-dashboard')  
            navigate(`/user/${res.user._id}`)
          }
          else {

            setMessage('login successfully')
            navigate(`/user/${res.user._id}`)


          }
        }
        else if (res.case === false) return setMessage(res.message)

        else if (!res.auth) {
          setMessage('email or password is not correct')
        }

        setTimeout(() => {
          setMessage('')
        }, 5000);
      })
    }
    catch (e) {
      console.log(e.message)
      setMessage(e.message)
      setLoading(false)

    }
  }
  //--------------------------------- 


  const swichLogin = (e) => {
    e.preventDefault();
    const newMode = e.target.value;

    setMode(newMode);
    setMessage('');
  }

  //  Find Geo Location 

  // Fetch the user's location based on their IP
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const country = response.data.country_code.toLowerCase();
        setCountryCode(country);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);
  //--------------- handel check box
  const [selectedCheckbox, setSelectedCheckbox] = useState('');

  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.id);
  };
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  if (!loading) {
    return (
      <>

        <div>
          <Header />
          <BlueHeader title={`${HomePage.forRegister[lang]}`} subTitle={`${HomePage.home[lang]}`} secondSubTitle={`${HomePage.forRegister[lang]}`} />
          {/* <div
          className={`form-block-wrapper form-block-wrapper--is-${mode}`}
          >

        </div> */}
          <section className='log-body' dir={dir}>
            <div className='log-form-cont'>
              <form
                onSubmit={mode === 'login' ? logInFunc : signUp}
                className='log-form-new' ref={formRef}>
                <div className='lognav-cont' style={{ backgroundColor: "#E1EBFF", color: "#283476" }}>
                  <button
                    value={'login'}
                    className={mode === 'login' ? 'buttons_bg' : 't-bg'}
                    id='lognav2'
                    onClick={(event) => swichLogin(event)}>{`${HomePage.loginRegisrer[lang]}`}</button>
                  <button
                    className={mode === 'signup' ? 'buttons_bg' : 't-bg'}
                    value={'signup'}
                    id='lognav3'
                    onClick={(event) => swichLogin(event)}>{`${HomePage.registerData[lang]}`}</button>
                </div>
                <span id='log-res-mess' >{message}</span>
                <span id='log-sp-1'>{mode === 'login' ? `${HomePage.welcomeRegister[lang]}` : `${HomePage.welcomeLogin[lang]}`}</span>
                {mode === 'signup' && <>
                  <label htmlFor='sign1' id='signsp1'> {HomePage.nameForm[lang]}</label>
                  <input type='text' name='name' id='signinp1' placeholder={HomePage.nameForm[lang]} required/>
                  <label htmlFor='sign1' id='signsp1'> {HomePage.parentName[lang]}</label>
                  <input type='text' name='parentName' required id='signinp1' placeholder={HomePage.parentName[lang]} />
                  
                </>}

                {mode === 'signup' && <>
                  {/* <input type='text' name='phone' id='phone' /> */}
                  <label htmlFor='sign1' id='signsp1'>{HomePage.phoneStudent[lang]}</label>
                  <PhoneInput
                    className={"register-field"}
                    country={countryCode}
                    value={phone}
                    label={HomePage.phoneStudent[lang]}
                    onChange={phone => setPhone("+"+phone)}
                    required={true}
                  />
                </>}
                {mode === 'signup' && <>
                  <label htmlFor='sign1' id='signsp1'>{HomePage.country[lang]}</label>
                  <input type='text' name='country' id='country' />
                </>}
                {mode === 'signup' && <>
                  <label htmlFor='sign1' id='signsp1'>{HomePage.phoneParent[lang]}</label>
                  <PhoneInput
                    required={true}
                    className={"register-field"}
                    country={countryCode}
                    value={phoneParent}
                    label={HomePage.phoneParent[lang]}
                    onChange={phone => setPhoneParent("+"+phone)}
                  />
                  {/* <input type='text' name='parentPhone' id='parentPhone'  /> */}
                </>}
                {mode === 'signup' && <>
                  <label htmlFor='sign1' id='signsp1'>{HomePage.nameSchool[lang]}</label>
                  <input type='text' name='schoolName' id='schoolName' />
                </>}
                {mode === 'signup' && <div className="mt-3 mb-3">
                  <label htmlFor='sign1' id='signsp1'>{HomePage.grade[lang]}</label>
                  <select className="w-75 m-auto form-control" name="grade" id="grade" style={{ borderRadius: "20px" }} >
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                </div>}
                <label id='logla1' htmlFor='login1'>
                  {HomePage.email[lang]}
                </label>
                <input type='text' name='email' id='login1' placeholder="example@email.com" required/>
                <label id='logla2' htmlFor='login2'>
                  {HomePage.pass[lang]}
                </label>

                <input type='password' name='password' id='login2' /><br />
                {mode === 'signup' && <>
                  <label htmlFor='sign2' id='signsp2'> {HomePage.repass[lang]}</label>
                  <input type='password' name='password2' id='signinp2' />
                </>}
                {mode === 'login' && <>
                  <div className="mb-3" style={{ padding: "0px 80px" }}>
                    <ModalforgetPass />
                  </div>
                </>}
                {/* {mode==='login'&&<NavLink id='logfor1' to={'/password/reset'}> </NavLink>} */}
                {mode === 'signup' && <>
                  <div className='checkbox-area'>
                    <span id='signsp3'>{HomePage.type[lang]}</span>
                    <label htmlFor='gender' id='signlab4'>
                      <input type='checkbox' name='gender'
                        value="male"
                        checked={selectedCheckbox === 'signrad1'}
                        onChange={handleCheckboxChange}
                        id='signrad1' />{HomePage.male[lang]}</label>
                    <label htmlFor='gender' id='signlab5'>
                      <input type='checkbox' name='gender'
                        value="female"
                        checked={selectedCheckbox === 'signrad2'}
                        onChange={handleCheckboxChange}
                        id='signrad2' />{HomePage.female[lang]}</label>
                  </div>
                  <label id='signlab6'>
                    {HomePage.age[lang]}
                    <input type='number' name='age' />
                  </label>
                </>
                }
                <input id='logSub1' className="buttons_bg" type='submit' value={mode === 'login' ? `${HomePage.enter[lang]}` : `${HomePage.registerEnter[lang]}`} />
              </form>
            </div>
            <div className='log-img-cont'>
              {
                mode === "login" ? (
                  <img src={LogIMG} alt={platformName} />
                ) : (<img src={Login_Image} alt={platformName} />)
              }

            </div>
          </section>
          {/* <section className={`form-block form-block--is-${mode}`}>
        <a href='/'> <img src={Logo} alt="AGA ENGLISH" width='100px'/></a>
          <header className="form-block__header">
            <h1>{mode === "login" ? "Welcome back!" : "Sign up"}</h1>
            <div className="form-block__toggle-block">
              <span>
                {mode === "login" ? "Don't" : "Already"} have an account? Click
                here &#8594;
              </span>
              <input
                name="form-toggler "
                id="form-toggler"
                type="checkbox"
                onClick={() => toggleMode()}
                value={mode}  
                className='toggler'
              />
              <label className='toggler-label'  htmlFor="form-toggler"></label>
              
             
            </div>
          </header>
          <LoginForm mode={mode} setMode={setMode} type='student' />
          
        </section> */}
        </div>
        <Footer />

      </>
    )
  }
  else {
    return (<>
      <SolarSystemLoading	></SolarSystemLoading	>
      {/* <p>Loading...</p> */}
    </>
    )
  }

}

function mapStateToProps({ loggedUser }) {
  return loggedUser
}

export default LoginComponent;
