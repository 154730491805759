import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { deleteLessonContent } from '../../../utils/lessons';
import { useSelector } from 'react-redux';
import { ErrorMsg, success } from '../../../utils/Toast';

const DeleteLessonContent = ({ show, handleClose, contentLessonDel, idLessonDel }) => {
    const [loading, setLoading] = useState(false);

    const handleLoaded = async () => {
        try {
            setLoading(true);

            const res = await deleteLessonContent({
                contentName: contentLessonDel?.name,
            }, idLessonDel)

            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.message);
                handleClose();
            }

        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)

        } finally {
            setLoading(false);
        }
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <Modal centered show={show} onHide={handleClose} dir={dir}>
                <Modal.Body>
                    <p>Do You Sure For Delete ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-danger' disabled={loading} onClick={handleLoaded}>
                        {loading ? "Loading..." : "Delete"}
                    </button>
                    <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteLessonContent
