
// import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { useSelector } from 'react-redux';
// import { HomePage } from '../../translations/trans';

// const ShowVideoRecord = ({ record, index }) => {
//     const [show, setShow] = useState(false);

//     console.log(record)

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const { lang, dir } = useSelector(({ language }) => {
//         return { lang: language.lang, dir: language.dir };
//     });
//     return (
//         <div>
//             <Button variant="primary" onClick={handleShow} className="mt-3" style={{ width: "90%" }} >
//                 {HomePage.Record[lang]} {index + 1}
//             </Button>
//             <Modal show={show} onHide={handleClose} centered size='xl'>
//                 <a href={`${record?.play_url}`}>Link</a>
//                 <Modal.Body>
//                     <iframe src={`${record.play_url}`} width="100%" height="500px" title="Video"></iframe>
//                     {/* <iframe src={`${record.play_url}`} width="100%" height="500px" title="title" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Close
//                     </Button>
//                     {/* <Button variant="primary" onClick={handleClose}>
//                         Save Changes
//                     </Button> */}
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     )
// }

// export default ShowVideoRecord

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans';

const ShowVideoRecord = ({ record, index }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    // دالة لتحويل رابط يوتيوب إلى رابط embed إذا كان watch
    const getEmbedUrl = (url) => {
        if (url.includes("youtube.com/watch")) {
            const videoId = url.split("v=")[1]?.split("&")[0]; // استخراج معرف الفيديو
            return `https://www.youtube.com/embed/${videoId}`;
        }
        return url; // إذا كان الرابط بالفعل embed أو ليس من يوتيوب نعيده كما هو
    };

    const embedUrl = getEmbedUrl(record.play_url); // نحصل على رابط المدمج

    return (
        <div>
            <Button variant="primary" onClick={handleShow} className="mt-3" style={{ width: "90%" }} >
                {HomePage.Record[lang]} {index + 1}
            </Button>
            <Modal show={show} onHide={handleClose} centered size='xl'>
                <a href={`${embedUrl}`} target="_blank" rel="noopener noreferrer">Link</a>
                <Modal.Body>
                    <iframe
                        src={embedUrl}
                        width="100%"
                        height="500px"
                        title="Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShowVideoRecord;
