import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import DeleteRecordManually from './DeleteRecordManually';
// import ReactPlayer from "react-player"
const ShowRecords = ({ isManual, title, urlVideo, downloadVideo ,classData
    ,session,recordingCode}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDownlaod = () => {

    }


    console.log("for manual", isManual)

    console.log("ddasdadasda", urlVideo)
    return (
        <div>
            <span style={{ color: "black" }} onClick={handleShow}>
                {title}
            </span>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Body>
                    <p>{urlVideo}</p>

                    <iframe src={urlVideo} width="100%" height="500px" title={`title ${urlVideo}`}></iframe>

                    {
                        isManual ? (
                            <DeleteRecordManually classData={classData}
                            session={session} recordingCode={recordingCode}/>
                        ) : null
                    }

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ShowRecords
