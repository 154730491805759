import React, { useEffect, useState } from "react";
import Contain from "../styled-components/contain.jsx";
import Col from "../styled-components/col";
import "./messages.css";
import ListRow from "../styled-components/listRow.jsx";
import { convertArrToObj } from "../globalFunc.jsx";
import Message from "./message";
import Avatar from '../../assets/profile-avatar.png'
import { useSelector } from "react-redux";

function MessagesCont({ users }) {
  //users = students if user teacher else teachers
  const { sent, recived } = useSelector(state => {
    return {
      sent: state.loggedUser.user.sent ? state.loggedUser.user.sent.sort((a, b) => new Date(b.date) - new Date(a.date)) : [],
      recived: state.loggedUser.user.recived ? state.loggedUser.user.recived.sort((a, b) => new Date(b.date) - new Date(a.date)) : []
    }
  });

  const [messages, setMessages] = useState([]);
  const [type, setType] = useState('recived');

  useEffect(() => {
    setMessages(recived);
  }, []);

  const usersObj = convertArrToObj(users, "_id");

  return (
    <Contain>
      <Col width="100%" dir="column">
        <Contain perfix="message-top-bar">
          <Col width="30%">
            <button
              onClick={() => {
                setType('recived');
                setMessages(recived);
              }}
            >
              Recived
            </button>
          </Col>
          <Col width="30%">
            <button
              onClick={() => {
                setType('sent');
                setMessages(sent);
              }}
            >
              Sent
            </button>
          </Col>
        </Contain>
        {messages.length ?
          <Contain perfix='messages-body-cont'>
            <Col fg="1" dir="column" width="100%" border="1px">
              <div className='messages-cont'>
                <ListRow
                  two={type === 'sent' ? "الى" : 'من'}
                  three={"الموضوع"}
                  four="عرض"
                  five="التاريخ"
                  one={null}
                />

                {type === "recived" &&
                  recived.map((m, i) => {
                    return (
                      <ListRow
                        key={i}
                        one={usersObj[m.from]?.coverImage}
                        two={usersObj[m.from]?.username}
                        three={m.title}
                        four={<Message message={m} />}
                        five={m?.date.split("T")[0]}
                      />
                    );
                  })}

                {type === "sent" &&
                  sent.map((m, i) => (
                    <ListRow
                      key={i}
                      two={usersObj[m.to]?.username}
                      one={usersObj[m.from]?.coverImage ? usersObj[m.from]?.coverImage : Avatar}
                      three={m.title}
                      four={<Message message={m} />}
                      five={m.date.split("T")[0]}
                    />
                  ))}
              </div>
            </Col>
          </Contain> : <h1>No Messages Yet!</h1>
        }
      </Col>
    </Contain>
  );
}

export default MessagesCont;
